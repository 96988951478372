var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('label',{staticClass:"vform-editor-label section"},[_vm._v("Text")]),_vm._l((Object.keys(_vm.element.label.dix)),function(key,index2){return (_vm.element.currentLang === key)?_c('div',{key:index2 + 1000000000 + _vm.forceReRenderKey,staticClass:"dflex"},[_c('div',{staticClass:"col p-0"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.element.label.dix[key]),expression:"element.label.dix[key]"}],staticClass:"mb-1 form-text v-form-label form-text-dark",attrs:{"title":_vm.element.label.dix['Unknown'],"placeholder":key === 'Unknown'
                        ? _vm.placeholders.LONG
                        : _vm.$t('vform.translationMessage', {
                              key: key,
                              content: _vm.element.label.dix['Unknown'],
                          }),"rows":"300","type":"text"},domProps:{"value":(_vm.element.label.dix[key])},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.element.label.dix, key, $event.target.value)}}})])]):_vm._e()}),(!_vm.element.emphasized)?[_c('label',{staticClass:"vform-editor-label section"},[_vm._v("Background Color")]),_c('div',{staticClass:"clickable",style:({
            width: '20px',
            height: '20px',
            backgroundColor: _vm.element.backgroundColor
                ? _vm.element.backgroundColor
                : '',
            border: _vm.element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
        }),on:{"click":function($event){_vm.colorPickrId = _vm.element.uuid}}}),(_vm.colorPickrId === _vm.element.uuid)?_c('color-picker',{ref:"colorpickr",staticClass:"step-settings-color-pickr",attrs:{"popup-style":true,"show-save-button":true,"value":_vm.element.backgroundColor ? _vm.element.backgroundColor : '#fff'},on:{"changeColor":function (color) {
            _vm.setColor(color, false);
          },"close":function () {
                _vm.colorPickrId = null;
            },"save":function (color) {
                 _vm.setColor(color);
            }}}):_vm._e(),_c('label',{staticClass:"vform-editor-label section"},[_vm._v("Font Color")]),_c('div',{staticClass:"clickable",style:({
            width: '20px',
            height: '20px',
            backgroundColor: _vm.element.fontColor
                ? _vm.element.fontColor
                : '',
            border: _vm.element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
        }),on:{"click":function($event){_vm.colorPickrId = 'fontColor'}}}),(_vm.colorPickrId === 'fontColor')?_c('color-picker',{ref:"colorpickr",staticClass:"step-settings-color-pickr",attrs:{"popup-style":true,"show-save-button":true,"value":_vm.element.fontColor ? _vm.element.fontColor : '#000'},on:{"changeColor":function (color) {
            _vm.setFontColor(color, false);
          },"close":function () {
                _vm.colorPickrId = null;
            },"save":function (color) {
                 _vm.setFontColor(color);
            }}}):_vm._e(),(_vm.element.backgroundColor || _vm.element.fontColor)?_c('div',{staticClass:"settings-button d-inline-block mt-2",on:{"click":function($event){_vm.element.backgroundColor = null; _vm.element.fontColor = null;}}},[_vm._v(" "+_vm._s(_vm.$t('resetColor'))+" ")]):_vm._e(),_c('br')]:_vm._e(),_c('label',{staticClass:"vform-editor-label section"},[_vm._v("Options")]),_c('div',{staticClass:"col row dflex"},[_c('div',{staticClass:"dflex"},[_c('label',{staticClass:"container vform-editor-body",attrs:{"for":'chkEmphasized' + _vm.$vnode.key}},[_vm._v(_vm._s(_vm.$t("emphasized"))+" "),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.element.emphasized),expression:"element.emphasized"}],attrs:{"type":"checkbox","id":'chkEmphasized' + _vm.$vnode.key},domProps:{"checked":Array.isArray(_vm.element.emphasized)?_vm._i(_vm.element.emphasized,null)>-1:(_vm.element.emphasized)},on:{"change":function($event){var $$a=_vm.element.emphasized,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.element, "emphasized", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.element, "emphasized", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.element, "emphasized", $$c)}}}}),_c('span',{staticClass:"checkmark"})])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }