<template>
  <div>
    <div
        class="settings-button float-right ml-3 d-flex"
        @click="$emit('exportToPDF')"
    >
      <icon v-if="!pdfGenerating" class="mr-2" type="pdf"/>
      <loading-spinner v-if="pdfGenerating" class="white mr-2"/>
      {{ $t("PDF") }}
      <div class="d-inline-block ml-1" v-if="pdfGenerating"> ({{ currentPDFPage }} / {{ totalPDFPages }})</div>
    </div>
    <div
        class="settings-button float-right ml-3 d-flex"
        @click="$emit('showLogSelection')"
        v-if="hasLog"
    >
      <icon v-if="!$refs.form || !$refs.form.pdfExportProcessing" class="mr-2" type="pdf"/>
      <loading-spinner v-if="pdfReportGenerating" class="white mr-2"/>
      {{ $t("PDFReport") }}
      <div class="d-inline-block ml-1" v-if="pdfReportGenerating"> ({{ currentPDFPage }} / {{
          totalPDFPages
        }})
      </div>
    </div>
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import Icon from "@/components/Icon.vue";
export default {
  name: "PDFExporter",
  components: {LoadingSpinner, Icon},
  props: {
    hasLog: {type: Boolean, default: false},
    pdfIsExporting: {type: Boolean, default: false}
  },
  data() {
    return {
      pdfGenerating: false,
      pdfReportGenerating: false,
      totalPDFPages: 0,
      currentPDFPage: 0,
    };
  },
}
</script>

<style scoped lang="scss">

</style>