<template>
  <tr v-if="!checkingPermission" :id="item.id"
      :class="[navByClickingRow && (hasEditPermission || hasViewPermission) ? 'hoverRow' : '', !hasEditPermission && !hasViewPermission ? 'no-access' : '', fullRowSelectable ? 'hoverRowHighlight' : '']"
      @click="(e) => { fullRowSelectable ? clickCheckBox(e, item.id) : null }">
    <popup
        v-if="showDeletePrompt || showUnlinkPrompt"
        @close="() => {showDeletePrompt = false; unlinkAbort();}"
    >
      <delete-prompt
          v-if="showDeletePrompt"
          slot="popupMainContent"
          custom-message="deletePromptQuestionWithParams"
          :custom-message-params="{name: toBeDeletedName}"
          @abort="() => {showDeletePrompt = false; this.toBeDeletedId = null;}"
          @confirm="() => {showDeletePrompt = false; deleteRowFinally();}"
      />
      <delete-prompt
          v-if="showUnlinkPrompt"
          slot="popupMainContent"
          custom-message="unlinkPromptQuestion"
          @abort="unlinkAbort"
          @confirm="() => {showUnlinkPrompt = false; clickCheckBoxFinally();}"
      />
    </popup>
    <td v-if="addPlaceholderStart"></td>
    <td v-if="canUnlink" class="selectLinking"
        v-on="canUnlink ? { click: (e) => {e.stopPropagation(); $emit('unlink', item)}} : {}"
    >
      <icon type="times"/>
    </td>
    <td v-if="selectableCheckbox" :class="selectableStyle === 'default' ? 'selectLinking' : ''"
        v-on="selectableCheckbox ? { click: (e) => {clickCheckBox(e, item.id ? item.id : item, item.selected ? item.selected : false);}} : {}"
    >
      <div v-if="selectableStyle === 'default'" :class="[item.selected ? 'selected' : '']"
      >
        <icon v-if="!item.selected" type="unlink"/>
        <icon v-else type="link"/>
      </div>
      <div v-if="selectableStyle === 'button'" :class="[item.selected ? 'selected' : '']"
      >
        <Button v-if="!item.selected">{{ $t("link") }}
          <icon type="link"/>
        </Button>
        <Button v-else>{{ $t("unlink") }}
          <icon type="unlink"/>
        </Button>
      </div>
    </td>
    <td v-for="(field, index) in fields" :key="index"
        :class="[field.type, 'field-id-' + field.id]"
        @click.self="() => {if((hasEditPermission || hasViewPermission) && navByClickingRow) {$router.push(item.editLink)}}">
      <div v-if="field.type === 'image'"
           @click.self="() => {if((hasEditPermission || hasViewPermission) && navByClickingRow) {$router.push(item.editLink)}}">
        <preview
            :asset-or-project-id="item.id"
            :limited-preview="true"
            :preview-id="item.id"
            :preview-uri="item.previewUri ? item.previewUri : item.profilePictureUri"
            :removable="false"
            :show-spinner-message="false"
            :source="resource"
            :type="'image'"
            class="table-thumbnail"
            spinner-size="default"
        />
      </div>
      <div v-else-if="field.type === 'boolean'">
        <icon :type="item[field.id] ? 'check' : 'times'"/>
      </div>
      <div v-else-if="field.type === 'appSlug' && item.type === 'webapp'">
        /{{ item[field.id] }}
      </div>
      <div v-else-if="field.type === 'appSlug' && item.type === 'app'">
        -
      </div>
      <div v-else-if="field.id === 'teams'">
        <div v-for="(team, index) in item.teams" :key="index" class="tag">
          <icon class="mr-1" type="users"/>
          {{ team.displayName ? team.displayName : team.name }}
        </div>
      </div>
      <div v-else-if="field.type === 'qrcode' || field.id === 'qrcode'">
        <qrcode-view
            :id="item.id"
            :source="item.type"
            class="table-thumbnail"
        />
      </div>
      <template v-else-if="field.type === 'color'">
        <div
            :class="[colorPickrId === item.id ? 'selected position-static mt-2' : '', 'color-preview', hasEditPermission || hasViewPermission ? 'editable' : '', !colorPickrId ? '' : 'hover']"
            :style="{backgroundColor: (item[field.id] ? item[field.id] : '#fff')}"
            @click.self="() => {colorPickrId = item.id; }">
        </div>
        <color-picker
            v-if="colorPickrId === item.id && (hasEditPermission || hasViewPermission)"
            ref="colorpickr"
            slot="popupMainContent"
            :has-transparency="false"
            :popup-style="true"
            :value="item[colorPickrId]"
            class="position-static"
            @close="() => {colorPickrId = null;}"
            @save="updateColor"
        />
      </template>
      <div v-else-if="field.type === 'icon'" class="preview table-thumbnail">
        <div class="square-image lighter">
          <icon class="preview-icon" v-if="field.virtual" :type="item.type" size="small"/>
          <icon class="preview-icon" v-else-if="!field.virtual" :type="item.type" size="small"/>
        </div>
      </div>
      <div v-else-if="field.type === 'editButton'">
        <Button v-if="hasEditPermission && !hideEditButton" :link="item.editLink"
                icon="pencil-alt" @click="$emit('edit', item.id)">
          {{ $t('edit') }}
        </Button>
        <Button v-else-if="hasViewPermission && !hideEditButton" :link="item.editLink"
                icon="pencil-alt" @click="$emit('edit', item.id)">
          {{ $t('view') }}
        </Button>
      </div>

      <Button
          v-else-if="field.type === 'editPopup'"
          icon="pencil-alt"
          @click="$emit('edit', item.id)">
        {{ $t('edit') }}
      </Button>
      <div v-else-if="(field.id === 'name' || field.id === 'displayName') && item[field.id] && item.editLink"
           class="name-field"
           @click="() => {if((hasEditPermission || hasViewPermission) && navByClickingRow) {$router.push(item.editLink)}}">
        <state-display v-if="showState" :state="item.state" class="float-right ml-4"/>
        <div v-if="item.organizationId && showOrganization" class="lighter">
          <icon v-if="hasEditPermission && navByClickingRow" type="folder"/>
          <icon v-else-if="navByClickingRow" type="lock"/>
          {{ $store.getters.getOrganizationDisplayName(item.organizationId) }}
        </div>
        <router-link
            v-if="$store.getters.isSuperAdmin"
            :to="item.editLink"
            class="table-row-title">
          <div class="overflow-text ">
            <div :class="[item[field.id] && item[field.id].length > 60 ? 'shorttext' : '']">
              {{ item[field.id].slice(0, 60) }}
              <span v-if="item[field.id] && item[field.id].length > 60">...</span>
            </div>
            <div v-if="item[field.id] && item[field.id].length > 30" class="longtext">{{ item[field.id] }}</div>
          </div>
        </router-link>
        <div v-else class="table-row-title">
          {{ item[field.id] }}
        </div>
        <div v-if="item.tags && showTags">
          <tag v-for="value in item.tags" :key="value.id" :clickable="false" :is-removable="false" :tag="value"/>
        </div>
        <div v-if="item.instances && item.instances.length && showInstances" class="mt-3 lighter table-row-instances">
          <h6>{{ $t('instances') }}</h6>

          <div v-for="(instance, index2) in item.instances" :key="index2">
            <div class="mt-1">
              <router-link :to="'/project/' + instance.projectId + '/linked_data?'" class="clickable withHoverBrightBackground p-1 d-block"
                           target="_blank">
                <icon class="mr-2 mt-1" size="0.9" type="angle-right"/>
                <content-display
                    :show-inline="true"
                    :value="instance.projectId"
                    class="link-name"
                    list-name="tableRowInstanceNames"
                    type="project"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <span v-else-if="field.type === 'date'">
                    {{ $store.getters.convertDate(item[field.id]) }}
                </span>
      <span v-else-if="field.type === 'state'">
                    <toggle-button v-if="hasEditPermission" :labels="true"
                                   :sync="true"
                                   :value="item[field.id] === 'a'"
                                   color="#DA5E06"
                                   @change="$emit('updateContent', {
                                            id: item.id,
                                            state: $event.value?'a':'i'
                                    })"/>
                </span>
      <span v-else-if="field.type === 'teamvisibility'">
                    {{ $t('teamVisibilityTypes.' + item[field.id]) }}
                </span>
      <span v-else-if="field.type === 'orgvisibility'">
                    {{ $t('orgVisibilityTypes.' + item[field.id]) }}
                </span>
      <span v-else-if="field.translate">
                    {{ field.translateFn ? $t(field.translateFn(field, item)) : $t(item[field.id]) }}
                </span>
      <span v-else-if="field.type === 'tag'">
                    <tag :clickable="false" :is-removable="false"
                         :tag="{id: item.id, name: item.name, color: item.color}"/>
                </span>
      <span v-else>
                    {{ item[field.id] }}
                </span>
    </td>
    <td v-if="selectableRows"
        v-on="selectableRows ? { click: () => $emit('selectRow', {id: item.id, assetId: item.assetId ? item.assetId : item.id, asset: item})} : {}">
      <div class="rowSelector">
        <icon type="eye"/>
      </div>
    </td>
    <td v-if="deletable" class="buttonField"
    >
      <button-field
          v-if="hasDeletePermission && (!item.versions || item.versions.length === 0)"
          :deletable="deletable && hasDeletePermission"
          :deleting="!!toBeDeletedId"
          :item-id="item.id"
          @deleteRow="deleteRow(item.id, item.displayName ? item.displayName : item.name)"
      />

    </td>
  </tr>
</template>

<script>
import Button from "../forms/Button";
import Icon from "../Icon";
import Preview from "../preview/Preview";
import ButtonField from "../forms/ButtonField";
import QrcodeView from "./QrcodeView";
import Popup from "../Popup";
import DeletePrompt from "../forms/DeletePrompt";
import Tag from "../Tag";
import ColorPicker from "./editors/ColorPicker";
import ContentDisplay from "./editors/ContentDisplay";
import StateDisplay from "@/components/StateDisplay";
import {OrganizationVisibility} from '../../enum';

export default {
  name: 'TableRow',
  components: {
    Button,
    Icon,
    Preview,
    ButtonField,
    QrcodeView,
    Popup,
    DeletePrompt,
    Tag,
    ColorPicker,
    ContentDisplay,
    StateDisplay
  },
  props: {
    fields: {type: Array, required: true,},
    item: {type: Object, required: true},
    deletable: {type: Boolean, default: false,},
    resource: {type: String, default: 'asset'},
    showOrganization: {type: Boolean, default: false},
    showState: {type: Boolean, default: false},
    deletePermission: {type: String, default: null},
    editPermission: {type: String, default: null},
    viewPermission: {type: String, default: null},
    customEditPermissionFunction: {required: false, default: null},
    customDeletePermissionFunction: {required: false, default: null},
    selectableCheckbox: {type: Boolean,},
    canUnlink: {type: Boolean, default: false},
    showTags: {type: Boolean, default: false},
    selectableRows: {type: Boolean,},
    navByClickingRow: {type: Boolean, default: false,},

    /**
     * todo: rename variable
     * @selectableRows Boolean Whether or not to make row-preview selectable
     */
    fullRowSelectable: {type: Boolean, default: false},

    showInstances: {type: Boolean, default: false},

    /**
     * @permissionByType If there are multiple permissions per type, use this one
     */
    permissionByType: {
      type: Object, default: () => {
      }
    },
    /***
     * Adds an empty td item at the beginning
     * */
    addPlaceholderStart: {type: Boolean, default: false},
    /***
     * How the selectableCheckbox should be styled
     * */
    selectableStyle: {type: String, default: "default"},
    hideEditButton: {type: Boolean, default: false}
  },
  data() {
    return {
      OrganizationVisibility: OrganizationVisibility,
      showDeletePrompt: false,
      showUnlinkPrompt: false,
      deleteConfirmed: false,
      toBeDeletedId: '',
      toBeDeletedName: '',
      clickedCheckBoxId: '',
      clickedCheckBoxAction: '',
      colorPickrId: null,
      hasEditPermission: false,
      hasDeletePermission: false,
      hasViewPermission: false,
      processing: false,
      checkingPermission: true,
      deleteLoadingState: false
    }
  },
  watch: {
    item: {
      deep: true,
      handler: function () {
        this.checkPermission();
      }
    }
  },
  mounted() {
    this.checkPermission();
  },
  methods: {
    async checkPermission() {
      this.checkingPermission = true;
      let editDispatchMethod = 'checkTeamOrgPermission';
      let deleteDispatchMethod = 'checkTeamOrgPermission';
      if (this.editPermission === 'userIsOrganizationMember') {
        deleteDispatchMethod = editDispatchMethod = 'userIsOrganizationMember';
      }
      if(this.customEditPermissionFunction) {
        console.log('got custom edit, checking...')
        this.hasEditPermission = await this.customEditPermissionFunction(this.item);
      } else {
        this.hasEditPermission = await this.$store.dispatch(editDispatchMethod, {
          teams: await this.$store.getters.checkIsTeamPermission(this.editPermission) ? [{id: this.item.id}] : this.item.teams,
          op: this.permissionByType && this.permissionByType[this.item.type] ? this.permissionByType[this.item.type] : this.editPermission,
          organizationId: this.item.organizationId ? this.item.organizationId : this.item.id
        })
      }
      this.hasViewPermission = await this.$store.dispatch(editDispatchMethod, {
        teams: await this.$store.getters.checkIsTeamPermission(this.viewPermission) ? [{id: this.item.id}] : this.item.teams,
        op: this.viewPermission,
        organizationId: this.item.organizationId ? this.item.organizationId : this.item.id
      })
      if(this.customDeletePermissionFunction) {
        console.log('got custom delete, checking...')
        this.hasDeletePermission = await this.customDeletePermissionFunction(this.item);
      } else {
        console.log('using standard delete')
        this.hasDeletePermission = await this.$store.dispatch(deleteDispatchMethod, {
          teams: await this.$store.getters.checkIsTeamPermission(this.deletePermission) ? [{id: this.item.id}] : this.item.teams,
          op: this.deletePermission,
          organizationId: this.item.organizationId ? this.item.organizationId : this.id
        })
      }

      this.checkingPermission = false;
    },
    clickCheckBox: function (e, id, selected/*, item*/) {
      if (!this.processing) {
        this.processing = true;
        e.stopPropagation();
        this.clickedCheckBoxId = id;
        this.clickedCheckBoxAction = selected ? 'remove' : 'add';
        if (selected) {
          this.showUnlinkPrompt = true;
        } else {
          this.clickCheckBoxFinally();
        }
      }
    },
    clickCheckBoxFinally: function () {
      this.$emit('selectCheckbox', this.clickedCheckBoxId, this.clickedCheckBoxAction, this.item);
      let $this = this;
      setTimeout(function () {
        $this.processing = false;
      }, 800);
      this.clickedCheckBoxAction = '';
      this.clickedCheckBoxId = '';
    },
    unlinkAbort: function () {
      this.showUnlinkPrompt = false;
      this.processing = false;
      this.clickedCheckBoxAction = '';
      this.clickedCheckBoxId = '';
    },
    updateColor: function (color) {
      let id = this.colorPickrId;
      this.colorPickrId = null;
      this.$emit('updateColor', {id: id, color: color});
    },
    rowIsClickable: function () {
      if (this.navByClickingRow && (!this.editPermission || this.hasEditPermission)) {
        return true;
      }
    },
    deleteRow: function (id, name) {
      this.showDeletePrompt = true;
      this.toBeDeletedId = id;
      this.toBeDeletedName = name;
    },
    deleteRowFinally: function () {
      this.$emit('deleteRow', this.toBeDeletedId);
      this.deleteConfirmed = false;
      this.toBeDeletedId = null;
    },
  }
}
</script>
