var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
          'vform-slide-control-element',
          _vm.invisible ? 'invisible' : '',
          _vm.config.bottomDropZone ? 'mb-0' : '',
          _vm.tiles ? 'vform-tiles' : '',
          !_vm.tiles ? _vm.config.alignment : '',
          'border-radius', 'multi-line',
          _vm.numOfElementsInZone && _vm.numOfElementsInZone > 1 ? 'col-'+_vm.getDropzoneCol() : '' ],style:({
          flex: _vm.config.bottomDropZone ? '0 0 ' + _vm.getDropzoneWidth() + '%' : '',
          paddingTop:
              _vm.config.height && _vm.config.showastiles ? _vm.config.height / 2 + 'vh' : '',
          backgroundColor: _vm.tiles && _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
          color: _vm.config.fontColor ? _vm.config.fontColor : (_vm.fontColor ? _vm.fontColor : ''),
          //maxWidth: !config.isAloneInZone && config.bottomDropZone ? '49%' : '',
      }),attrs:{"id":_vm.buttonId},on:{"click":_vm.click,"mouseenter":function($event){_vm.tiles ? _vm.setColors(_vm.buttonId) : function () {}},"mouseleave":function($event){_vm.tiles ? _vm.unsetColors(_vm.buttonId) : function () {}}}},[_vm._t("dropzone"),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected},on:{"editProperty":_vm.editProperty,"removeBlock":function($event){return _vm.$emit('removeBlock')}}}):_vm._e(),(_vm.tiles)?_c('div',{class:[
              'inner d-flex border-radius',
              !_vm.disabled ? 'clickable' : 'no-button-hover-effect',
              _vm.tiles ? 'p-2' : 'simple-button',
              _vm.config.bottomDropZone ? 'next-button' : '',
              !_vm.tiles ? _vm.config.alignment : '',
              _vm.config.textAlignment ? ("text-" + (_vm.config.textAlignment)) : '' ],style:({
              'justify-content': 'center',
              'flex-direction' : 'column',
               borderColor: _vm.config.borderColor ? _vm.config.borderColor : '#000'
          })},[(true && _vm.getImage(_vm.config, _vm.lang))?_c('preview',{staticClass:"tile-button-image-wrapper pt-1 pb-1",attrs:{"asset-id":_vm.getImage(_vm.config, _vm.lang),"background-mode":false,"img-auto-shrink-to-fit":true,"may-load-now":_vm.isActive,"offline-mode":_vm.offlineMode,"preview-id":_vm.getImage(_vm.config, _vm.lang),"removable":false,"show-spinner-message":false,"size":_vm.config.iconSize,"classes":"square-image","source":"asset","spinner-size":"default"},on:{"contentLoaded":_vm.increaseCounter}}):_vm._e(),_c('div',{},[_vm._v(_vm._s(_vm.getLabel(_vm.config, _vm.lang) ? _vm.getLabel(_vm.config, _vm.lang) : _vm.$t("next"))+" ")])],1):_c('div',{class:[
              'inner d-flex align-items-center',
              !_vm.disabled ? 'clickable' : 'no-button-hover-effect',
              _vm.invisible ? 'invisible' : '',
              _vm.tiles ? 'p-2' : '',
              _vm.config.bottomDropZone ? 'next-button' : 'simple-button',
              !_vm.tiles ? _vm.config.alignment : '',
              !_vm.tiles ? (_vm.config.textAlignment ? ("text-" + (_vm.config.textAlignment)) : '') : '' ],style:({
            backgroundColor: _vm.config.backgroundColor ? _vm.config.backgroundColor : '',
            borderColor: _vm.config.borderColor ? _vm.config.borderColor : '#000',
            color: _vm.config.fontColor ? _vm.config.fontColor : (_vm.fontColor ? _vm.fontColor : '')
        }),attrs:{"id":_vm.buttonId},on:{"mouseenter":function($event){!_vm.tiles ? _vm.setColors(_vm.buttonId) : function () {}},"mouseleave":function($event){!_vm.tiles ? _vm.unsetColors(_vm.buttonId) : function () {}}}},[(_vm.getImage(_vm.config, _vm.lang))?_c('preview',{staticClass:"mini vform-preview-img vform-icon-img mr-2",attrs:{"asset-id":_vm.getImage(_vm.config, _vm.lang),"may-load-now":_vm.isActive,"offline-mode":_vm.offlineMode,"preview-id":_vm.getImage(_vm.config, _vm.lang),"removable":false,"show-spinner-message":false,"source":"asset","spinner-size":"default"},on:{"contentLoaded":_vm.increaseCounter}}):_vm._e(),_c('div',{class:['text wrap-break', _vm.getImage(_vm.config, _vm.lang) ? 'with-icon' : 'without-icon']},[(!_vm.config.buttonStyle ||  _vm.config.buttonStyle === 'Text' || _vm.config.buttonStyle === 'TextAndArrow')?[_vm._v(_vm._s(_vm.getLabel(_vm.config, _vm.lang) ? _vm.getLabel(_vm.config, _vm.lang) : _vm.$t("next")))]:[_c('div',{domProps:{"innerHTML":_vm._s('&nbsp;')}})]],2),(_vm.config.buttonStyle === 'Arrow' || _vm.config.buttonStyle === 'TextAndArrow')?_c('div',{staticClass:"ml-3"},[_c('icon',{attrs:{"type":"angle-right"}})],1):_vm._e()],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }