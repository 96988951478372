var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[(_vm.editing)?[_c('table',{staticClass:"table-fixed mat-table"},[_c('tr',[_c('th',{},[_c('div',{staticClass:"vform-label white"},[_vm._v(_vm._s(_vm.$t('MATERIAL')))])]),_c('th',{staticClass:"clr"},[_c('div',{staticClass:"vform-label white"},[_vm._v(_vm._s(_vm.$t('COLOR')))])]),_c('th',[_c('div',{staticClass:"vform-label white mb-0 pb-0"},[_vm._v(_vm._s(_vm.$t('TILING')))])]),_c('th',{staticClass:"del"},[_c('div',{staticClass:"vform-label white mb-0 pb-0"},[_vm._v(_vm._s(_vm.$t('DEL')))])])]),_vm._l((_vm.rows),function(item,index){return _c('tr',{key:index,staticClass:"align-items-center"},[_c('td',[(_vm.loading)?_c('loading-spinner'):_c('select',{directives:[{name:"model",rawName:"v-model",value:(item.material),expression:"item.material"}],staticClass:"custom-select form-text-dark material-select darker",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(item, "material", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.sortedItems),function(matItem,matIndex){return _c('option',{key:matIndex,staticClass:"form-text mb-1",domProps:{"value":matItem.id}},[_vm._v(" "+_vm._s(_vm.$t(matItem.displayName ? matItem.displayName : matItem.name))+" ")])}),0)],1),_c('td',{staticClass:"clr"},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('div',{class:[
                          _vm.colorPickrId === index ? 'selected' : '',
                          'colormat-color-preview mr-2 clickable align-items-center',
                          _vm.colorPickrId === index ? 'hover' : '' ],style:({
                          position: 'relative !important',
                          backgroundColor: item.color ? item.color : 'white',
                          border: '1px solid ' + (item.color ? item.color : '#fff')
                      }),on:{"click":function($event){if($event.target !== $event.currentTarget){ return null; }return (function () {
                              _vm.colorPickrId = index;
                          })($event)}}},[(_vm.colorPickrId === index)?_c('color-picker',{ref:"colorpickr",refInFor:true,attrs:{"slot":"popupMainContent","popup-style":true,"value":item.color ? item.color : '#fff'},on:{"close":function () {
                                  _vm.colorPickrId = null;
                              },"save":function (color) {
                                  _vm.colorPickrId = null;
                                  return _vm.updateColor(color, index);
                              }},slot:"popupMainContent"}):_vm._e()],1),(item.color)?_c('div',{staticClass:"remove-color-button clickable pl-1 pr-1 d-inline-block",on:{"click":function($event){item.color = null}}},[_c('icon',{attrs:{"size":"0.9","type":"trash"}})],1):_vm._e()])]),_c('td',{staticClass:"ml-3"},[_c('vector-widget',{staticClass:"mat-color-vector-widget p-1",attrs:{"editing":true,"hide-save-button":true,"value":item.tiling,"type":"v2"},on:{"save":function (value) {_vm.updateTiling(value, index)},"update":function (value) {_vm.updateTiling(value, index)}}})],1),_c('td',[_c('div',{staticClass:"delete-button pl-1 pr-1 clickable",on:{"click":function($event){return _vm.deleteRow(index)}}},[_c('icon',{attrs:{"size":"0.8","type":"trash"}})],1)])])})],2),_c('div',{staticClass:"w-100"}),_c('div',{staticClass:"settings-button d-inline-block mr-2 mt-1",on:{"click":_vm.addRow}},[_c('icon',{attrs:{"type":"plus"}})],1),(_vm.showSaveButton)?_c('div',{staticClass:"settings-button d-inline-block",on:{"click":_vm.save}},[_vm._v(_vm._s(_vm.$t("save")))]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.error))])]:_vm._l((_vm.rows),function(row,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mat-display"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('MATERIAL')))]),_vm._v(" "+_vm._s(_vm.getMaterial(row.material))+" ")]),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('COLOR')))]),_c('div',{class:[
                          'colormat-color-preview mr-2 clickable align-items-center' ],style:({
                          position: 'relative !important',
                          backgroundColor: row.color ? row.color : 'transparent',
                          border: '1px solid ' + (row.color ? row.color : '#fff')
                      })}),_c('br'),_vm._v(" "+_vm._s(row.color)+" "),(!row.color)?_c('div',[_vm._v("'N / A'")]):_vm._e()]),_c('div',{staticClass:"ml-3"},[_c('div',{staticClass:"label"},[_vm._v(_vm._s(_vm.$t('TILING')))]),_vm._v(" "+_vm._s(row.tiling)+" ")])])])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }