<template>
    <div>
      <label class="vform-editor-label section">Type</label>
      <div>
        <select class="custom-select form-text-dark" v-model="element.navType">
          <option value="default">Course Overview</option>
          <option value="contextual">Course Module</option>
        </select>
      </div>

      <label class="vform-editor-label section">Options</label>
      <div class="col row dflex">
        <div class="dflex">
          <label
              class="container vform-editor-body"
              :for="'showCatsOnly' + $vnode.key"
          >{{ $t("showCatsOnly") }}
            <input
                type="checkbox"
                :id="'showCatsOnly' + $vnode.key"
                v-model="element.showCatsOnly"
                @change="adapt"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>

      <template v-if="!element.showCatsOnly">
        <label class="vform-editor-label section">Show only items from category</label>
        <div>
          <select class="custom-select form-text-dark" v-model="element.subItemsFromCat">
            <option :value="null">Show all items</option>
            <option :key="item.id" :value="item.id" v-for="item in courseCats">{{ item.name }}</option>
          </select>
        </div>
      </template>

      <div v-if="element.showCatsOnly" :class="['course-cat-overlay-edit mt-3']">
          <div
              v-if="!selectedCat || selectedCat === cat.id"
              @click="selectCat(cat)"
              class="cat-selector mb-1"
              :key="cat.id"
              v-for="cat in courseCats">
            {{ cat.name }}
            <div class="d-inline-block float-right mr-1" @click.stop="selectedCat = null" v-if="selectedCat">
              <icon type="times" />
            </div>
            <div class="d-inline-block float-right mr-1" @click.stop="showHideCat(cat)" v-if="!selectedCat">
              <icon :type="element.hiddenCats.includes(cat.id) ? 'eye-slash' : 'eye'" />
            </div>
            <div class="p-1" v-if="selectedCat">
              <v-form-go-to-component
                  class="w-100 mb-5"
                  :organization-id="organizationId"
                  :slides="slides"
                  :project-id="projectId"
                  :element="element.goToCats[selectedCat]"
                  :steps="steps"
                  :current-lang="currentLang"
                  default-action="NoAction"
                  dix-selector="actionDix"
              />
            </div>
        </div>
      </div>

      <template>
        <label class="vform-editor-label section">Background Color</label>
        <div
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="colorPickrId = element.uuid"
            class="clickable"
        />
        <color-picker
            v-if="colorPickrId === element.uuid"
            ref="colorpickr"
            :popup-style="true"
            :show-save-button="true"
            :value="element.backgroundColor ? element.backgroundColor : '#fff'"
            class="step-settings-color-pickr"
            @changeColor="
              (color) => {
                setColor(color, false);
              }"
            @close="
                () => {
                    colorPickrId = null;
                }
            "
            @save="
                (color) => {
                     setColor(color);
                }
            "
        />
        <label class="vform-editor-label section">Font Color</label>
        <div
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="colorPickrId = 'fontColor'"
            class="clickable"
        />
        <color-picker
            v-if="colorPickrId === 'fontColor'"
            ref="colorpickr"
            :popup-style="true"
            :show-save-button="true"
            :value="element.fontColor ? element.fontColor : '#000'"
            class="step-settings-color-pickr"
            @changeColor="
              (color) => {
                setFontColor(color, false);
              }"
            @close="
                () => {
                    colorPickrId = null;
                }
            "
            @save="
                (color) => {
                     setFontColor(color);
                }
            "
        />
        <div
            v-if="element.backgroundColor || element.fontColor"
            class="settings-button d-inline-block mt-2"
            @click="element.backgroundColor = null; element.fontColor = null;">
          {{ $t('resetColor') }}
        </div><br />
      </template>
    </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import { vFormControls, placeholders } from "@/enum";
import ColorPicker from "../widgets/editors/ColorPicker";
import CourseMixinJs from "@/components/vForm/course/CourseMixin.js.vue";
import vFormGoToComponent from "@/components/vForm/vFormGoToComponent.vue";
import Icon from "@/components/Icon.vue";
export default {
    name: "vFormTEXT",
    components: {
      vFormGoToComponent,
        ColorPicker,
      Icon
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
        formId: {type: String, default: ""},
        slides: { type: Object, required: true },
    },
    mixins: [vFormElementMixin, CourseMixinJs],
    data() {
        return {
            forceReRenderKey: 0,
            colorPickrId: null,
            vFormControls: vFormControls,
            placeholders: placeholders,
            selectedCat: null
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls.COURSE_BLOCK;
        this.loadvFormMetaValuesForCourse(this.formId);
        if(!this.element.goToCats) {
          this.$set(this.element, "goToCats", {})
        }
        if(!this.element.hiddenCats) {
          this.$set(this.element, "hiddenCats", []);
        }
    },
  methods: {
    adapt() {
      if(this.element.showCatsOnly) {
        this.$set(this.element, "subItemsFromCat", null);
      } else {
        this.$set(this.element, "hiddenCats", []);
      }
    },
    showHideCat(cat) {
      const index = this.element.hiddenCats.findIndex(item => {return item === cat.id});
      if(index !== -1) {
        this.element.hiddenCats.splice(index, 1);
        //this.$set(this.element, "hiddenCats", hd);
      } else {
        this.element.hiddenCats.push(cat.id);
        //this.$set(this.element, "hiddenCats", hd);
      }
    },
    selectCat(cat) {
      this.selectedCat = cat.id;
      if(!this.element.goToCats[cat.id]) {
        this.$set(this.element.goToCats, cat.id, {
          actionDix: {
            dix: {
              Unknown: ''
            }
          }
        })
      }
    },
    setCats() {
      this.cats = this.$refs.courseNav.courseCats;
    },
    editItems() {
      this.editingItems = !this.editingItems;
      if(this.editingItems) {
        this.setCats();
      } else {
        this.selectedCat = null;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
.course-cat-overlay-edit {
  .cat-selector {
    color: #fff;
    border-radius: 2px;
    padding: 3px;
    cursor: pointer;
    background-color: var(--vform-editor-ui-secondary-color);
    -webkit-box-shadow: 0 8px 6px -5px black;
    box-shadow: 0 5px 6px -5px black;
    &:hover, &.active {
      background-color: #000;
      color: #fff;
    }
  }
}
</style>
