<template>
  <div>
    <div @click="createFile" class="d-inline-block settings-button">{{ $t('Export instances') }}</div>
    <div class="form-error mt-1" v-if="msg">{{ msg }}</div>
  </div>
</template>

<script>
export default {
  name: "InstanceCSVExporter",
  props: {
    assemblyId: {type: String, required: true},
  },
  data() {
    return {
      loading: false,
      msg: '',
    };
  },
  methods: {
    async createFile() {
      await this.$store.dispatch('clientCreateAssemblyInstanceFile', {
        id: this.assemblyId
      }).then(() => {
        this.msg = this.$t('The file is being created, please check the files tab of this assembly for a file called instances.json');
      }).catch(() => {
        this.msg = 'the file could not be created';
      })
    }
  }
}
</script>