var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative w-100"},[_vm._t("dropzone"),(_vm.showTestLivePopup)?_c('portal',{attrs:{"to":"slideShowPopupSection"}},[_c('popup',{attrs:{"is-not-closable":true,"elevated-z-index":1000}},[_c('div',{attrs:{"slot":"popupMainContent"},slot:"popupMainContent"},[_vm._v(" "+_vm._s(_vm.$t('ModeQuestion'))),_c('br'),_vm._l((Object.values(_vm.vformModes)),function(mode){return _c('div',{key:mode,staticClass:"settings-button d-inline-block switched-colors mr-2",on:{"click":function($event){return _vm.setMode(mode)}}},[_vm._v(" "+_vm._s(_vm.$t('vformMode.' + mode))+" ")])})],2)])],1):_vm._e(),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected},on:{"editProperty":_vm.editProperty,"removeBlock":function($event){return _vm.$emit('removeBlock')}}}):_vm._e(),_c('div',_vm._l((_vm.config.fields),function(field,index){return _c('div',{key:index + 34534535},[_c('div',{staticClass:"vform-label-for-input"},[_vm._v(_vm._s(_vm.getLabel(field, _vm.lang) ? _vm.getLabel(field, _vm.lang) : _vm.$t('userId'))+" "+_vm._s(field.isID ? '*' : '')+" ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.values[index]),expression:"values[index]"}],class:['vform-form-text w-100 mb-1', _vm.markAsMissing[index] && _vm.markAsMissing[index].missing ? 'vform-error' : '', _vm.disabled ? 'lighter' : ''],attrs:{"disabled":_vm.disabled,"type":"text"},domProps:{"value":(_vm.values[index])},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }index === (_vm.config.fields.length - 1) ? _vm.click : function () {}},"change":function($event){_vm.$emit('storeData', _vm.config.uuid, _vm.retrieveData());},"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.values, index, $event.target.value)}}})])}),0),_c('div',{class:[
    'mt-2 clickable vform-slide-control-element',
    _vm.config.loginButton.alignment
    ],on:{"click":_vm.click}},[_c('div',{class:['inner d-flex align-items-center BUTTON_ITEM', _vm.tiles ? 'p-2' : '', _vm.config.bottomDropZone ? 'next-button' : 'simple-button', _vm.config.loginButton.alignment],style:({
          backgroundColor: _vm.config.loginButton.backgroundColor ? _vm.config.loginButton.backgroundColor : '',
          color: _vm.config.loginButton.fontColor ? _vm.config.loginButton.fontColor : (_vm.fontColor ? _vm.fontColor : ''),
          borderColor: _vm.config.loginButton.borderColor ? _vm.config.loginButton.borderColor : (_vm.config.loginButton.backgroundColor ? _vm.config.loginButton.backgroundColor : ''),

      })},[(_vm.getImage(_vm.config.loginButton, _vm.lang))?_c('preview',{staticClass:"mini vform-preview-img vform-icon-img mr-2",attrs:{"asset-id":_vm.getImage(_vm.config.loginButton, _vm.lang),"may-load-now":_vm.isActive,"preview-id":_vm.getImage(_vm.config.loginButton, _vm.lang),"removable":false,"source":"asset","spinner-size":"default","show-spinner-message":false},on:{"contentLoaded":_vm.increaseCounter}}):_vm._e(),_c('div',{class:['text', _vm.getImage(_vm.config.loginButton, _vm.lang) ? 'with-icon' : 'without-icon']},[(!_vm.config.loginButton.buttonStyle ||  _vm.config.loginButton.buttonStyle === 'Text' || _vm.config.loginButton.buttonStyle === 'TextAndArrow')?[_vm._v(" "+_vm._s(_vm.getLabel(_vm.config.loginButton, _vm.lang) ? _vm.getLabel(_vm.config.loginButton, _vm.lang) : _vm.$t('login'))+" ")]:[_c('div',{domProps:{"innerHTML":_vm._s('&nbsp;')}})]],2),(_vm.config.loginButton.buttonStyle === 'Arrow' || _vm.config.loginButton.buttonStyle === 'TextAndArrow')?_c('div',{staticClass:"ml-3"},[_c('icon',{attrs:{"type":"angle-right"}})],1):_vm._e()],1)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }