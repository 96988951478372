<template>
  <div class="course-navigation">
    <div v-if="showPlaceholder">
      Course Navigation Placeholder
    </div>
    <!--todo: remove showCatItemsOnly.includes(courseCat.name) when releasing, not necessary anymore-->
    <div  v-for="(courseCat, index) in courseCats"
         v-if="showCat(courseCat)"
         :key="courseCat.id">
      <div v-if="showCatNameInternal"
           @click="$emit('catGotClicked', courseCat.id)"
           :class="['category-name p-1 mb-2 border-radius', index !== 0 ? 'mt-2' : '',
           itemIsClickable(courseCat) ? 'is-clickable' : '',
          getProgressInPercent(courseCat.id) > 0 ? 'startedCourseItem' : 'notdone2',
           getProgressInPercent(courseCat.id) === 100 ? 'doneCourseItem' : 'notdone',]">{{ courseCat.name }}
        {{ getProgressInPercent(courseCat.id) }}%
        <icon v-if="itemIsClickable(courseCat)" type="angle-right" class="float-right" />
      </div>
      <!--<span class="lighter clickable" @click="removeCourseCat(courseCat.name)">Remove</span>
      <span v-if="index !== 0" @click="moveCourseCat(courseCat.name)" class="clickable">move up</span>-->
      <course-item-node
          v-for="(node, index) in getCourseTreeForCat(courseCat.id)"
          v-if="!showCatsOnly"
          :key="node.id + forceReRenderKey"
          :active-nodes="baseCourseItem ? [baseCourseItem.id] : []"
          :child-index="index"
          :editable="false"
          :move-course-item-up="moveCourseItemUp"
          :node="node"
          :progress-data="$store.getters.getvFormUserSessionData ? $store.getters.getvFormUserSessionData : {}"
          :remove-course-item="removeCourseItem"
          @gotClicked="(item) => {$emit('gotClicked', {
            projectId: item.id,
            courseOriginProjectId,
            course: formId})
          }"
      />
    </div>
    <course-item-node
        v-for="(node, index) in getCourseTreeForCat(null)"
        v-if="!showCatsOnly"
        :key="node.id + forceReRenderKey"
        :active-nodes="baseCourseItem ? [baseCourseItem.id] : []"
        :child-index="index"
        :editable="false"
        :move-course-item-up="moveCourseItemUp"
        :node="node"
        :progress-data="$store.getters.getvFormUserSessionData ? $store.getters.getvFormUserSessionData : {}"
        :remove-course-item="removeCourseItem"
        :show-progress="false"
        @gotClicked="(item) => {$emit('gotClicked', {
        projectId: item.id,
        courseOriginProjectId,
        course: formId
      })
      }"
    />
  </div>
</template>
<script>
import CourseMixinJs from "@/components/vForm/course/CourseMixin.js.vue";
import CourseItemNode from "@/components/vForm/course/CourseItemNode.vue";
import {mapState} from 'vuex';
import Icon from "@/components/Icon.vue";
export default {
  name: "CourseNavigation",
  components: {CourseItemNode, Icon},
  props: {
    /**
     * This is the id of the lobby project
     * */
    courseProjectId: {type: String, required: true},
    projectId: {type: String, required: true},
    /**
     * This is ALWAYS the id of the form asset which is being the course container
     * **/
    formId: {type: String, required: true},
    showCatsOnly: {type: Boolean, default: false},
    showCatItemsOnly: {type: Array, default: () => {return []}},
    hiddenCatItems: {type: Array, default: () => {return []}},
    showCatName: {type: Boolean, default: true},
    activeItem: {type: String, default: ""},
    // this means that the item will show the current selected course item and its siblings and highlight the active one
    contextual: {type: Boolean, default: false},
    showPlaceholder: {type: Boolean, default: false},
    clickableCats: {type: Array, default: () => {return []}
    },
  },
  mixins: [CourseMixinJs],
  data() {
    return {
      forceReRenderKey: 0,
      showCatNameInternal: false,
      showCatItemsOnlyInternal: []
    };
  },
  computed: {
    ...mapState({
      vFormUserSession: state => state.vformpage.vFormUserSession,
    }),
  },
  watch: {
    showCatName() {
      this.setShowCatName();
    },
    showCatItemsOnly() {
      this.setShowCatsInternal();
    }
  },
  beforeMount() {
    this.init();
    this.setShowCatName();
    this.setShowCatsInternal();
  },
  methods: {
    setShowCatsInternal() {
      this.showCatItemsOnlyInternal = this.showCatItemsOnly;
    },
    showCat(courseCat) {
      if(this.baseCourseItem && this.baseCourseItem.cat) {
        return courseCat.id === this.baseCourseItem.cat;
      }
      else if(this.showCatItemsOnlyInternal && this.showCatItemsOnlyInternal.length) {
        return this.showCatItemsOnlyInternal.includes(courseCat.name) || this.showCatItemsOnlyInternal.includes(courseCat.id)
      }
      else if(this.hiddenCatItems && this.hiddenCatItems.length) {
        return !this.hiddenCatItems.includes(courseCat.id)
      }
      return true;
    },
    itemIsClickable(cat) {
      return cat.id && this.clickableCats.includes(cat.id)
    },
    getProgressInPercent(cat) {
      const progressData = this.$store.getters.getvFormUserSessionData;
      if (this.courseItems.length && progressData) {
        const allItems = this.courseItems.filter(item => {
          return (cat && item.cat === cat) || (!cat && !item.cat);
        });
        let cumulatedSteps = 0;
        let cumulatedDone = 0;
        if (allItems) {
          allItems.map(item => {
            const {id} = item;
            if (progressData[id] && progressData[id].completedSteps) {
              const current = progressData[id].completedSteps.length;
              const total = progressData[id].numberOfSteps;
              cumulatedDone += current;
              cumulatedSteps += total;
            }
          })
        }
        if (cumulatedDone) {
          // 5 von 10 steps
          // total = 7 = 100%, aktuell = 1
          return parseInt((100 / cumulatedSteps * cumulatedDone).toFixed(0));
        }
      }
      return 0;
    },
    setShowCatName() {
      this.showCatNameInternal = !!this.showCatName;
    },
    async init() {
      console.log('init... int init init')
      await this.loadvFormMetaValuesForCourse(this.formId);
      if (this.contextual && this.courseItems.length) {
        this.setBaseCourseItem(this.projectId);
        this.courseItems.push({
          name: 'Back to overview',
          id: this.courseProjectId
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.course-navigation {
  min-height: 20px;
  font-size: 0.9rem;
}

.category-name {
  color: #fff;
  transition: all 300ms ease;
  background-color: #5d7366;
  &.is-clickable {
    cursor: pointer;
    background-color: #354044;
    &:hover {
      background-color: #ec7412;
    }
  }
  &.startedCourseItem {
    background-color: #e36d2e;
  }
  &.doneCourseItem {
    background-color: #479f47;
  }
}
</style>