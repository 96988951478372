<template>
  <div
      :class="['w-100 vform-text-display position-relative', config.emphasized ? 'vform-emphasized font-weight-bold' : '', config.backgroundColor ? 'with-bg' : 'without-bg']"
      :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: !config.emphasized && config.fontColor ? config.fontColor : '',
            fontFamily: getFontName()
          }">
    <slot name="dropzone"></slot>
    <!--todo: check if preview is really necessary anymore...-->

    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <course-navigation
        ref="courseNav"
        v-if="config && $store.getters.getvFormUserSession && $store.getters.getvFormUserSession.courseOriginProjectId"
        :project-id="projectId"
        :course-project-id="$store.getters.getvFormUserSession.courseOriginProjectId"
        :form-id="$store.getters.getvFormUserSession.course ? $store.getters.getvFormUserSession.course : formId"
        :show-cats-only="config.showCatsOnly"
        :show-cat-items-only="config.subItemsFromCat ? [config.subItemsFromCat] : []"
        :hidden-cat-items="config.hiddenCats"
        :show-cat-name="!config.subItemsFromCat"
        :contextual="!config.navType || config.navType === 'contextual'"
        :show-placeholder="editable && config.navType && config.navType === 'contextual'"
        :clickable-cats="config.goToCats ? Object.keys(config.goToCats) : []"
        @gotClicked="goToProject"
        @catGotClicked="catGotClicked"
    />
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import CourseNavigation from "@/components/vForm/course/CourseNavigation.vue";
export default {
  name: "CourseBlock",
  components: {
    EditorOverlay,
    CourseNavigation,
  },
  mixins: [ElementMixin],
  props: {
    formId: {type: String, required: true},
    organizationId: {type: String, required: true},
    slides: { type: Array, default: () => { return []}},
    steps: { type: Array, default: () => { return []}},
  },
  data() {
    return {
      localString: "",
      cats: [],
      editingItems: false,
    };
  },
  beforeMount() {
    this.setUniqueId();
  },
  mounted() {
    this.increaseCounter();
    this.addListener(document, "click", this.documentClick, 'textdisplay');
  },
  methods: {
    documentClick() {
      this.leaveEditing(this.uniqueId);
    },
    setEditing() {
      if (this.editable) {
        this.editing = true;
        this.setCursor(this.uniqueId);
      }
    },
    onInput(e) {
      this.setLabel(this.config, this.lang, e.target.innerText);
    },
    validate() {
      return true;
    },
    checkRequired() {
      return true;
    },
    getData() {
      return {data: this.getLabel(this.config, this.lang), type: 'text'};
    },
    async goToProject(conf) {
      //const {projectId} = conf;
      /*const lastVisitedStep = await this.$store.getters.getvFormUserSessionLastKnownStep(projectId);
      if(lastVisitedStep) {
        conf.stepId = lastVisitedStep;
      }*/
      //conf.doNotLog = true;
      this.$emit('goToProject', conf)
    },
    catGotClicked(catId) {
      const {goToCats} = this.config;
      if(goToCats && goToCats[catId]) {
        this.handleGoTo(goToCats[catId])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// inner div
.with-bg {
  padding: 5px 6px;
}

// outer div
.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;
  margin-bottom: 0.6em;
  &.with-bg {
    width: calc(100% + 20px) !important;
  }
}
</style>