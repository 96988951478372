<template>
  <div></div>
</template>
<script>
export default {
  name: "vFormElementMixin",
  props: {
    steps: {type: Array, default: () => {return []}},
    globals: {type: Object, default: () => {return {}}},
    element: {type: Object, required: true},
    organizationId: {type: String, default: ""},
    projectId: {type: String, required: true},
    vSTAGEContext: {type: Boolean, default: false},
    currentLang: {type: String, required: true},
  },
  data() {
    return {
      colorPickrId: null,
      forceReRenderKey: 0
    }
  },
  watch: {
    "element.label": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
    "element.options": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
    "element.fields": {
      deep: true,
      handler: function () {
        this.$emit("dirty");
      },
    },
  },
  methods: {
    setFontColor(color, closePickr = true) {
      if (closePickr) {
        this.colorPickrId = null;
      }
      this.$set(this.element, "fontColor", color);
      this.forceReRenderKey++;
    },
    setColor(color, closePickr = true) {
      if (closePickr) {
        this.colorPickrId = null;
      }
      this.$set(this.element, "backgroundColor", color);
      this.forceReRenderKey++;
    },
  }
};
</script>