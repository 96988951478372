<template>
  <div/>
</template>

<script>
import { Document, Packer, Paragraph, TabStopType, Tab, PositionalTab, PositionalTabAlignment, PositionalTabRelativeTo, PositionalTabLeader, TextRun, Header, Footer, PageNumber, TableRow, TableCell, Table, ImageRun, AlignmentType, WidthType } from "docx";
import { saveAs } from "file-saver";
import vFormAndProjectMixin from "../vFormAndProjectMixin.js.vue";
import DateMixin from "../../mixins/DateMixin.js.vue";
import { vFormControls } from "../../../enum";
const TABLE_HEADER_MARGINS = {
  top: 25,
  bottom: 25,
  left: 50,
  right: 50,
};

const SLIDE_IMAGE_DIMENSIONS = {
  width: 300,
  height: 225,
};

let wordData = {
  instanceNames: {}
};

export default {
  name: "ToWordMixinJs",
  mixins: [vFormAndProjectMixin, DateMixin],
  methods: {
    /**
     * Converts the entire project into a editable word document.
     * Currently, toWord() only generates a word document that contains a header, footer and single table.
     * This aforementioned table contains the following columns:
     * Nr. - The current step.
     * Abbildung - All the slides as screenshots, linked to the current step.
     * Handlungsschritt - Contains all the Headline/Text blocks contained in the local panels in the current step.
     *
     * @param organizationId
     * @param projectId
     * @param slides
     * @param config
     * @param metaField
     */
    async toWord(organizationId, projectId, slides, config, exportConfig) {
      const {autoGenerateLegend, generatePartList, metaField} = exportConfig;
      try {
        await this.initializeData({metaField, projectId});
        // Used for displaying the progress bar
        this.$store.dispatch('updateCurrentWordPage', 0)
        this.$store.dispatch('updateTotalWordPages', slides.length);

        this.generateTableHeader();

        for (let i = 0; i < slides.length; i++) {
          wordData.currentSlide = i + 1;
          this.updateWordProgress();
          await this.processSlide(slides[i], projectId, slides, config, autoGenerateLegend);
        }
        let additionalItems = [];
        if(generatePartList) {
          additionalItems = [...additionalItems, ...this.addPartListTable()];
        }
        await this.generateDocument(organizationId, additionalItems);
      } catch (e) {
        console.log("Error: ", e);
      }
    },

    /**
     * Processes the slide.
     * If the slide has just landed on a new step, generate a new row with the cached values saved from the past slides.
     * Once generated, clean the arrays, so that we can cache the values for the next step.
     *
     * Else the slide doesn't land on a new step, just save the current slide image and instructions.
     *
     * @param slide
     * @param captionsForSlides
     * @param projectId
     * @param slides
     * @param config
     * @returns {Promise<void>}
     */
    async processSlide(slide, projectId, slides, config, autoGenerateLegend = false) {
      const {id} = slide;
      const step = this.findStepBySlideId(slide.id, config);
      const img = await this.generateSlideImage(slide, projectId);
      const isLastSlide = wordData.currentSlide === slides.length;

      const finalizeStep = async () => {
        this.renderStepPanels(wordData.previousStep, config);
        if (autoGenerateLegend) {
          await this.generateLegend();
        }
        this.generateNewRow();
        this.clearStepData();
      }

      if (isLastSlide) {
        // If the last slide has a step, then...
        if (step) {
          await finalizeStep();
          wordData.previousStep = step;
          wordData.stepIndex++;
        }
        wordData.stepImages.push(img);
        wordData.iteratedSlides.push(id);
      }

      if (step || isLastSlide) {
        if (wordData.previousStep !== undefined) {
          await finalizeStep();
        }
        wordData.previousStep = step;
        wordData.stepIndex++;
      }

      if (wordData.previousStep !== undefined) {
        wordData.stepImages.push(img);
        wordData.iteratedSlides.push(id);
      }
    },

    /**
     * Utility function made to prevent unexpected values from popping up.
     * Made in case the user decides to click on the download button multiple times. Data does not get cleaned once a
     * file gets downloaded.
     *
     */
    async initializeData(config) {
    const {metaField, projectId} = config;
      wordData = {
        rows: [],
        stepImages: [],
        panelItems: [],
        iteratedSlides: [],

        previousStep: undefined,
        stepIndex: 0,
        currentSlide: 0,
        organizationName: "",
        organizationWebsite: "",
        captionValues: new Set(),
        selectedMetaField: metaField,
      };
      await this.retrievevSTAGEConfig(projectId);
    },

    /**
     * Used for displaying which page is currently being processed in the progress bar.
     */
    updateWordProgress() {
      this.$store.dispatch('updateCurrentWordPage', wordData.currentSlide)
    },

    /**
     * Retrieves the specified step, given a slide id.
     * @param slideId
     * @param config
     * @returns {*}
     */
    findStepBySlideId(slideId, config) {
      return config.steps.find((step) => {
        return step.linkedSlides.find((s) => {
          return s === slideId;
        });
      });
    },

    /**
     * Generates a legend with a link to all the captions from the slide images.
     *
     * @param captionsForSlides
     * @returns {Promise<void>}
     */
    async generateLegend() {
      const {iteratedSlides} = wordData;

      if(iteratedSlides && iteratedSlides.length) {
        const legend = await this.generateLegendForSlides(wordData.vSTAGEConfig, iteratedSlides);
        if(legend.length) {
          wordData.panelItems.push(this.createParagraph("", { alignment: "center" }));
          wordData.panelItems.push(this.createParagraph("Legend: ", { isBold: true }));
        }
        for(let i = 0; i < legend.length; i++) {
          const {name, letter} = legend[i];
          console.log(PositionalTabRelativeTo);
          console.log(PositionalTabLeader);
          console.log(PositionalTabAlignment);
          console.log(PositionalTab)
          console.log(Tab)
          console.log(TabStopType)
          if(letter) {
            const formattedLetter = letter.replace(/(\w+-\w+)/g, (match) => {
              return match.replace('-', '\u2011'); // Replace hyphen with non-breaking hyphen
            });
            wordData.panelItems.push(new Paragraph({
              children: [
                new TextRun({
                  text: formattedLetter,
                  bold: true, // Make the identifier bold
                }),
                new TextRun({
                  text: '\u00A0-\u00A0' + name,
                }),
              ],
              /*children: [
                new TextRun({
                  text: `${formattedLetter}\u00A0-\u00A0${name}`,
                }),
              ],*/
              style: 'TableCellStyle',
            }));
          } else {
            wordData.panelItems.push(new Paragraph({
              children: [
                new TextRun(name),
              ],
              style: 'TableCellStyle',
            }));
          }
          /*wordData.panelItems.push(this.createParagraph({
            text: name,
            bullet: {
              level: 0
            }
          }));*/
        }
      } else {
        console.log('No legend generated for this slide.')
      }
    },
    async generateLegendForSlides(projectConfig, slideIds = []) {
      if(!projectConfig) {
        console.log('cannot generate legend without config')
        return;
      }
      const {slideInfo, relevantParts, aliases} = await this.retrievevSTAGEConfigByConfig(projectConfig);
      const legend = [];
      // linked Caption slides contains
      // the necessary attributes for each slide
      if (relevantParts && Object.keys(relevantParts).length) {
        console.log('slideInfo', slideInfo)
          for (let slideId of Object.keys(relevantParts)) {
            if(slideIds.includes(slideId)) {
              console.log('relevantParts', relevantParts[slideId])
              const relevantInstanceIds = relevantParts[slideId].map(item => {
                return item.instanceId;
              })
              await this.loadMetadataByInstances(relevantInstanceIds);
              const instanceNames = this.$store.getters.getInstanceNames;
              const partEntries = relevantParts[slideId];
              console.log('relevant parts for current slide', partEntries)
              for(let partEntry of partEntries) {
                const {instanceId, assemblyInstanceId} = partEntry;
                console.log('searching for', instanceId)
                const captionText = slideInfo[slideId] ? slideInfo[slideId][`${assemblyInstanceId}:${instanceId}`] : '';
                const alias = aliases[`${assemblyInstanceId}:${instanceId}`] ? aliases[`${assemblyInstanceId}:${instanceId}`] : null;
                const instanceName = instanceNames[instanceId] ? instanceNames[instanceId] : "no name found " + instanceId;
                /*const fullCaptionText = captionText
                    ? `${captionText} - ${alias ? alias : instanceName}`
                    : (alias ? alias : instanceName);*/
                legend.push({
                  letter: captionText,
                  name: alias ? alias : instanceName
                });
              }
            }
          }
      }
      //legend.sort((a, b) => a.localeCompare(b));
      return legend;
    },
    /**
     * Renders all the text/headline blocks in all the local panels in a given step.
     * @param step 
     * @param config 
     */
    renderStepPanels(step, config) {
        step.panels.forEach((panel, index) => {
          const elements = this.getElementsByPanel(panel.uuid, config.global, step, index === 0);
          const filteredElements = elements.filter((item) => !item.bottomDropZone);
          this.addStepElements(filteredElements);
        })
    },

   /**
    * Generates a single slide image from a given slide.
    * @param slide 
    * @param projectId 
    * @param i 
    */
    async generateSlideImage(slide, projectId, i = 0) {
      try {
        const img = await this.loadThumbnailNew(projectId, slide, i, {
          loadingMode: this.projectLoadingMode,
          width: 300,
          height: 225,
          resizeMethod: 'cover',
        });

        return new Paragraph({
          children: [
            new ImageRun({
              type: 'png',
              data: img,
              transformation: SLIDE_IMAGE_DIMENSIONS,
            })
          ],
          spacing: { after: 100 }
        });
      } catch (e) {
        throw Error("No slide image found.");
      }
    },

    /**
     * Generates the Word document and saves it within the client's computer.
     */
    async generateDocument(organizationId, children = []) {
      const header = await this.retrieveHeader(organizationId);
      const footer = await this.retrieveFooter();
      const table = new Table({ rows: wordData.rows, layout: "fixed" });

      const wordDocument = new Document({
        styles: {
          paragraphStyles: [
            {
              id: 'TableCellStyle',
              name: 'Table Cell Style',
              basedOn: 'Normal',
              next: 'Normal',
              run: {
                font: 'Arial',
                // size: 24, // 12pt font size
              },
              paragraph: {
                indent: {
                  //left: 330, // 0.5 inch (36pt)
                  //hanging: 450, // Ensures text indent
                },
                spacing: {
                  line: 276, // 1.15 line spacing
                },
                contextualSpacing: true,
                keepNext: true,
                keepLines: true,
              },
            },
          ],
          default: {
            document: {
              run: {
                font: "Arial",
              },
              paragraph: {
                alignment: AlignmentType.LEFT
              }
            }
          }
        },
        sections: [{
          headers: { default: header },
          footers: { default: footer },
          children: [table, ...children]
        }]
      });

      const ts = this.getFormattedTimestamp();
      Packer.toBlob(wordDocument)
          .then((blob) => {
            // name of the project
            saveAs(blob, `${this.projectName}-${ts}.docx`);
          });
    },
    getFormattedTimestamp() {
      const date = new Date();
      const day = String(date.getDate()).padStart(2, '0'); // Get day and pad with leading zero if needed
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Get month (0-11) and pad with leading zero
      const year = date.getFullYear(); // Get full year

      return `${day}-${month}-${year}`;
    },

    /**
     * Generates the header of the entire table.
     */
    generateTableHeader() {
      const tableHeader = new TableRow({
        children: [
          this.createTableCell([this.createParagraph("Nr. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 550),
          this.createTableCell([this.createParagraph("Abbildung. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 4750), // Set fixed width for the "Abbildung" cell  
          this.createTableCell([this.createParagraph("Handlungsschritt. ", { isBold: true, alignment: "center" })], TABLE_HEADER_MARGINS, 4750),
        ],
      });

      wordData.rows.push(tableHeader);
    },
    
    /**
     * Creates a single new table cell.
     * @param children
     * @param margins
     * @param width
     * @returns {TableCell}
     */
    createTableCell(children, margins, width = null) {
      const cellOptions = {
      children: children,
      margins: margins,
        };
      
      if (width) {
        cellOptions.width = {
          size: width,
          type: WidthType.DXA
        };
      }
      
      return new TableCell(cellOptions);
    },

    /**
     * Generates a new row for the table.
     */
    generateNewRow() {
      const stepLabel = this.createParagraph(wordData.stepIndex.toString(), { alignment: "end" });
      
        // Cell for step nr.
      const stepCell = this.createTableCell([stepLabel], { top: 100, left: 100, bottom: 100, right: 100 });
      const slideImagesCell = this.createTableCell(wordData.stepImages, { top: 100, left: 100, right: 100 }, 3000); // Set fixed width for the "Abbildung" cell
      const instructionCell = this.createTableCell(wordData.panelItems, { top: 100, left: 100, bottom: 100, right: 100 });
      
      const row = new TableRow({
        children: [
              stepCell,
              slideImagesCell,
              instructionCell,
            ]
        });
      wordData.rows.push(row);
    },
    /**
     * Generates the header of the document.
     * Header contains the name and website of the organization that has created the project.
     * @returns {Promise<Header>}
     */
    async retrieveHeader(organizationId) {
      await this.$store.dispatch('loadOrganization', { id: organizationId }).then(organizationData => {
        wordData.organizationName = organizationData.displayName;
        wordData.organizationWebsite = organizationData.url;
      });
      let organizationLabel = '';
      if(wordData.organizationWebsite) {
        organizationLabel = `${wordData.organizationName} - ${wordData.organizationWebsite}`;
      } else {
        organizationLabel = wordData.organizationName;
      }
      return new Header({
        children: [ this.createParagraph( organizationLabel, {isBold: true, alignment: "center"}) ]
      });
    },

    /**
     * Generates the footer of the document.
     * Footer contains a page number that adjusts its value according to which page it is in.
     * @returns {Promise<Footer>}
     */
    async retrieveFooter() {
      return new Footer({
        children: [this.createParagraph(PageNumber.CURRENT, {alignment: "end"})],
      });
    },

    /**
     * Looks through all the blocks in a panel.
     * Retrieves ONLY the Headline and Text blocks and takes its value.
     *
     * @param elements
     * @returns {Promise<void>}
     */
    async addStepElements(elements) {
      for (const element of elements) {
        const text = this.getLabel(element, this.language);

        const {formElementTypeString} = element;

        if (formElementTypeString === vFormControls.HEADLINE) {
          wordData.panelItems.push(this.createParagraph(text, { isBold: true }));
        }

        if (formElementTypeString === vFormControls.TEXT) {
          wordData.panelItems.push(this.createParagraph(text));
        }
      }
    },

    /**
     * Utility function for creating a paragraph. Saves time.
     * @param text
     * @param isBold
     * @param alignment
     * @returns {Paragraph}
     */
    createParagraph(text, { isBold = false, alignment = "start" } = {}) {
      const lines = text.split('\n');
      console.log('lines', lines)
      if(lines.length === 1) {
        return new Paragraph( {
          children: [new TextRun({children: [text], bold: isBold}) ],
          alignment: alignment,
          spacing: { after: 10 }
        });
      }

      const textRuns = lines.map((line, index) => {
        const textRun = new TextRun({ text: line, bold: isBold, break: index < lines.length && index !== 0 ? 1 : 0 });
        console.log(index);
        return textRun;
      });

      return new Paragraph({
        children: textRuns,
        alignment: alignment,
        spacing: { after: 10 }
      });
    },

    /**
     * Retrieves a specific object, given a nested object and the target property name.
     *
     * @param obj
     * @param target
     * @returns {unknown}
     */
    dig(obj, target) {
      for (let key in obj) {
        if (key === target) {
          return obj[key];
        }
        if (typeof obj[key] === 'object') {
          let result = this.dig(obj[key], target);
          if (result) {
            return result;
          }
        }
      }
      return undefined;

    },

    /**
     * Prepares the variables for generating the row for the next step.
     */
    clearStepData() {
      wordData.stepImages = [];
      wordData.panelItems = [];
      wordData.iteratedSlides = [];
      wordData.captionValues = new Set();
    },

    /**
     * Retrieves all the captions, based on the assemblyconfig nodes in the config.json file.
     *
     * example:
     * {
     *   some-uuid: {
     *     label: 'A'
     *   }
     * }
     *
     * @returns {Promise<{}>}
     */
    async retrievevSTAGEConfigByConfig(config) {
      /**
       * single slide:
       * {
       *     "attributes": {
       *         "relevance": {
       *             "partList": false
       *         },
       *     }
       *     AssemblyConfig: {
       *       "nodes: {
       *         "some-node-id": {
       *         "caption": {
       *             "text": "",
       *             "font-size": 44.9689255
       *         }
       *       }
       *       }
       *     }
       * }
       * */
      const { slides } = config;
      const globalAssemblyConfig = config.assemblyConfig;
      let globals = {};
      let aliases = {};
      // this retrieves all globally defined instances with partRelevant
      if(globalAssemblyConfig) {
        Object.keys(globalAssemblyConfig).map(assemblyId => {
          // todo: assembly can also have importantData retrieval, but assemblyId then must be replaced by rootNode instanceId
          const instances = globalAssemblyConfig[assemblyId].nodes;
          if(instances && Object.keys(instances).length) {
            Object.keys(instances).map(instanceId => {
              const {caption, alias} = instances[instanceId];
              if(caption && caption.text) {
                globals[`${assemblyId}:${instanceId}`] = caption.text ? caption.text : " ";
              }
              if(alias) {
                console.log('found alias!', alias)
                aliases[`${assemblyId}:${instanceId}`] = alias;
              }
            })
          }
        });
      }
      let slideInfo = {};
      let relevantParts = {};
      // this filters all instances per slide and merges the default settings into the object
      for(let i = 0; i < slides.length; i++) {
        const {AssemblyConfig, id, attributes} = slides[i];
        if(attributes && attributes.relevance && attributes.relevance.partList) {
          relevantParts[id] = attributes.relevance.partList.map(item => {
            const split = item.split(':');
            return {
              assemblyInstanceId: split[0],
              instanceId: split[1]
            }
          });
        }
        if(AssemblyConfig) {
          slideInfo[id] = {};
          Object.keys(AssemblyConfig).map(assemblyId => {
            // todo: assembly can also have importantData retrieval, but assemblyId then must be replaced by rootNode instanceId
            const instances = AssemblyConfig[assemblyId].nodes;
            if(instances && Object.keys(instances).length) {
              Object.keys(instances).map(instanceId => {
                const {caption} = instances[instanceId];
                if(caption && caption.text) {
                  slideInfo[id][`${assemblyId}:${instanceId}`] = caption.text ? caption.text : globals[`${assemblyId}:${instanceId}`];
                }
              })
              Object.keys(globals).map(instanceId => {
                if(!slideInfo[id][instanceId]) {
                  slideInfo[id][instanceId] = globals[instanceId];
                }
              })
            }
          });
        }
        if(slideInfo[id] && !Object.keys(slideInfo[id]).length) {
          delete slideInfo[id];
        }
      }

      return {slideInfo, relevantParts, aliases};
    },
    async retrievevSTAGEConfig(projectId) {
      const loadingMode = await this.getProjectLoadingMode(projectId);
      wordData.vSTAGEConfig = await this.loadProjectConfigFileNew(projectId, {loadingMode});
    },

    /**
     * Utility function that determines if an object is empty.
     * @param object
     * @returns {boolean}
     */
    isObjectEmpty(object) {
      return Object.keys(object).length !== 0;
    },
    getNonLoadedInstances: function(instanceIds) {
      return instanceIds.filter(id => !(id in this.$store.getters.getInstanceNames));
    },
    /**
     * Given multiple instances. Filter out the caption and metadata.
     * @param allInstances
     * @returns {Promise<*>}
     */
    async loadMetadataByInstances(allInstances) {
      const instanceNames = JSON.parse(JSON.stringify(this.$store.getters.getInstanceNames));
      console.log('instanceNames', instanceNames)
      const loadedInstanceIds = Object.keys(instanceNames);
      console.log('loadedIds', loadedInstanceIds)
      if(loadedInstanceIds && loadedInstanceIds.length) {
        allInstances = allInstances && allInstances.length ? allInstances.filter(id => {
          return !loadedInstanceIds.includes(id)
        }) : [];
      }
      console.log('loading instances', allInstances)
      if(!allInstances || !allInstances.length) {
        return new Promise((resolve) => {resolve();})
      }
      let filterString = "id in ";
      if(allInstances.length === 1) {
        filterString = "id eq " + allInstances[0];
      } else {
        for(let instance of allInstances) {
          filterString += `'${instance}' `;
        }
      }

      const instances = await this.$store.dispatch("clientGetCrossProjectInstances", { include: ['squashedMeta'], filter: filterString });
      for(let i = 0; i < instances.length; i++) {
        const {id, squashedMetaFields, displayName} = instances[i];
        const {selectedMetaField} = wordData;
        if(selectedMetaField) {
          console.log('selected metafield:', selectedMetaField)
          const targetMetaField = this.filterMetaField(squashedMetaFields, selectedMetaField);
          console.log('targetMetaField', targetMetaField)
          await this.$store.dispatch('updateInstanceName', {
            id,
            name: targetMetaField ? targetMetaField.squashedMetaValues.finalMetaValue : displayName
          });
        } else {
          await this.$store.dispatch('updateInstanceName', {
            id,
            name: displayName
          });
        }
      }
      return this.$store.getters.getInstanceNames;
    },
    /**
     * Checks to see if a specific metafield exists.
     *
     * @param squashedMetaFields
     * @returns {*}
     */
    filterMetaField(squashedMetaFields, selectedMetaField) {
      console.log('squasehdMetaFields', squashedMetaFields)
      return squashedMetaFields.find(field => {
        return field.squashedMetaValues.metaFieldId === selectedMetaField && !!field.squashedMetaValues.finalMetaValue
      });
    },
    addPartListTable() {
      const names = this.$store.getters.getInstanceNames ? Object.values(this.$store.getters.getInstanceNames) : [];
      if(!names.length) {
        return [];
      }
      const uniqueNames = [...new Set(names)];
      // todo: sort unique names alphabetically
      const sortedNames = uniqueNames.sort((a, b) => a.localeCompare(b));
      // todo: add some space at the top of the table...

      // Add a paragraph with spacing before the part list table
      const spaceBeforePartList = new Paragraph({
        children: [new TextRun({ text: "", break: 1 })],
        spacing: { before: 400 } // Adjust the spacing value as needed
      });

      // Add the part list table
      const partListTable = new Table({
        rows: sortedNames.map((name) => {
          return new TableRow({
            children: [
              this.createTableCell([this.createParagraph(name, { alignment: "start" })], TABLE_HEADER_MARGINS, 9500),
            ],
          });
        }),
        layout: "fixed"
      });
      return [spaceBeforePartList, partListTable];
    }
  }
}
</script>
