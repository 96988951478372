<script>
import vFormBUTTONMixin from "./vFormBUTTONMixin.js";

export default {
  name: "vFormLOGOUT",
  mixins: [vFormBUTTONMixin],
  beforeMount() {
    this.element.formElementType = this.vFormControls["LOGOUT"];
  },
}
</script>