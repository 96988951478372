<template>
  <!--Single choice-->
  <div
      :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: config.fontColor ? config.fontColor : '',
          }"
      :class="['mb-3 position-relative w-100 vform-radiobuttons', config.emphasized ? 'vform-emphasized' : '', config.backgroundColor ? 'with-bg' : 'without-bg']">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <div :class="['mb-2', getImage(config, lang) ? 'vform-item-with-icon-container' : '']">
      <div v-if="!config.noQuestion" :class="['text', !editable && (!isAnswerValid || isAnswerRequired) ? 'vform-error pl-1' : '']">{{
          getLabel(config, lang) ? getLabel(config, lang) : placeholders.LONG
        }}{{ getRequired(config) ? "*" : "" }}
      </div>
    </div>
    <div
        v-for="(option, index) in getOptions(config)"
        :key="index + forceReRenderKey + Math.random().toString(36).substring(7)"
        :class="['clickable slide-control-element pt-1 pb-1', getOptionImage(option, lang) ? 'vform-item-with-icon-container' : '']"
    >
      <div class="text display-checkboxes-wrapper pl-2 position-relative">
        <div class="user-id-log-entry">{{ option.userId ? option.userId : $store.getters.getvFormUserId }}</div>
        <checkbox-widget :initial-value="option.isSelected"
                         :class="'vform-checkbox-label-text'"
                         :color="!config.emphasized && config.fontColor ? config.fontColor : fontColor ? fontColor : ''"
                         :label-for="'chkRadio' + index"
                         :label-text="getText(option, lang) ? getText(option, lang) : placeholders.SHORT"
                         type="radio"
                         :disabled="disabled"
                         @change="(val) => {option.isSelected = val;}"
                         @clicked="(val) => {storeData(val, index)}"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import CheckboxWidget from "@/components/widgets/editors/Checkbox";
import {mapState} from 'vuex';
export default {
  name: "SingleChoice",
  components: {
    EditorOverlay,
    CheckboxWidget
  },
  mixins: [ElementMixin],
  data() {
    return {
      selected: {},
      isUserChanged: false,
      isAnswerValid: true,
      isAnswerRequired: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        if(!this.isUserChanged && !this.$store.getters.isElementVisited(this.config.uuid)) {
          console.log('watch!' + this.isUserChanged)
          //this.setOptionPreSelected(this.config);
          this.forceReRenderKey++;
        }
      }
    }
  },
  computed: mapState({
    formData: state => state.vformpage.stepFormData,
  }),
  beforeMount() {
    let options = this.getOptions(this.config);
    // this is a hack because isSelected got saved into the config
    for(let i = 0; i < options.length; i++) {
      this.$set(options[i], "isSelected", false);
    }
  },
  mounted() {
    this.increaseCounter();
    const {uuid} = this.config;
    if(!this.$store.getters.isElementVisited(uuid)) {
      this.setOptionPreSelected(this.config)
    }
    this.$store.dispatch('setElementVisited', uuid)

  },
  methods: {
    selectUnselectItem(index) {
      this.isUserChanged = true;
      if (!this.disabled) {
        let options = this.getOptions(this.config);
        for(let i = 0; i < options.length; i++) {
          options[i].isSelected = i === index;
        }
        this.setOptions(this.config, options);
        this.forceReRenderKey++;
        const option = options[index];
        this.checkOption(option);
      }
    },
    storeData(value, optionIndex) {
      this.isUserChanged = true;
      for(let i = 0; i < this.config.options.length; i++) {
        this.$set(this.config.options[i], "isSelected", false);
      }
      this.$set(this.config.options[optionIndex], "userId", this.$store.getters.getvFormUserId);
      this.$set(this.config.options[optionIndex], "isSelected", value);
      this.$emit('storeData', this.config.uuid, this.retrieveData());
    },
    validate() {
      let isValid = this.validateOptions();
      this.isAnswerValid = isValid;
      return isValid;
    },
    checkRequired() {
        let result = this.getAnyOptionSelected(this.config);
        this.isAnswerRequired = !result;
        return result;
    },
    fillData() {
      if(this.formData[this.config.uuid]) {
        let data = this.formData[this.config.uuid]
        if(data) {
          try {
            data = JSON.parse(JSON.stringify(data));
            this.setOptionSelectedByData(this.config, data);
          } catch {
            console.log('could not parse data');
          }
        }
      }
    },
    retrieveData() {
      return JSON.parse(JSON.stringify(this.getOptions(this.config)));
    },
    getData() {
      if (!this.disabled) {
        return {
          data: {
            value: this.getOptionData(this.config, this.lang),
            question: this.getLabel(this.config, this.lang),
            elementId: this.config.uuid
          },
          type: 'radiobuttons'
        };
      }
      return null;
    }
  }
}
</script>
<style lang="scss" scoped>
.vform-radiobuttons.with-bg {
  padding: 11px 12px;
}
.display-checkboxes-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
</style>

<style lang="scss">
.display-checkboxes-wrapper {
  .radio{
    background-color: transparent;
  }
}
.vform-checkbox-label-text {
  font-size: 1em !important;
}
</style>