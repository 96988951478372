var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"course-navigation"},[(_vm.showPlaceholder)?_c('div',[_vm._v(" Course Navigation Placeholder ")]):_vm._e(),_vm._l((_vm.courseCats),function(courseCat,index){return (_vm.showCat(courseCat))?_c('div',{key:courseCat.id},[(_vm.showCatNameInternal)?_c('div',{class:['category-name p-1 mb-2 border-radius', index !== 0 ? 'mt-2' : '',
         _vm.itemIsClickable(courseCat) ? 'is-clickable' : '',
        _vm.getProgressInPercent(courseCat.id) > 0 ? 'startedCourseItem' : 'notdone2',
         _vm.getProgressInPercent(courseCat.id) === 100 ? 'doneCourseItem' : 'notdone' ],on:{"click":function($event){return _vm.$emit('catGotClicked', courseCat.id)}}},[_vm._v(_vm._s(courseCat.name)+" "+_vm._s(_vm.getProgressInPercent(courseCat.id))+"% "),(_vm.itemIsClickable(courseCat))?_c('icon',{staticClass:"float-right",attrs:{"type":"angle-right"}}):_vm._e()],1):_vm._e(),_vm._l((_vm.getCourseTreeForCat(courseCat.id)),function(node,index){return (!_vm.showCatsOnly)?_c('course-item-node',{key:node.id + _vm.forceReRenderKey,attrs:{"active-nodes":_vm.baseCourseItem ? [_vm.baseCourseItem.id] : [],"child-index":index,"editable":false,"move-course-item-up":_vm.moveCourseItemUp,"node":node,"progress-data":_vm.$store.getters.getvFormUserSessionData ? _vm.$store.getters.getvFormUserSessionData : {},"remove-course-item":_vm.removeCourseItem},on:{"gotClicked":function (item) {_vm.$emit('gotClicked', {
          projectId: item.id,
          courseOriginProjectId: _vm.courseOriginProjectId,
          course: _vm.formId})
        }}}):_vm._e()})],2):_vm._e()}),_vm._l((_vm.getCourseTreeForCat(null)),function(node,index){return (!_vm.showCatsOnly)?_c('course-item-node',{key:node.id + _vm.forceReRenderKey,attrs:{"active-nodes":_vm.baseCourseItem ? [_vm.baseCourseItem.id] : [],"child-index":index,"editable":false,"move-course-item-up":_vm.moveCourseItemUp,"node":node,"progress-data":_vm.$store.getters.getvFormUserSessionData ? _vm.$store.getters.getvFormUserSessionData : {},"remove-course-item":_vm.removeCourseItem,"show-progress":false},on:{"gotClicked":function (item) {_vm.$emit('gotClicked', {
      projectId: item.id,
      courseOriginProjectId: _vm.courseOriginProjectId,
      course: _vm.formId
    })
    }}}):_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }