<template>
<div>
  <div
      @click="isActive ? () => {} : $emit('gotClicked', node)"
      :class="[
            'vform-slide-control-element',
            'border-radius',
            'multi-line',
            showProgress && progressInPercent > 0 ? 'startedCourseItem' : 'notdone2',
            showProgress && progressInPercent === 100 ? 'doneCourseItem' : 'notdone',
            isActive ? 'activeCourseItem' : 'inactiveCourseItem',
        ]"
  >
    <div
        :class="[
                'inner align-items-left text-left',
                !disabled ? 'clickable' : 'no-button-hover-effect',
                'simple-button',
            ]"
    ><div>{{ node.name }}</div>
      <div class="d-flex align-items-start">
        <div v-if="progressData[node.id] && showProgress" class="progress-bar-course">
          <div :style="{width: progressInPercent + '%'}" class="progress">{{ progressInPercent }}%</div>
        </div>
        <div v-if="progressData[node.id] && showProgress" class="steps-progress text-right">({{ progressData[node.id] && progressData[node.id].completedSteps ? progressData[node.id].completedSteps.length : 0 }}/{{ progressData[node.id].numberOfSteps }} steps)</div>
      </div>
    </div>
  </div>
  <span v-if="editable && childIndex !== 0" @click="moveCourseItemUp(node.id)" class="clickable ml-1"><icon type="angle-up" /></span>
  <span v-if="editable" class="lighter clickable ml-4" @click="removeCourseItem(node.id)"><icon type="trash-alt" /></span>
  <course-item-node
      :show-move-up="index !== 0"
      v-if="node.children"
      :key="subnode.id"
      v-for="(subnode, index) in node.children"
      :node="subnode"
      :move-course-item-up="moveCourseItemUp"
      :remove-course-item="removeCourseItem"
      :child-index="index"
      :editable="editable"
      :active-nodes="activeNodes"
      @gotClicked="(item) => {$emit('gotClicked', item)}"
  />
</div>
</template>

<script>
import CourseItemNode from "../course/CourseItemNode";
import Icon from "@/components/Icon.vue";
export default {
  name: "CourseItemNode",
  components: {
    CourseItemNode,
    Icon
  },
  props: {
    node: {type: Object, required: true},
    removeCourseItem: {type: Function, required: true},
    moveCourseItemUp: {type: Function, required: true},
    showMoveUp: {type: Boolean, default: false},
    childIndex: {type: Number, default: 0},
    editable: {type: Boolean, default: true},
    activeNodes: {type: Array, default: () => {return []}},
    disabled: {type: Boolean, default: false},
    progressData: {type: Object, default: () => {return {}}},
    showProgress: {type: Boolean, default: true}
  },
  computed: {
    progressInPercent() {
      if(this.progressData[this.node.id] && this.progressData[this.node.id].completedSteps) {
        const current = this.progressData[this.node.id].completedSteps.length;
        const total = this.progressData[this.node.id].numberOfSteps;
        if(total) {
          // 5 von 10 steps
          // total = 7 = 100%, aktuell = 1
          return parseInt((100 / total * current).toFixed(0));
        }
      }
      return 0;
    },
    isActive() {
      return this.activeNodes.includes(this.node.id);
    }
  },
}
</script>

<style lang="scss" scoped>
.startedCourseItem {
  background-color: #e36d2e;
}
.doneCourseItem {
  background-color: #479f47;
}
.activeCourseItem {
  background-color: #adadad;
  cursor: auto;
}
.newStyle .activeCourseItem .inner:hover {
  background-color: #adadad;
  color: #000;
  cursor: auto;
}
.progress-bar-course {
  border-radius: 3px;
  width: 75%;
  background-color: #ddd;
  flex-grow: 0;
  .progress {
    border-radius: 3px;
    background-image: repeating-linear-gradient(-45deg, #34383e 0, #626974 1px, #21252b 0, #21252b 4px);
    color: #fff;
  }
}
.steps-progress {
  font-size: 0.8rem;
  margin-top: -2.5px;
  white-space: nowrap;
  padding-left: 5px;
}
</style>