<template>
  <tr class="meta-field">
    <td class="meta-field-label">
      {{ label }}
    </td>
    <td v-if="!editing && editable"
        class="table-edit"
        @click.prevent="startEditing">
      <icon v-if="!editing" :type="'edit'"/>
    </td>
    <td v-if="editing"
        class="table-edit"
        @click.prevent="cancelEditing">
      <icon v-if="editing" type="times"/>
    </td>
    <td v-if="!editing" :key="field.id" class="meta-field-value">
      <div
          v-if="!this.$store.getters.isSuperAdmin && ['2fe0dcb3-b82f-46d6-b940-d7caeafb007a', '768c4690-8646-4e51-a9fe-2bcf606803e9'].includes(field.id)">
        <!--Placeholder for quickfix-->
      </div>
      <div v-if="showMetaValue">
        <json-adder
            v-if="isJson && (getConfigField('widget') === 'JsonAdder')"
            :config="field.config"
            :editing="false"
            :value="rawValue"
        />
        <mat-color-adder
            v-else-if="isJson && (getConfigField('widget') === 'MatColorAdder')"
            :config="field.config"
            :editing="false"
            :organization-ids="organizationIds"
            :value="rawValue"
        />
        <!--<u-i-group
                v-else-if="isJson && isUiOverlay"
                :organization-id="$store.getters.getProjectOrganization($route.params.id)"
                :id="$route.params.id"
                @save="updateValue"
                :value="renderValue"
        />-->
        <pre v-else-if="isJson">{{ renderValue }}</pre>
        <content-display
            v-else-if="isAsset"
            :list-name="field.id"
            :media-category="field.config && field.config.mediaCategory ? field.config.mediaCategory : null"
            :show-preview="field.type === 'asset'"
            :teams="teams"
            :type="getConfigField('type') ? getConfigField('type') : field.type"
            :value="renderValue"
            class="col-12 col-md-6"
        />
        <content-display
            v-else-if="isProject"
            :list-name="field.id"
            :teams="teams"
            :type="'project'"
            :value="renderValue"
        />
        <vector-display
            v-else-if="isVector"
            :type="field.type"
            :value="renderValue"
        />
        <lifetime-setter
            v-else-if="field.config && field.config.unit === 'seconds' && isInteger"
            :editing="false"
            :hide-label="true"
            :value="rawValue"
        />

        <div v-else-if="isCheckbox">
          {{ renderValue }}
        </div>
        <div v-else-if="isDate">
          {{ dateFromISO(renderValue) }}
        </div>
        <div v-else-if="isDateTime">
          {{ dateTimeFromISO(renderValue) }}
        </div>
        <div v-else-if="isTime">
          {{ timeFromISO(renderValue) }}
        </div>
        <div v-else-if="isPassword">
          <span v-for="x in renderValue.length" :key="x">*</span>
        </div>
        <div v-else-if="isColor" style="display: flex; align-items: center;">
          <div :style="{backgroundColor: renderValue}" class="color-square"/>
          <div style="padding-left: 15px">{{ renderValue }}</div>
        </div>
        <div v-else>
          {{ renderValue }}
        </div>
      </div>
      <div v-else-if="storeName === 'Instance'">
        {{ $t("instanceLocalValueUsesGlobalValueFromAsset") }}
      </div>
      <div v-else>
        {{ $t("assetGlobalValueUsesDefaultValue") }}
      </div>
    </td>
    <td v-else class="meta-field-value editing">
      <div class="button darker p-1 pl-2 pr-2 border-radius d-inline-block mr-2 mb-1" @click="updateValue(null)">
        {{ $t('setValueToEmpty') }}
      </div>
      <div class="button darker p-1 pl-2 pr-2 border-radius d-inline-block mb-1" @click="deleteMetaValue(null)">
        {{ $t('setBackToDefault') }}
      </div>
      <div class="w-100 mb-2"/>
      <div
          v-if="!this.$store.getters.isSuperAdmin && ['2fe0dcb3-b82f-46d6-b940-d7caeafb007a', '768c4690-8646-4e51-a9fe-2bcf606803e9'].includes(field.id)">
        <!--Placeholder for quickfix-->
      </div>
      <mat-color-adder
          v-else-if="isJson && (getConfigField('widget') === 'MatColorAdder')"
          :config="field.config"
          :organization-ids="organizationIds"
          :value="rawValue"
          @save="updateValue"
      />
      <json-adder
          v-else-if="isJson && (getConfigField('widget') === 'JsonAdder')"
          :config="field.config"
          :value="rawValue"
          :editing="true"
          @save="updateValue"
      />

      <text-widget
          v-else-if="isText"
          :value="renderValue"
          @save="updateValue"
      />
      <color-picker
          v-else-if="isColor"
          :value="renderValue"
          @save="updateValue"
      />
      <range-widget
          v-else-if="isRange"
          :type="field.type"
          :value="rawValue"
          @save="updateValue"
      />
      <uuid-widget v-else-if="isUuid"
                   :value="renderValue"
                   @save="updateValue"
      />
      <list-widget
          v-else-if="isList"
          :value="rawValue"
          @save="updateValue"
      />
      <i-p-widget
          v-else-if="isIP"
          :value="renderValue"
          @save="updateValue"
      />
      <uri-widget v-else-if="isUri"
                  :value="renderValue"
                  @save="updateValue"
      />
      <email-widget
          v-else-if="isEmail"
          :value="renderValue"
          @save="updateValue"
      />
      <boolean-widget
          v-else-if="isCheckbox"
          :label="field.key"
          :value="rawValue"
          @save="updateValue"
      />
      <vector-widget
          v-else-if="isVector"
          :type="field.type"
          :value="rawValue"
          :editing="true"
          @save="updateValue"
      />
      <date-picker-widget
          v-else-if="isDateOrTime"
          :type="field.type"
          :value="renderValue"
          @save="updateValue"
      />
      <content-list
          v-else-if="isAsset"
          :list-name="field.id"
          :media-category="field.config && field.config.mediaCategory ? field.config.mediaCategory : null"
          :optional-id="field.name"
          :organization-ids="organizationIds"
          :show-preview="field.type === 'asset'"
          :teams="teams ? teams.map(item => {return item.id}) : []"
          :type="getConfigField('type') ? getConfigField('type') : field.type"
          :value="renderValue"
          class="col-12"
          @save="updateValue"
      />
      <content-list
          v-else-if="isProject"
          :list-name="field.id"
          :organization-ids="organizationIds"
          :remote-sub-type="getConfigField('subType') ? getConfigField('subType') : null"
          :type="getConfigField('type') ? getConfigField('type') : 'project'"
          :value="renderValue"
          @save="updateValue"
      />
      <lifetime-setter
          v-else-if="field.config && field.config.unit === 'seconds' && isInteger"
          :editing="true"
          :hide-label="true"
          :value="rawValue"
          @setValue="updateValue"
      />
      <number
          v-else-if="isFloat"
          :type="field.type"
          :value="renderValue"
          @save="updateValue"
      />
      <integer-widget
          v-else-if="isInteger"
          :type="field.type"
          :value="renderValue"
          @save="updateValue"
      />
      <codemirror
          v-else-if="isJson"
          :value="renderValue"
          @abort="cancelEditing"
          @save="updateValue"
      />

      <enum v-else-if="isEnum"
            :possible-values="renderConfig"
            :value="renderValue"
            @save="updateValue"
      />
      <password-widget
          v-else-if="isPassword"
          :possible-values="field.config"
          :value="renderValue"
          @save="updateValue"
      />

    </td>
    <td class="default-value"><span class="lighter float-right">{{ field.defaultValue }}</span></td>
  </tr>
</template>

<script>
import Icon from "../../Icon";
import {MetaFieldTypes} from '../../../enum';
import ContentList from "../editors/ContentList";
import ContentDisplay from "../editors/ContentDisplay";
import Enum from "../editors/Enum";
import Number from "../editors/FloatWidget";
import TextWidget from "../editors/TextWidget";
import BooleanWidget from "../editors/BooleanEditor";
import VectorWidget from "../editors/VectorWidget";
import DateMixin from '@/components/mixins/DateMixin.js';
import PasswordWidget from "../editors/PasswordWidget";
import VectorDisplay from "../editors/VectorDisplay";
import EmailWidget from "../editors/EmailWidget";
import IPWidget from "../editors/IPWidget";
import UriWidget from "../editors/UriWidget";
import RangeWidget from "../editors/RangeWidget";
import ListWidget from "../editors/ListWidget";
import IntegerWidget from "../editors/IntegerWidget";
import UuidWidget from "../editors/UuidWidget";
//import UIGroup from "../editors/UIGroup";
import DatePickerWidget from "@/components/widgets/editors/DatePickerWidget";
import ColorPicker from "@/components/widgets/editors/ColorPicker";
import Codemirror from "@/components/widgets/editors/Codemirror";
import JsonAdder from "@/components/widgets/editors/JsonAdder";
import MatColorAdder from "@/components/widgets/editors/MatColorAdder";
import LifetimeSetter from "@/components/LifetimeSetter";

/**
 * Returns the raw value or '(empty)' if value
 * is undefined or null.
 *
 * @param {string} v - Value
 * @param dft - Default value
 * @returns {string}
 */
const emptyOrValue = (v, dft) => {
  const d = dft || '(empty)';
  return v ? v : d;
};

/**
 *
 */
export default {
  name: 'MetaField',
  components: {
    DatePickerWidget,
    ColorPicker,
    Icon,
    Codemirror,
    ContentList,
    ContentDisplay,
    Enum,
    Number,
    TextWidget,
    BooleanWidget,
    VectorWidget,
    PasswordWidget,
    VectorDisplay,
    EmailWidget,
    IPWidget,
    UriWidget,
    RangeWidget,
    ListWidget,
    UuidWidget,
    IntegerWidget,
    //UIGroup,
    JsonAdder,
    MatColorAdder,
    LifetimeSetter
  },
  mixins: [DateMixin],
  props: {
    label: {type: String, required: true},
    value: {type: String, required: false, default: null},
    field: {type: Object, required: true},
    /**
     * The id of the target (e.g. of the Asset)
     * */
    targetId: {type: String, required: true},
    /**
     * The storeName to update the item (e.g. Project)
     * */
    storeName: {type: String, default: ''},
    /**
     * If required by the store (e.g. setting the metaValue of an Instance which needs id = projectId and cid = instanceId)
     * */
    cid: {type: String, required: false, default: null},
    organizationIds: {type: Array, default: () => {return []}},
    teams: {type: Array, default: null},
    doEmitOnly: {type: Boolean, default: false},
    editable: {type: Boolean, default: true}
  },
  data() {
    return {
      editing: false,
      updatedValue: [],
    }
  },
  computed: {
    showMetaValue(){
      return  (!this.$route.path.includes('library/3d-data') || (this.$route.path.includes('library/3d-data') && (this.storeName === 'Instance' && this.field.metaValues[0].MetaFieldId) || (this.storeName != 'Instance' && this.field.metaValues.length)));
    },
    isText() {
      return this.field.type === MetaFieldTypes.String || this.field.type === MetaFieldTypes.Text;
    },
    isIP() {
      return this.field.type === MetaFieldTypes.Ip || this.field.type === MetaFieldTypes.Ipv6;
    },
    isUri() {
      return this.field.type === MetaFieldTypes.Uri
    },
    isEmail() {
      return this.field.type === MetaFieldTypes.Email
    },
    isUuid() {
      return this.field.type === MetaFieldTypes.Uuid;
    },
    isDateOrTime() {
      return this.field.type === MetaFieldTypes.Date || this.field.type === MetaFieldTypes.Time || this.field.type === MetaFieldTypes.DateTime;
    },
    isDate() {
      return this.field.type === MetaFieldTypes.Date;
    },
    isTime() {
      return this.field.type === MetaFieldTypes.Time;
    },
    isDateTime() {
      return this.field.type === MetaFieldTypes.DateTime;
    },
    isUiOverlay() {
      return this.field.key === 'uiOverlay';
    },
    isJson() {
      return this.field.type === MetaFieldTypes.Json;
    },
    isAsset() {
      return this.field.type === MetaFieldTypes.Asset || this.field.type === MetaFieldTypes.DataSet;
    },
    isProject() {
      return this.field.type === MetaFieldTypes.Project;
    },
    isVector() {
      return this.field.type === MetaFieldTypes.Vector2 || this.field.type === MetaFieldTypes.Vector3 || this.field.type === MetaFieldTypes.Vector4 || this.field.type === MetaFieldTypes.xForm2 || this.field.type === MetaFieldTypes.xForm3;
    },
    isRange() {
      return this.field.type === MetaFieldTypes.Range;
    },
    isCheckbox() {
      return (this.field.type === MetaFieldTypes.Boolean);
    },
    isList() {
      return (this.field.type === MetaFieldTypes.List);
    },
    isColor() {
      return this.field.type === MetaFieldTypes.Color;
    },
    isEnum() {
      return this.field.type === MetaFieldTypes.Enum;
    },
    isPassword() {
      return this.field.type === MetaFieldTypes.Password;
    },
    isFloat() {
      return this.field.type === MetaFieldTypes.Float;
    },
    isInteger() {
      return this.field.type === MetaFieldTypes.Integer;
    },
    typeI18nKey() {
      let key = 'editors.type.' + this.field.type;
      if (this.field.type === MetaFieldTypes.Asset) {
        if (this.field.config) {
          key = key + '.' + this.getConfigField('type');
        }
      }
      return key;
    },
    renderConfig() {
      if (typeof this.field.config === 'string') {
        return JSON.parse(this.field.config);
      } else {
        return this.field.config;
      }
    },
    /**
     * Render the current value according the type
     * @returns {string}
     */
    renderValue() {
      const v = this.getValue();
      const d = this.field.defaultValue;
      switch (this.field.type) {
        case MetaFieldTypes.Integer:
        case MetaFieldTypes.String : {
          return emptyOrValue(v, d);
        }
        case MetaFieldTypes.Vector2 : {
          return v;
        }
        case MetaFieldTypes.Vector3 : {
          return v;
        }
        case MetaFieldTypes.Vector4 : {
          return v;
        }
        case MetaFieldTypes.xForm2 : {
          return v;
        }
        case MetaFieldTypes.xForm3 : {
          return v;
        }
        case MetaFieldTypes.Boolean: {
          if (v === undefined) {
            if (d === undefined) {
              return false;
            }
          }
          return v;
        }
        case MetaFieldTypes.Json: {
          return emptyOrValue(v, d);
        }
        default:
          return emptyOrValue(v, d);
      }
    },
    rawValue() {
      return this.getValue();
    },
  },
  beforeMount() {
    //console.log(this.$route.path);
    if (!this.value) {
      this.updatedValue = '';
    }
  },
  methods: {
    startEditing() {
      this.editing = true;
    },
    cancelEditing() {
      this.editing = false;
    },
    getConfigField(fieldName) {
      if (this.field.config) {
        let config = this.field.config;
        try {
          let tmp = JSON.parse(this.field.config);
          config = tmp;
        } catch {
          // do nothing
        }
        return config[fieldName] ? config[fieldName] : null;
      }
    },
    updateValue(value) {
      let args = {
        id: this.targetId,
        values: [{metaFieldId: this.field.id, value: value}]
      };
      if (this.cid) {
        args.cid = this.cid;
      }

      const updated = {
        metaFieldId: this.field.id,
        value: value,
        cid: this.cid,
      };

      if (this.doEmitOnly) {
        this.$emit('updated', updated);
      } else {
        this.$store.dispatch('set' + this.storeName + 'MetaValues', args).then(() => {
          this.$emit('updated', updated)
        });
      }
      this.setValue(value);
      this.editing = false;
    },
    deleteMetaValue(value) {
      let args = {
        id: this.targetId,
        values: [this.field.id]
      };

      if (this.cid) {
        args.cid = this.cid;
      }

      const updated = {
        metaFieldId: this.field.id,
        value: value,
        cid: this.cid,
      };

      if (this.doEmitOnly) {
        this.$emit('updated', updated);
      } else {
        this.$store.dispatch(`unset${this.storeName}MetaValues`, args).then(() => {
          this.$emit('updated', updated)
        });
      }

      this.setValue(value);
      this.editing = false;
    },
    setValue(value) {
      if (!this.updatedValue) {
        this.updatedValue = value;
      }
      this.updatedValue = value
    },
    getValue() {
      if (this.hasUpdateValue()) {
        return this.updatedValue;
      }

      if (this.hasValue()) {
        return this.value;
      }

      if (this.hasDefault()) {
        return this.field.default;
      }
      return null;
    },
    hasValue() {
      return this.value && this.value.length > 0;
    },
    hasUpdateValue() {
      return this.updatedValue && this.updatedValue.length > 0;
    },
    hasDefault() {
      return !!this.field.default;
    },
  },
}
</script>

<style lang="scss" scoped>
.meta-field {
  background-color: $meta-field-background-color;
}

pre {
  color: #fff;
}

.meta-field-label {
  font-size: 1em;
  margin-right: 10px;
  width: 20%;
  min-width: 100px;

  &.editing {
    background-color: $input-background-color;
  }
}

.meta-field-value {
  background-color: darken($meta-field-background-color, 10%);
  border: 1px solid darken($meta-field-background-color, 15%);
}

td, th {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

td.default-value {
  width: 100px;
}

table tr {
  border: 1px solid darken($meta-field-background-color, 10%);
}

.table-edit {
  position: relative;
  height: 100%;
  cursor: pointer;
  width: 40px;
  min-width: 40px;
  border-right: 1px solid $input-background-color;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: all 300ms ease;
    transition: all 300ms ease;
    opacity: 0.8;
  }

  &:hover {
    .icon {
      opacity: 1;
    }
  }
}
</style>
