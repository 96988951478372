import { render, staticRenderFns } from "./QR_CODE.vue?vue&type=template&id=4116e7a5&scoped=true&"
import script from "./QR_CODE.vue?vue&type=script&lang=js&"
export * from "./QR_CODE.vue?vue&type=script&lang=js&"
import style0 from "./QR_CODE.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./QR_CODE.vue?vue&type=style&index=1&id=4116e7a5&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4116e7a5",
  null
  
)

/* custom blocks */
import block0 from "./QR_CODE.vue?vue&type=custom&index=0&blockType=div&%40click=getCam()"
if (typeof block0 === 'function') block0(component)

export default component.exports