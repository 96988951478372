<template>
  <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
    <div
        :class="['form-block', getBlockDraggableClasses(blockType)]"
        :elementType="blockType"
    >
      <h2 class="vform-editor-label">
        {{ $t("vform." + blockName) }}
      </h2>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Block",
  props: {
    blockName: {type: String, required: true},
    blockType: {type: String, required: true},
    state: {type: Object, required: true}
  },
  data() {
    return {

    }
  },
  methods: {
    getBlockDraggableClasses(elementType){
      if (this.state.hotspotPopupVisible) {
        if (this.hotspotPopupAllowedElementTypes.find((x) => {
          return x === elementType
        })) {
          return "draggable";
        }
        else{
          return "disabled"
        }
      }
      else{
        return "draggable";
      }
    }
  }
}
</script>


<style scoped lang="scss">
.form-block {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  //border: solid 4px var(--vform-editor-panel-active-background-color;
  border-radius: 8px;
  min-height: 50px;
  height: 100%;
  padding: 12px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  -moz-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 8px 6px -5px black;
  //box-sizing: border-box;
  //margin: 3px;

  &.disabled {
    opacity: 0.2;
  }
}
</style>