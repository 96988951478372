<template>
    <main-table-layout
        sub-menu-selector="librarypanel"
    >
        <content-filter
                slot="filter"
                @filter="filtering"
                store-name="Dataset"
                id="datasetList"
                :type-filter-items="['tbl', 'obj']"
        >
          <label class="mt-3">{{ $t('Schema') }}</label>
          <select class="custom-select darker" v-model="schemaName">
            <option :value="null">None</option>
            <option value="sfx.event">vForm logs</option>
            <option value="sfx.formElementData">vForm custom data</option>
            <option value="sfx.courseSession">Session tables</option>
          </select>
        </content-filter>
        <view-switcher
                slot="mainContentButtons"
                id="mainContent"
                :table="true"
                :thumbnails="true"
        />
        <pagination
                slot="mainContentButtons"
                :limit="paginationLimit"
                :total="$store.getters.getDatasetListOptions(listName, 'pagination_items')"
                id="datasettablepage"
                @setActive="loadPaginatedData"
                ref="pagination"
        />
      <div slot="mainContentButtons">

      <Button
            permission="createDataSet"
            type="action-button" link="/library/dataset/add" icon="plus">
                    {{ $t('createNewDataSet') }}
        </Button>
      </div>

      <Button
           slot="mainContent"
           @click="loadPaginatedData(0); $refs.pagination.setActive(0)"
           type="reload"
           icon="redo"
           class="icon-button"
       />
        <div slot="mainContent">
            <table-widget
                    v-if="$store.getters.getViewSwitcher(this.$route.name, 'mainContent') === 'table'"
                    id="appsDefaultTable"
                    :fields="fields"
                    :data="$store.getters.getDatasetsByListName(listName)"
                    :title-as-links="true"
                    @sort="sort"
                    :nav-by-clicking-row="true"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :show-organization="true"
                    edit-permission="writeDataSet"
                    delete-permission="deleteDataSet"
                    :use-team-permission="true"
                    :loading="loading"
                    :initial-sorting="sortObject"
            />
            <thumbnails
                    v-if="this.$store.getters.getViewSwitcher(this.$route.name, 'mainContent') === 'thumbnails'"
                    id="appsDefaultTable"
                    :fields="thumbnailFields"
                    :data="$store.getters.getDatasetsByListName(listName)"
                    :title-as-links="true"
                    @sort="sort"
                    :deletable="true"
                    @deleteRow="deleteRow"
                    :nav-by-clicking-row="true"
                    edit-permission="writeDataSet"
                    delete-permission="deleteDataSet"
                    :use-team-permission="true"
                    :loading="loading"
            />
        </div>
    </main-table-layout>
</template>

<script>
    import Button from "../components/forms/Button";
    import TableWidget from "../components/widgets/TableWidget";
    import ContentFilter from "../components/widgets/filter/ContentFilter";
    import MainTableLayout from "../layouts/MainTableLayout";
    import ViewSwitcher from "../components/widgets/ViewSwitcher";
    import Thumbnails from "../components/widgets/ThumbnailView";
    import Pagination from "../components/Pagination";
    import LoadOverviewMixinJs from "../components/mixins/LoadOverviewMixin.js.vue";
    export default {
        name: 'DataSets',
      mixins: [LoadOverviewMixinJs],
        components: {
            Button,
            TableWidget,
            ContentFilter,
            MainTableLayout,
            ViewSwitcher,
            Thumbnails,
            Pagination,
        },
        data() {
            return {
                schemaName: null,
                listName: 'DatasetList',
                include: 'teams',
                deleteMethod: 'deleteDataset',
                registerMethod: 'registerDatasetQueryParams',
                loadMethod: 'loadDatasets',
                filter: {
                  add: {
                    filter: {
                      [this.listName]: 'schemaName eq ' + this.schemaName
                    }
                  }
                },
                fields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ],
                thumbnailFields: [
                    {
                        name: '',
                        id: 'icon',
                        type: 'icon',
                        virtual: true,
                        fieldId: 'type'
                    },
                    {
                        name: 'Organization',
                        id:'organization',
                        type: 'organization',
                        sortable: true
                    },
                    {
                        name: 'Type',
                        id: 'type',
                        type: 'string',
                    },
                    {
                        name: 'Last Update',
                        id: 'updatedAt',
                        type: 'date',
                        sortable: true
                    },
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                      name: 'Teams',
                      id: 'teams',
                      type: 'teams',
                    },
                    {
                        name: 'Edit',
                        type: 'editButton'
                    },
                ]
            }
        },
        watch: {
            schemaName() {
              this.filtering({
                add: {
                  filter: {
                    [this.listName]: 'schemaName eq ' + this.schemaName
                  }
                }
              })
            }
        },
        methods: {
          deleteRow(id) {
            this.$store.dispatch('clientDeleteDataset', {id})
                .then(() => {
                  this.loadPaginatedData(0);
                });
          },
        }
    }
</script>
