<template>
  <div
      :class="['w-100 vform-text-display mb-3 position-relative', config.emphasized ? 'vform-emphasized font-weight-bold' : '', config.backgroundColor ? 'with-bg' : 'without-bg']"
      :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: !config.emphasized && config.fontColor ? config.fontColor : '',
            fontFamily: getFontName()
          }">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <h3 :key="'title' + forceReRenderKey">{{ getLabel(config, lang) ? getLabel(config, lang) : $t('Summary') }}</h3>
    <div v-if="editorMode">{{ $t('NoSummaryInEditMode') }}</div>
    <div v-else>
      <div
          v-for="(elementId) in getElementListForm(stepFormData)"
          :key="elementId + forceReRenderKey"
          class="mb-1"
      >
        <div>{{ getElementQuestion(elementId, globals, steps, lang) }}</div>
        <div class="form-summary-result">{{ getElementInfo(elementId, stepFormData, lang) }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import vFormAndProjectMixin from "../vFormAndProjectMixin.js.vue";

export default {
  name: "Text",
  components: {
    EditorOverlay
  },
  mixins: [vFormAndProjectMixin, ElementMixin],
  props: {
    stepFormData: {type: Object, default: () => {return {}}},
    steps: {type: Array, default: () => {return []}},
    globals: {type: Object, default: () => {return []}},
  },
  data() {
    return {
      localString: "",
      forceReRenderKey: 0
    };
  },
  watch: {
    stepFormData: {
      deep: true,
      handler() {
        this.forceReRenderKey++;
      }
    },
    lang() {
      this.forceReRenderKey++;
    }
  },
  beforeMount() {
    this.setUniqueId();
  },
  mounted() {
    this.increaseCounter();
  },
  methods: {
    getStepName(id) {
      const step = this.getStepById(id);
      if(step) {
        return step.name ? step.name : step.step;
      }
      return "";
    },
    setEditing() {
      if (this.editable) {
        this.editing = true;
        this.setCursor(this.uniqueId);
      }
    },
    onInput(e) {
      this.setLabel(this.config, this.lang, e.target.innerText);
    },
    validate() {
      return true;
    },
    checkRequired() {
      return true;
    },
    getData() {
      return {data: this.getLabel(this.config, this.lang), type: 'text'};
    }
  }
}
</script>

<style lang="scss" scoped>
// inner div
.with-bg {
  padding: 5px 6px;
}

// outer div
.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;

  &.with-bg {
    width: calc(100% + 20px) !important;
  }
}
.form-summary-label {
  text-transform: uppercase;
  font-size: 0.75em;
}
.form-summary-result {
  font-size: 0.8em;
}
</style>