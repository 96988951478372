<template>
  <div :class="['mb-3 position-relative vform-slide-control-element', tiles ? 'vform-image-tiles' : '', config.alignment]">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
    />
    <preview
      v-if="getImage(config, lang)"
      :may-load-now="isActive"
      :preview-id="getImage(config, lang)"
      :size="config.size"
      source="asset"
      :asset-id="getImage(config, lang)"
      :removable="false"
      :class="['vform-image', config.alignment]"
      @preview="previewIsHere = true"
      :background-mode="false"
      :offline-mode="offlineMode"
      @contentLoaded="increaseCounter"
     />
    <div class="text-center" v-else>
      {{ $t('noImageSelected') }}<br />
      <icon class="lighter" type="image" size="3" />
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import Icon from "../../Icon";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "IMAGE",
  components: {
    Preview,
    Icon,
    EditorOverlay
  },
  mixins: [ElementMixin],
  props: {
  },
  data() {
    return {
      forceReRenderKey: 0,
      previewIsHere: false,
      tiles: false
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.tiles = this.isTiles(this.config);
        this.forceReRenderKey++;
      }
    }
  },
  beforeMount() {
    this.tiles = this.isTiles(this.config)
  },
  methods: {
    validate() {
        return true;
    },
    checkRequired() {
      return true;
    },
  }
}
</script>

<style lang="scss" scoped>

</style>