<template>
  <div @click="setEditingProperty"
       :class="['vform-viewer-editor-overlay d-flex', active ? 'active' : 'inactive']">
    <!--<div class="mr-2" @click.stop="setEditing">
      <icon type="edit" />
    </div>-->
    <div @click.stop="$emit('removeBlock')" class="editor-overlay-icon icon2">
      <icon type="trash" size="0.9" />
    </div>
    <div class="editor-overlay-icon icon1">
      <icon type="grip-dots-vertical" size="0.9" />
    </div>
  </div>
</template>

<script>
import Icon from "../../Icon";
export default {
  name: "EditorOverlay",
  components: {Icon},
  props: {
    active: {type: Boolean, default: false}
  },
  methods: {
    setEditing() {
      this.$emit("edit");
    },
    setEditingProperty() {
      this.$emit("editProperty");
    },

  }
}
</script>

<style lang="scss" scoped>
  $icon-width: 18px;
  .icon1 {
    position:absolute;
    bottom: 0;
    right: 0;
    width: $icon-width;
    height: $icon-width;
    cursor: move;
  }
  .icon2 {
    position:absolute;
    bottom: 0;
    right: $icon-width + 3;
    width: $icon-width;
    height: $icon-width;
  }
</style>