<template>
  <div class="position-relative w-100">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <div v-if="!vSTAGEContext" class="mb-2">
      {{ $t('vform.partInfovStageOnly') }}
    </div>
    <div v-if="vSTAGEContext" class="mb-2 d-flex" style="flex-direction: column">
      <div v-if="!instanceId">
        {{ $t("vform.selectInstanceFromScene") }}
      </div>

      <div v-if="instanceId">
        <label class="meta-field-label">{{ $t('Selected Part: ') }}</label>
        <label class="meta-field-value"> {{ $t(this.instanceName) }}</label>
      </div>
      <div v-for="(item, key, index) in smashedInstanceMetaSets" v-if="instanceId" :key="index"
           class="">
        <label class="meta-field-label">{{ key }}</label>
        <label v-if="item" class="meta-field-value"> {{ item }}</label>
        <label v-else class="meta-field-value"> - </label>
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js";
import EditorOverlay from "./EditorOverlay";
import {combineAssetAndInstanceMetaValues_v2} from "@/utils";

export default {
  name: "PART_INFO",
  components: {EditorOverlay},
  mixins: [ElementMixin, MetaSetsMixin],
  props: {
    instanceId: {type: String, default: ""}
  },
  data() {
    return {
      assetId: null,
      projectId: null,
      instanceMetaSets: null,
      assetMetaSets: null,
      smashedInstanceMetaSets: null,
      instanceName: ''
    };
  },
  beforeMount() {
    //this.instanceId = '42e0a832-892e-4ad4-a123-ae2dffe6dcc5';
  },
  mounted() {
    this.$emit('increasePostLoadingCounter');
    if (this.instanceId) {
      this.loadInstance();
    }
  },
  watch: {
    instanceId: function (val) {
      this.instanceId = val;
      if (this.instanceId) {
        this.loadInstance();
      }
    }
  },
  methods: {
    async loadInstance() {
      this.assetId = null;
      this.smashedInstanceMetaSets = null;
      this.instanceName = 'vform.selectInstanceNotFound';
      // load instance to retrieve assetId and projectId
      await this.$store.dispatch('clientGetCrossProjectInstances', {
        filter: `id eq ${this.instanceId}`,
        include: []
      }).then(data => {
        if (data[0]) {
          this.instanceName = data[0].name;
          this.projectId = data[0].projectId;
          this.assetId = data[0].assetId;
        }
      });

      if (this.assetId) {
        // load asset
        await this.$store.dispatch('clientLoadAsset', {
          id: this.assetId,
          include: 'metaSets'
        }).then(asset => {
          const {metaSets} = asset;
          this.assetMetaSets = metaSets;
        });

        this.instanceMetaValues = await this.$store.dispatch('getProjectInstanceMetaValues', {
          id: this.projectId,
          cid: this.instanceId,
        });
        this.makeMetaFieldsForInstance();
      }
    },
    makeMetaFieldsForInstance() {
      this.instanceMetaSets = combineAssetAndInstanceMetaValues_v2(this.assetMetaSets, this.instanceMetaValues);

      //filter based on what fields to display (config.metaFields)
      this.instanceMetaSets.forEach((metaset) => {
        let fieldsClone = JSON.parse(JSON.stringify(metaset.metaFields));
        fieldsClone.forEach((field) => {
          if (!this.config.metaFields.find((id) => id === field.id)) {
            const index = metaset.metaFields.findIndex((f) => {
              return f.id === field.id
            });
            metaset.metaFields.splice(index, 1);
          }
        });
      });

      this.smashedInstanceMetaSets = this.smashMetaSets(this.instanceMetaSets, true);
    },
  }
}
</script>

<style lang="scss" scoped>
.meta-field-label {
  font-weight: 600;
  min-width: 150px;
}

.meta-field-value {
  min-width: 200px;
}
</style>