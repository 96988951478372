<template>
  <div class="position-relative w-100">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @removeBlock="$emit('removeBlock')"
        @editProperty="editProperty"
    />
    <div v-html="getLabel(config, lang) ? getLabel(config, lang) : placeholders.EMBED">
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "CODE_SNIPPET",
  components: {EditorOverlay},
  mixins: [ElementMixin],
  mounted() {
    this.increaseCounter();
  }
}
</script>