var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:[
            'vform-slide-control-element',
            'border-radius',
            'multi-line',
            _vm.showProgress && _vm.progressInPercent > 0 ? 'startedCourseItem' : 'notdone2',
            _vm.showProgress && _vm.progressInPercent === 100 ? 'doneCourseItem' : 'notdone',
            _vm.isActive ? 'activeCourseItem' : 'inactiveCourseItem' ],on:{"click":function($event){_vm.isActive ? function () {} : _vm.$emit('gotClicked', _vm.node)}}},[_c('div',{class:[
                'inner align-items-left text-left',
                !_vm.disabled ? 'clickable' : 'no-button-hover-effect',
                'simple-button' ]},[_c('div',[_vm._v(_vm._s(_vm.node.name))]),_c('div',{staticClass:"d-flex align-items-start"},[(_vm.progressData[_vm.node.id] && _vm.showProgress)?_c('div',{staticClass:"progress-bar-course"},[_c('div',{staticClass:"progress",style:({width: _vm.progressInPercent + '%'})},[_vm._v(_vm._s(_vm.progressInPercent)+"%")])]):_vm._e(),(_vm.progressData[_vm.node.id] && _vm.showProgress)?_c('div',{staticClass:"steps-progress text-right"},[_vm._v("("+_vm._s(_vm.progressData[_vm.node.id] && _vm.progressData[_vm.node.id].completedSteps ? _vm.progressData[_vm.node.id].completedSteps.length : 0)+"/"+_vm._s(_vm.progressData[_vm.node.id].numberOfSteps)+" steps)")]):_vm._e()])])]),(_vm.editable && _vm.childIndex !== 0)?_c('span',{staticClass:"clickable ml-1",on:{"click":function($event){return _vm.moveCourseItemUp(_vm.node.id)}}},[_c('icon',{attrs:{"type":"angle-up"}})],1):_vm._e(),(_vm.editable)?_c('span',{staticClass:"lighter clickable ml-4",on:{"click":function($event){return _vm.removeCourseItem(_vm.node.id)}}},[_c('icon',{attrs:{"type":"trash-alt"}})],1):_vm._e(),_vm._l((_vm.node.children),function(subnode,index){return (_vm.node.children)?_c('course-item-node',{key:subnode.id,attrs:{"show-move-up":index !== 0,"node":subnode,"move-course-item-up":_vm.moveCourseItemUp,"remove-course-item":_vm.removeCourseItem,"child-index":index,"editable":_vm.editable,"active-nodes":_vm.activeNodes},on:{"gotClicked":function (item) {_vm.$emit('gotClicked', item)}}}):_vm._e()})],2)}
var staticRenderFns = []

export { render, staticRenderFns }