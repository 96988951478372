<template>
  <div class="w-100 position-relative">
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <div class="user-id-log-entry">
      {{ textUserId ? textUserId : $store.getters.getvFormUserId}}
    </div>
    <div :class="['mb-3', config.emphasized ? 'vform-emphasized' : '']">
      <div :class="['w-100 vform-item-with-icon-container mb-3', getImage(config, lang) ? '' : 'w-100']">
        <div class="text d-block">
          <div class="vform-label-for-input">
            {{ getLabel(config, lang) }}{{ getLabel(config, lang) && getRequired(config) ? "*" : "" }}
          </div>
          <!--<div :class="disabled ? 'lighter' : ''">{{ getLabel(config, lang) }}{{ getRequired(config) ? "*" : "" }}</div>-->
          <input v-if="!config.textArea"
                 v-model="value"
                 :class="['w-100 vform-form-text', markAsMissing ? 'vform-error' : '', disabled ? 'lighter' : '']"
                 :disabled="disabled"
                 :style="{ borderColor: fontColor && !config.emphasized ? fontColor : 'inherit', color: fontColor && !config.emphasized ? fontColor : 'inherit'}"
                 type="text"
                 @keyup="(evt) => {setNewUser(); storeData(evt.target.value)}"
                 />
          <textarea
              v-if="config.textArea"
              v-model="value"
              :rows="config.textAreaRows"
              :class="['w-100 vform-form-text', markAsMissing ? 'vform-error' : '', disabled ? 'lighter' : '']"
              :disabled="disabled"
              :style="{ borderColor: fontColor && !config.emphasized ? fontColor : 'inherit', color: fontColor && !config.emphasized ? fontColor : 'inherit'}"
              type="text"
              @keyup="(evt) => {setNewUser(); storeData(evt.target.value)}"
              />
        </div>
      </div>
    </div>
    <component-logger-viewer
        v-if="config.logId"
        :element="config"
        :edit-mode="editable"
        :form-data="value"
        :project-name="projectName"
        :user-id="userId"
        :session-id="sessionId"
        :element-label="getLabel(config)"
    />
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
import ComponentLoggerViewer from "./ComponentLoggerViewer.vue";

export default {
  name: "SHORT_ANSWER",
  components: {
    EditorOverlay,
    ComponentLoggerViewer
  },
  mixins: [ElementMixin],
  props: {
    userId: {type: String, default: null},
    sessionId: {type: String, default: null},
  },
  data() {
    return {
      value: '',
      markAsMissing: false,
      textUserId: null
    };
  },
  beforeMount() {
    if (this.isID(this.config) && this.userId) {
      this.value = this.userId;
    }
    /*const preData = this.$store.getters.getStepFormData;
    if(preData && preData[this.config.uuid]) {
      console.log('found pre data, prefilling:')
      console.log(preData[this.config.uuid]);
      this.fillData(preData[this.config.uuid]);
    }*/
  },
  mounted() {
    this.increaseCounter();
  },
  methods: {
    setNewUser() {
      this.textUserId = this.$store.getters.getvFormUserId;
    },
    validate() {
      return this.validateOptions();
    },
    checkRequired() {
      if (this.getRequired(this.config)) {
        this.markAsMissing = !this.value;
        return !!this.value;
      }
      return true;
    },
    fillData(data) {
      console.log('fill data triggered for ' + this.config.uuid)
      if(data) {
        console.log('received data:')
        console.log(data)
        try {
          data = JSON.parse(JSON.stringify(data))
          console.log('parsed data:')
          console.log(data)
          const {answer, userId} = data;

          this.value = answer;
          this.textUserId = userId;
        } catch (e) {
          console.log(e)
          console.log('prefilling short answer data did not work')
        }
      }
    },
    retrieveData() {
      return JSON.parse(JSON.stringify({answer: this.value, userId: null}));
    },
    storeData(value) {
      const args = JSON.parse(JSON.stringify({
        answer: value,
        userId: this.textUserId ? this.textUserId : this.$store.getters.getvFormUserId
      }))
      this.$emit('storeData', this.config.uuid, args)
    },
    getData() {
      if (!this.disabled) {
        const label = this.getLabel(this.config, this.currentLang);
        const data = {
          question: label,
          elementId: this.config.uuid,
          answers: [{
            answer: this.value,
            userId: this.textUserId ? this.textUserId : this.$store.getters.getvFormUserId
          }]
        }
        return {
          fields: [{
            value: (label ? label : 'no-label') + ';' + this.value,
            type: "default",
            data
          }],
          type: 'textAnswer'
        };
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
.vform-emphasized {
  .vform-label {
    color: var(--vform-editor-layout-quaternary-color);
  }

  .vform-form-text {
    border: 1px solid var(--vform-editor-layout-quaternary-color);
    color: var(--vform-editor-layout-quaternary-color);
  }
}

.vform-form-text:disabled {
  background-color: transparent;
}
</style>