<template>
  <div>
    <label class="vform-editor-label section">Question</label>
    <div
        v-for="(key, index2) in Object.keys(element.label.dix)"
        v-if="element.currentLang === key"
        :key="index2 + 1000000000 + forceReRenderKey"
        class="dflex"
    >
      <div class="col p-0">
                <textarea
                    v-model="element.label.dix[key]"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.LONG
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    :title="element.label.dix['Unknown']"
                    class="form-text v-form-label form-text-dark"
                    rows="300"
                    type="text"
                />
        <div class="col row mt-2">
          <div class="col-12 pl-0 dflex">
            <label :for="'chkNoQuestion' + $vnode.key" class="container vform-editor-body"
            >{{ $t("noQuestion") }}
              <input
                  :id="'chkNoQuestion' + $vnode.key"
                  v-model="element.noQuestion"
                  type="checkbox"
              />
              <span class="checkmark"></span>
            </label>
          </div>
        </div>

        <!-- :placeholder="
                key === 'Unknown' ? $t('Question') : key + ' ' + $t('question')
            " -->

        <!--ANSWERS-->
        <label class="vform-editor-label section">Answers</label>
        <label class="vform-editor-label section" style="float: right"
        >Correct</label
        >
        <div
            v-for="(item, index4) in element.options"
            :key="index4 + 98239283 + forceReRenderKey"
            class="dflex mb-1"
        >
          <div class="col pl-0 pr-0 dflex">
            <div class="col pl-0">
              <textarea
                  v-model="item.text.dix[key]"
                  :placeholder="
                      key === 'Unknown'
                          ? placeholders.SHORT
                          : $t('vform.translationMessage', {
                                key: key,
                                content: item.text.dix['Unknown'],
                            })
                  "
                  :title="item.text.dix['Unknown']"
                  class="form-text v-form-label form-text-dark mb-2"
                  rows="300"
                  type="text"
              />
              <div
                  class="icon-delete-option"
                  style="position: absolute; top: 0px; right: 16px"
                  @click="removeOption(index4)"
              >
                <icon :size="'1.8'" type="fat-square-minus"/>
              </div>
              <div class="w-100 pl-0 pr-0">
                <div v-if="true" class="col-12 dflex row">
                  <label
                      :for="'chkPreSelected' + $vnode.key + index4"
                      class="container"
                      style="padding-left: 5px !important"
                  ><div class="ml-4">{{ $t('isPreSelected') }}</div>
                    <input
                        :id="'chkPreSelected' + $vnode.key + index4"
                        v-model="item.isPreSelected"
                        type="checkbox"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>
            <div class="col-auto pl-0 pr-0">
              <div class="col-12 dflex row">
                <checkbox-widget :class="'pl-1'" :initial-value="item.isCorrectAnswer"
                                 :label-for="'chkCorrect' + $vnode.key + index4"
                                 @change="(val) => { item.isCorrectAnswer = val; }">
                </checkbox-widget>
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 mt-1 text-right col" style="padding-right: 34px">
          <span @click="addOption(key)">
              <icon :size="'2.0'" type="fad-square-plus"/>
          </span>
        </div>
      </div>
    </div>
    <template v-if="!element.emphasized">
      <label class="vform-editor-label section">Background Color</label>
      <div
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          @click="colorPickrId = element.uuid"
          class="clickable"
      />
      <color-picker
          v-if="colorPickrId === element.uuid"
          ref="colorpickr"
          :popup-style="true"
          :show-save-button="true"
          :value="element.backgroundColor ? element.backgroundColor : '#fff'"
          class="step-settings-color-pickr"
          @changeColor="
              (color) => {
                setColor(color, false);
              }"
          @close="
                () => {
                    colorPickrId = null;
                }
            "
          @save="
                (color) => {
                     setColor(color);
                }
            "
      />

      <label class="vform-editor-label section">Font Color</label>
      <div
          :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
          @click="colorPickrId = 'fontColor'"
          class="clickable"
      />
      <color-picker
          v-if="colorPickrId === 'fontColor'"
          ref="colorpickr"
          :popup-style="true"
          :show-save-button="true"
          :value="element.fontColor ? element.fontColor : '#000'"
          class="step-settings-color-pickr"
          @changeColor="
              (color) => {
                setFontColor(color, false);
              }"
          @close="
                () => {
                    colorPickrId = null;
                }
            "
          @save="
                (color) => {
                     setFontColor(color);
                }
            "
      />
      <div
          v-if="element.backgroundColor || element.fontColor"
          class="settings-button d-inline-block mt-2"
          @click="element.backgroundColor = null; element.fontColor = null;">
        {{ $t('resetColor') }}
      </div><br />
    </template>

    <label class="vform-editor-label section">Options</label>
    <!--ADD DIX TRANSLATION-->
    <div class="col row mt-2">
      <div class="col-12 pl-0 dflex">
        <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body"
        >{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="col-12 pl-0 dflex">
        <label :for="'chkRequired' + $vnode.key" class="container vform-editor-body"
        >{{ $t("isRequired") }}
          <input
              :id="'chkRequired' + $vnode.key"
              v-model="element.isRequired"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="col-12 pl-0 dflex">
        <label :for="'chkCorrect' + $vnode.key" class="container vform-editor-body"
        >{{ $t("validateCorrectness") }}
          <input
              :id="'chkCorrect' + $vnode.key"
              v-model="element.validateCorrectness"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <!--DIX-->
    <!--FOR EACH TRANSLATION ONE FIELD-->
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {vFormControls, placeholders} from "@/enum";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";
import ColorPicker from "../widgets/editors/ColorPicker";
import {v4 as uuidv4} from "uuid";
export default {
  name: "vFormMULTIPLE_CHOICE",
  components: {
    Icon,
    CheckboxWidget,
    ColorPicker,
  },
  props: {
    element: {type: Object, required: true},
    stepNo: {type: Number, required: true},
    organizationId: {type: String, default: ""},
  },
  mixins: [vFormElementMixin],
  data() {
    return {
      forceReRenderKey: 0,
      editImage: "",
      vFormControls: vFormControls,
      placeholders: placeholders,
    };
  },
  watch: {
    element: {
      deep: true,
      handler() {
      },
    },
  },
  beforeMount() {
    if (!this.element.options) {
      this.addOption("Unknown");
    }
    if(!this.element.noQuestion) {
      this.$set(this.element, "noQuestion", false)
    }
  },
  methods: {
    removeOption(index) {
      this.element.options.splice(index, 1);
    },
    addOption(lang) {
      if (!this.element.options) {
        this.$set(this.element, "options", []);
      }
      this.element.options.push({
        uuid: uuidv4(),
        isCorrectAnswer: false,
        isChecked: false,
        isPreSelected: false,
        text: {
          dix: {
            [lang]: "",
          },
          imageDix: {
            [lang]: "",
          },
        },
      });
      this.forceReRenderKey++;
    },
  },
};
</script>
