<template>
<div>
  <div v-if="error">{{ error }}</div>
  <div v-if="isDone">
    Done!
  </div>
  <div v-else>
    <h3>Source of data</h3>
    <div v-if="!logTables.length" class="settings-button d-inline-block mb-2" @click="loadAllLogTables">choose other than the current log table</div><br />
    <div v-if="logTables && logTables.length">Choose the other log table to retrieve data from (if not set, it will use the log table attached to this vform)</div>
    <select v-if="logTables && logTables.length" class="form-text form-text-dark" v-model="sourceDataSetId">
      <option :value="logTable.id" :key="logTable.id" v-for="logTable in logTables">
        {{ logTable.name }} ({{logTable.id}})
      </option>
    </select>
    <session-user-selector
        ref="selector"
        :log-id="sourceDataSetId ? sourceDataSetId : logId"
        :show-print-items="false"
        :hide-user-selection="true"
        @sessionSelected="getRows"
    />
    <h3 class="mt-3">Course</h3>
    Please select a course
    <select @change="loadSessions" class="form-text form-text-dark" v-model="selectedCourse">
      <option :value="course" :key="course" v-for="course in courseSelection">
        <content-display
            :value="course"
            type="form"
            :show-inline="true"
        />
      </option>
    </select>
    <div v-if="selectedCourse">
      Please select a session for this course
      <select class="form-text form-text-dark" v-model="selectedCourseSession">
        <option :value="session.sessionId" :key="session.sessionId" v-for="session in courseSessions">
          {{ session.sessionId }} {{ session.time }}
        </option>
      </select>
    </div>
    <div v-if="sessionData && selectedCourse">
      Found entries: {{ sessionData.length }}
      <br />
      New Session Id: {{ selectedCourseSession }}<br />
      <div @click="duplicateRowsWithId" v-if="selectedCourseSession && !saving" class="settings-button d-inline-block mt-1">{{ $t('start') }}</div>
      <loading-spinner v-if="saving" />
    </div>
  </div>
</div>
</template>
<script>
import SessionUserSelector from "@/components/vForm/logs/SessionUserSelector.vue";
import LoggingMixin from "@/components/vForm/viewer/loggingMixin.vue";
import {v4 as uuidv4} from "uuid";
import LoadingSpinner from "@/components/LoadingSpinner.vue";
import {SpecialUuids} from "@/enum";
import ContentDisplay from "@/components/widgets/editors/ContentDisplay.vue";
export default {
  name: "CourseLogCorrector",
  components: {
    SessionUserSelector,
    LoadingSpinner,
    ContentDisplay
  },
  mixins: [LoggingMixin],
  props: {
    logId: {type: String, required: true},
    targetLogId: {type: String, default: null},
    assetId: {type: String, required: true}
  },
  data() {
    return {
      sessionData: null,
      newSessionId: "",
      saving: false,
      courseSelection: [],
      selectedCourse: null,
      courseSessionTable: null,
      selectedCourseSession: null,
      courseSessions: [],
      isDone: false,
      error: "",
      logTables: [],
      sourceDataSetId: null
    }
  },
  beforeMount() {
    this.dataSetId = this.logId;
    this.loadCourseMetaValues();
  },
  methods: {
    async loadAllLogTables() {
      this.logTables = await this.$store.dispatch('clientLoadDatasets', {
        filter: 'schemaName eq sfx.event'
      })
      this.logTables.sort(( a, b ) => {
        if ( a.name < b.name ){
          return -1;
        }
        if ( a.name > b.name ){
          return 1;
        }
        return 0;
      })
    },
    getRows() {
      this.sessionData = this.$refs.selector.getRowsWithSession();
    },
    async duplicateRowsWithId() {
      this.error = "";
      this.saving = true;
      await this.getColumns();
      let newData = this.sessionData.map(item => {
        if(item.data && item.data) {
          item.data = this.addUserIdToAllAnswers(item.data, item.userId);
        }
        if(item.key) {
          item.key = item.key.replace(item.sessionId, this.selectedCourseSession)
        }
        item.sessionId = this.selectedCourseSession;
        item.id = uuidv4();
        if(item.visited === true) {
          item.completed = true;
        }
        const data = this.mapEntry(this.columns, item, false);
        return {
          data,
          key: item.key
        }
      })
      if (newData.length) {
        await this.$store.dispatch('clientCreateDatasetRows', {
          id: this.logId,
          values: newData
        }).catch(e => {
          this.error = e;
        });
      }
      if(!this.error) {
        this.saving = false;
        this.isDone = true;
      }
    },
    addUserIdToAllAnswers(data, userId) {
      for(let i = 0; i < data.length; i++) {
        let {answers} = data[i];
        if(answers) {
          for(let j = 0; j < answers.length; j++) {
            let answer = answers[j];
            answer.userId = userId;
            data[i].answers[j] = answer;
          }
        }
      }
      return data;
    },
    async loadCourseMetaValues() {
      let metaValues = await this.$store.dispatch('clientLoadMetaValues', {
        id: SpecialUuids.COURSE_METASET,
        cid: SpecialUuids.COURSE_ITEMS_METAFIELD
      })
      if (metaValues && metaValues.length) {
        this.courseSelection = metaValues.map(item => {
          return item.targetId;
        })
      }
    },
    setSessionTable(metaValues) {
      const sessionTable = metaValues.find(item => {
        return item.metaFieldId === SpecialUuids.COURSE_DATASET_METAFIELD;
      });
      if(sessionTable) {
        const {value} = sessionTable;
        this.courseSessionTable = value;
      }
    },
    async loadSessions() {
      this.courseSessions = [];
        let metaValues = await this.$store.dispatch('clientLoadAssetMetaValues', {
          id: this.selectedCourse
        });
        this.setSessionTable(metaValues);

        this.courseSessions = await this.$store.dispatch('getSessionsFromDB', {
          sessionTable: this.courseSessionTable
        });
    }
  }
}
</script>