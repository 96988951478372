<template>
  <div
      :class="['w-100 vform-text-display position-relative', config.emphasized ? 'vform-emphasized font-weight-bold' : '', config.backgroundColor ? 'with-bg' : 'without-bg']"
      :style="{
            backgroundColor: config.backgroundColor ? config.backgroundColor : '',
            color: !config.emphasized && config.fontColor ? config.fontColor : '',
            fontFamily: getFontName()
          }">
    <slot name="dropzone"></slot>
    <!--todo: check if preview is really necessary anymore...-->
    <preview
        v-if="getImage(config, lang)"
        :asset-id="getImage(config, lang)"
        :may-load-now="isActive"
        :preview-id="getImage(config, lang)"
        :removable="false"
        class="mini mr-2 float-left"
        source="asset"
    />
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <div
        :id="uniqueId"
        :class="[config.backgroundColor ? 'with-bg' : 'without-bg']"
        contenteditable="false"
        @input="onInput"
        @click.stop="doNothing">{{ getLabel(config, lang) ? getLabel(config, lang) : placeholders.LONG }}  <!-- Keep this on one line --></div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import Preview from "@/components/vForm/viewer/Preview";
import EditorOverlay from "./EditorOverlay";

export default {
  name: "Text",
  components: {
    Preview,
    EditorOverlay
  },
  mixins: [ElementMixin],
  data() {
    return {
      localString: ""
    };
  },
  beforeMount() {
    this.setUniqueId();
  },
  mounted() {
    this.increaseCounter();
    this.addListener(document, "click", this.documentClick, 'textdisplay');
  },
  methods: {
    documentClick() {
      this.leaveEditing(this.uniqueId);
    },
    setEditing() {
      if (this.editable) {
        this.editing = true;
        this.setCursor(this.uniqueId);
      }
    },
    onInput(e) {
      this.setLabel(this.config, this.lang, e.target.innerText);
    },
    validate() {
      return true;
    },
    checkRequired() {
      return true;
    },
    getData() {
      return {data: this.getLabel(this.config, this.lang), type: 'text'};
    }
  }
}
</script>

<style lang="scss" scoped>
// inner div
.with-bg {
  padding: 5px 6px;
}

// outer div
.vform-text-display {
  white-space: pre-wrap;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  font-size: 1em;
  margin-bottom: 0.6em;
  &.with-bg {
    width: calc(100% + 20px) !important;
  }
}
</style>