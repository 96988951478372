<template>
  <div>
    <div class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">{{ $t('vform.titleAlignment') }}</label>
      </div>
      <div
          v-for="(align, index) in titleAlignments"
          :key="index"
          :class="['vform-alignment-button', align === element.titleAlignment ? 'active' : '']"
          @click="setTitleAlignment(align)">
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
        <div class="dflex mt-3">
      <div class="col row">
        <label class="vform-editor-label section mt-0">{{ $t('vform.textAlignment') }}</label>
      </div>
      <div
          v-for="(align, index) in textAlignments"
          :key="index"
          :class="['vform-alignment-button', align === element.textAlignment ? 'active' : '']"
          @click="setTextAlignment(align)">
        <icon :type="'align-' + align" size="1"/>
      </div>
    </div>
    <div class="col row dflex mt-4">
      <div class="dflex">
        <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body">{{ $t("emphasized") }}
          <input
              :id="'chkEmphasized' + $vnode.key"
              v-model="element.emphasized"
              type="checkbox"
          >
          <span class="checkmark"/>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import {vFormControls} from "@/enum";
import Icon from "../Icon";
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";

export default {
  name: "VFormPARTLEGEND",
  components: {
    Icon
  },
  mixins: [vFormElementMixin],
  data() {
    return {
      vFormControls: vFormControls,
      titleAlignments: ["left", "center", "right"],
      textAlignments: ["left", "center", "right"],
    };
  },
  beforeMount() {
    this.element.formElementType = vFormControls["PART_LEGEND"];
    if (!this.element.titleAlignment) {
      this.$set(this.element, "titleAlignment", "left");
    }
    if (!this.element.textAlignment) {
      this.$set(this.element, "textAlignment", "left");
    }
  },
  methods: {
    setTitleAlignment(val) {
      this.$set(this.element, "titleAlignment", val);
    },
    setTextAlignment(val) {
      this.$set(this.element, "textAlignment", val);
    },
  }
};
</script>

<style lang="scss">
.vform-alignment-button {
  background-color: var(--vform-editor-ui-secondary-color);
  border-radius: 1px;
  margin-left: 1px;
  width: 25px;
  height: 25px;
  position: relative;
  color: #fff;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &.active {
    background-color: var(--vform-editor-accent-color);
  }
}
</style>