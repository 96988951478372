<template>
  <div class="w-100">
    <div
        :class="[
           'row vform-container-2 pl-0 pr-0',
           !viewerModeOnly ? '' : 'fullHeight',
           'active-col-' + activeColumn
        ]">
      <div
          :class="[
            'no-padding-force margin-auto',
            !viewerModeOnly && panelDocked ? 'col-9' : 'col-12',
          ]"
      >
        <panel-context-menu
            v-if="!viewerModeOnly && activeStepObject && showSlideShow"
            ref="contextMenu"
            :key="state.panelContextReRenderKey"
            :active-step-object="activeStepObject"
            :editor-mode="!viewerModeOnly"
            :global-object="config.global"
            :state="state"
            :config="config"
            @applyForAllPanels="applyForAllPanels"
            @reattach="addContextMenu"
            @addAllStepsToGlobalPanel="addAllStepsToGlobalPanel"
        />
        <transition-editor
            v-if="!viewerModeOnly && slides && videoConfig && transitionEditMode"
            ref="transitionEditor"
            :form-id="formId"
            :project-id="projectId"
            :slides="slides"
            :video-config="videoConfig"
            @abortEditing="transitionEditMode = false;"
            @reloadVideoConfig="loadVideoConfigFileNew"
        />
        <div v-if="!config || !config.steps || presentationNotAvailable"
             :class="['form-loading-screen', vSTAGEContext ? 'full-width' : 'normal',
             getWindowAppLoadingClasses()
             ]">
          <div v-if="presentationNotAvailable" class="form-error margin-auto p-2">
            {{ $t("projectFilesNotAvailable") }}
            <div class="settings-button d-inline-block" @click="reload">Reload</div>
          </div>
          <div v-else>
            <icon size="3.3" type="desktop"/>
            <loading-spinner class="white"/>
            <loading-spinner class="dark simple-spinner" size="big"/>

            <div class="loading-message">{{ loadingMessage }}</div>
          </div>
        </div>
        <slide-show-display
            v-if="showSlideShow && config"
            ref="slideShowDisplay"
            :session-config="sessionConfig"
            :active-mode="activeMode"
            :active-slide-meta="activeSlideMeta"
            :active-slide-uuid="activeSlideUuid"
            :active-step-index="activeStepIndex"
            :active-step-object="activeStepObject"
            :app-config="appConfig"
            :custom-screen-size="customScreenSize"
            :disable-links="disableLinks"
            :editor-mode="!viewerModeOnly"
            :form-id="formId"
            :initial-slide-id="initialSlideId"
            :project-id="projectId"
            :project-loading-mode="projectLoadingMode"
            :remote-language="language"
            :scaling="screenSize"
            :screen-orientation="screenOrientation"
            :show-images="!hideSlideImages"
            :slide-image-mode="slideImageMode"
            :slides="slides"
            :state="state"
            :v-s-t-a-g-e-context="vSTAGEContext"
            :vform-config="config"
            :video-config="videoConfig"
            :organization-id="organizationId"
            :teams="targetTeams"
            @applyForAllPanels="applyForAllPanels"
            @fullyLoaded="$emit('fullyLoaded')"
            @goToProject="(conf) => { this.$emit('goToProject', conf);}"
            @goToProjectSlide="(projectId, slideId) => {this.$emit('goToProject', {projectId, slideId});}"
            @kioskMode="(val) => {$emit('kioskMode', val);}"
            @leave="$emit('leave')"
            @reload="$emit('reload'); forceReload()"
            @removeBlock="removeBlock"
            @repeatSlide="(slideUuid) => {$emit('repeatSlide', slideUuid);}"
            @selectSlide="selectSlide"
            @setActiveColumn="(col) => {setActiveColumm(col)}"
            @setLanguage="addTranslation"
            @slideOut="$emit('slideOut')"
            @stepMounted="addContextMenu()"
            @setProjectName="(name) => {projectName = name;}"
            @exportToPDF="exportToPDF"
        />
        <slides-carousel
            v-if="
              !presentationNotAvailable &&
              !viewerModeOnly &&
              slides &&
              slides.length &&
              config &&
              showSlideShow"
            ref="carousel"
            :loading-thumbs-in-progress="loadingThumbs"
            :selected-slide-uuid="activeSlideUuid"
            :show-transition-button="!!videoConfig"
            :slides="slides"
            :state="state"
            :thumbs="thumbs"
            :steps="config.steps"
            class="bottom-bar1"
            @addStep="addStep"
            @addTransition="addTransition"
            @cleanUpGhostSlides="cleanUpGhostSlide"
            @deleteStep="deleteStep"
            @selectSlide="selectSlide"
            @setStepNumbers="setStepNumbers"
            @setDirtyFlag="setDirtyFlag"
        />
      </div>
      <popup
          v-if="showExportSettings"
          :elevated-z-index="10005"
          @close="showExportSettings = false"
      >
        <div slot="popupMainContent">
          <v-form-export
              ref="exportpopup"
              :log-id="config.logID"
              :organizationId="organizationId"
              @hideExportSettings="showExportSettings = false"
              @exportToPDF="exportToPDF"
              @exportToWord="exportToWord"
              @startPDFReport="startPDFReport"
          />
        </div>
      </popup>
      <popup
          v-if="showNewerVersionPopup"
          @close="showNewerVersionPopup = false">
        <div
            slot="popupMainContent"
            class="mt-5 col row d-flex flex-wrap"
        >
         {{ $t('newerVersion') }} There is a newer version online, do you want to reload the form?
        </div>
      </popup>
      <div v-if="!viewerModeOnly" class="col-3 vform-container">
        <div v-if="config.steps && !config.version" class="form-error">
          {{ $t("vform.oldVersionMessage") }}
        </div>
        <popup
            v-if="showDeletePrompt"
            @close="finishDelete">
          <delete-prompt
              slot="popupMainContent"
              @abort="finishDelete"
              @confirm="confirm"
          />
          <div
              v-if="toBeDeletedType !== 'Step'"
              slot="popupMainContent"
              class="mt-5 col row d-flex flex-wrap"
          >
            <div class="d-flex flex-wrap">
              <label
                  :for="'chkDoNoShow'"
                  class="container vform-editor-body"
              >{{ $t("vform.doNotShowDeleteElementConfirmation") }}
                <input
                    :id="'chkDoNoShow'"
                    v-model="doNotShowDeleteConfirm"
                    type="checkbox"
                    @change="showDeleteConfirmMessage"
                >
                <span class="checkmark"/>
              </label>
            </div>
          </div>
        </popup>
        <popup
            v-if="showSaveItMessage"
            @close="showSaveItMessage = ''">
          <div
              slot="popupMainContent"
              class="mt-5 col row d-flex flex-wrap"
          >
            {{ showSaveItMessage }}
            <div class="w-100" />
            <div @click="saveContent" class="settings-button d-inline-block">{{ $t('save') }}</div>
          </div>
        </popup>
        <popup
            v-if="showLogCorrector"
            @close="showLogCorrector = false">
          <div
              slot="popupMainContent"
              class="mt-5 col row d-flex flex-wrap"
          >
            <log-corrector
                v-if="activeStepObject"
                :log-id="config.logID"
                :active-step-object="activeStepObject"
                :globals="config.global"
            />
          </div>
        </popup>
        <div id="flexer-panel" :class="['flexer', panelDocked ? 'panel-docked' : 'panel-undocked']">
          <div :class="['undocker', !panelDocked ? 'active' : '']" @click="panelDocked = !panelDocked">
            <icon :type="panelDocked ? 'angle-left' : 'angle-right'"/>
          </div>
          <div v-if="hamburgerActive" class="hamburger" @click="hamburgerActive = !hamburgerActive">
            <div :class="['hamburger-inner', hamburgerActive ? 'active' : 'inactive']">
              <div/>
              <div/>
              <div/>
            </div>
          </div>
          <div :class="['vform-inline-menu', hamburgerActive ? 'active' : 'inactive']">
            <!--add some content here-->
          </div>
          <div :class="['gear', gearActive ? 'active' : 'inactive']" @click="gearActive = !gearActive">
            <icon type="cog"/>
          </div>
          <div :class="['vform-inline-menu', gearActive ? 'active' : 'inactive', hideGear ? 'superIndex' : '']">
            <v-form-global-settings
                v-if="config"
                :cc-light-context="ccLightContext"
                :form="config"
                :language="language"
                :organization-id="organizationId"
                :project-id="projectId"
                :v-s-t-a-g-e-context="vSTAGEContext"
                :version="config ? config.version : null"
                @addNextButtonToAllSteps="addNextButtonToAllSteps"
                @convertToFile="convertToFile"
                @innerPopupActive="(val) => { this.hideGear = val;}"
                @loadFont="loadFontsLocal"
                @logoChanged="() => { this.$refs.slideShowDisplay.setLogo(); this.hideGear = false; } "
                @setLanguage="(lang) => {addTranslation(lang);}"
                @setScreenOrientation="(orientation) => {this.screenOrientation = orientation;}"
                @setScreenSize="(size, customSize) => {this.screenSize = size; this.customScreenSize = customSize;}"
                @themeChanged="() => { this.applyFormTheme(); }"
            />
          </div>
          <div v-if="!panelDocked" :class="['d-flex drag-grip-global-settings tab-container pl-1', 'pt-1 pr-2']">
            <div>
              <icon size="1.2" type="grip-dots-vertical"/>
            </div>
          </div>
          <div :class="['d-flex tab-container pl-1 pt-1']">
            <div
                :class="['mr-1 clickable panel-tab vform-editor-button-text p-1 pl-2 pr-2',activeColumn === 'blocks' ? 'active-column' : '',]"
                @click="showBlocks()">
              {{ $t("vform.blocks") }}
            </div>
            <div
                :class="['clickable panel-tab vform-editor-button-text p-1  pl-2 pr-2', activeColumn === 'properties' ? 'active-column' : '',]"
                @click="showProperties()">
              {{ $t("vform.properties") }}
            </div>
            <div
                :class="['clickable panel-tab vform-editor-button-text p-1  pl-2 pr-2',activeColumn === 'hotspots' ? 'active-column' : '',]"
                @click="showHotspots()">
              {{ $t("vform.hotspots") }}
            </div>
            <div
                v-if="$store.getters.isSuperAdmin && false"
                :class="['clickable panel-tab vform-editor-button-text p-1  pl-2 pr-2',activeColumn === 'learning-dots' ? 'active-column' : '',]"
                @click="showLearningDots()"
            >
              {{ $t("vform.learningDots") }}
            </div>
            <div
                :class="['clickable panel-tab vform-editor-button-text p-1 pl-2 pr-2 step-properties',activeColumn === 'step-properties' ? 'active-column' : '',]"
                @click="showStepProperties()"
            >
              {{ $t("vform.stepProperties") }}
            </div>
          </div>

          <template v-if="activeColumn === 'blocks'">
            <div class="fancy-scrollbar p-2 pr-1 scrollbar-container">
              <block-panel
                  :hotspot-popup-allowed-element-types="hotspotPopupAllowedElementTypes"
                  :state="state"
              />
            </div>
          </template>
          <template v-if="activeColumn === 'properties'">
            <!--STEP CONTAINER SORTED BY ASSOCIATED SLIDES-->
            <div class="fancy-scrollbar p-2 pr-1 scrollbar-container">
              <properties-panel
                  :key="forceReRenderKey + 20000"
                  :active-slide-uuid="activeSlideUuid"
                  :active-step-object="activeStepObject"
                  :config="config"
                  :current-lang="language"
                  :organization-id="organizationId"
                  :project-id="projectId"
                  :slides="slides"
                  :state="state"
                  :target-teams="targetTeams"
                  :thumbs="thumbs"
                  :v-s-t-a-g-e-context="vSTAGEContext"
                  :active-element="state.activeElement"
                  :form-id="formId"
              />
            </div>
          </template>
          <template v-if="activeColumn === 'hotspots'">
            <div class="fancy-scrollbar p-2 pr-1 scrollbar-container">
              <hotspots-panel
                  :key="forceReRenderKey + 20001"
                  :active-slide-uuid="activeSlideUuid"
                  :active-step="activeStepObject ? activeStepObject.uuid : null"
                  :config="config"
                  :language="language"
                  :organization-id="organizationId"
                  :project-id="projectId"
                  :slides="slides"
                  :state="state"
                  :target-teams="targetTeams"
                  :thumbs="thumbs"
                  :v-s-t-a-g-e-context="vSTAGEContext"
                  @setSlideInfo="setSlideInfo"
              />
            </div>
          </template>
          <!--<template v-if="activeColumn === 'learning-dots'">
            <div class="fancy-scrollbar p-2 pr-1 scrollbar-container">
              <learning-dots-panel
                  :key="forceReRenderKey + 5001"
                  :active-slide-uuid="activeSlideUuid"
                  :active-step="activeStepObject ? activeStepObject.uuid : null"
                  :config="config"
                  :language="language"
                  :organization-id="organizationId"
                  :project-id="projectId"
                  :slides="slides"
                  :state="state"
                  :target-teams="targetTeams"
                  :thumbs="thumbs"
                  :vSTAGEContext="vSTAGEContext"
              />
            </div>
          </template>-->
          <template v-if="activeColumn === 'step-properties'">
            <div class="fancy-scrollbar p-2 pr-1 scrollbar-container">
              <step-settings-panel
                  v-if="config"
                  :steps="config.steps"
                  :global="config.global"
                  :active-step-object="activeStepObject"
                  :config="config"
                  :slides="slides"
                  :state="state"
                  :key="settingsReRenderKey"
                  @applyForAll="applyForAllPanels"
                  @globalPanelAdded="(panel) => {addAllStepsToGlobalPanel(panel); addContextMenu()}"
                  @addAllStepsToGlobalPanel="addAllStepsToGlobalPanel"
                  @removeBlock="removeBlock"
                  @panelAdded="addContextMenu()"
              />
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import {vFormControls, placeholders, vformLoadingModes, projectLoadingModes, vFormRealms} from "@/enum";
import {getWindowAppLoadingClasses} from "@/utils";
import Popup from "@/components/Popup";
import DeletePrompt from "@/components/forms/DeletePrompt";
import {v4 as uuidv4} from "uuid";
import SlideShowDisplay from "@/components/vForm/viewer/SlideShowDisplay";
import SlidesCarousel from "@/components/vForm/viewer/SlidesCarousel";
import VformDraggableDroppableMixin from "@/components/mixins/VformDraggableDroppableMixin.js.vue";
import VformInfoDotDraggableDroppableMixin from "@/components/mixins/VformInfoDotDraggableDroppableMixin.js.vue";
import vformSidePanelDraggableDroppableMixinJs from "../mixins/VformSidePanelDraggableDroppableMixin.js";
import StepSettingsPanel from "./StepSettingsPanel";
import BlockPanel from "./BlockPanel";
import PropertiesPanel from "./PropertiesPanel";
import LoadingSpinner from "@/components/LoadingSpinner";
import PanelContextMenu from "./PanelContextMenu";
import vFormGlobalSettings from "./vFormGlobalSettings";
import ColorContrastMixinJs from "@/components/mixins/ColorContrastMixin.js";
import HotspotsPanel from "@/components/vForm/HotspotsPanel";
//import LearningDotsPanel from "@/components/vForm/LearningDotsPanel";
import DateMixinJs from "@/components/mixins/DateMixin.js.vue";
import MetaSetsMixin from "@/components/mixins/MetaSetsMixin.js";
import vFormAndProjectMixin from "./vFormAndProjectMixin.js.vue";
import TransitionEditor from "./viewer/TransitionEditor";
import isUUID from "is-uuid";
import {fontCuts, SpecialUuids} from "@/enum";
import listenerMixinJs from "../mixins/listenerMixin.js";
import toPDFMixinJs from "./toPDFMixin.js";
import toWordMixinJs from "./mixins/toWordMixin.js.vue";
import LogCorrector from "./LogCorrector.vue";
import FormTreeTraverserMixinJs from "@/components/nodeEditors/FormTreeTraverserMixin.js.vue";
import vFormExport from "@/components/vFormExport.vue";
const nextButton = {
      formElementTypeString: vFormControls.BUTTON,
      formElementType: vFormControls.BUTTON,
      showAsTiles: false,
      uuid: uuidv4(),
      bottomDropZone: true,
      alignment: "right",
      label: {
        dix: {
          Unknown: "",
        },
        imageDix: {
          Unknown: "",
        },
      },
      expanded: true,
};

export default {
  name: "VFormEditor",
  components: {
    HotspotsPanel,
    //LearningDotsPanel,
    Icon,
    Popup,
    DeletePrompt,
    SlideShowDisplay,
    SlidesCarousel,
    StepSettingsPanel,
    BlockPanel,
    PropertiesPanel,
    LoadingSpinner,
    PanelContextMenu,
    vFormGlobalSettings,
    TransitionEditor,
    LogCorrector,
    vFormExport
  },
  mixins: [
    FormTreeTraverserMixinJs,
    VformDraggableDroppableMixin,
    vformSidePanelDraggableDroppableMixinJs,
    ColorContrastMixinJs,
    VformInfoDotDraggableDroppableMixin,
    MetaSetsMixin,
    vFormAndProjectMixin,
    listenerMixinJs,
    toPDFMixinJs,
    FormTreeTraverserMixinJs,
    DateMixinJs,
    toWordMixinJs,
  ],
  props: {
    offlineMode: {type: Boolean, default: false},
    /**
     * Id of the project connected to the vform
     * */
    projectId: {type: String, default: ""},
    /**
     * Id of the form asset
     * */
    remoteFormId: {type: String, default: ""},
    /**
     * Id of the organization to which the vform belongs
     * (vform and project could technically be on different orgs, but we
     * do not allow this)
     * */
    organizationId: {type: String, required: true},
    /**
     * The name of the form asset
     * */
    assetName: {type: String, required: true},
    /**
     * The teams of the form
     * */
    targetTeams: {type: Array, default: null},
    hideStepsAndPreview: {type: Boolean, default: false},
    hideSlideImages: {type: Boolean, default: false},
    appConfig: {
      type: Object, required: false, default: () => {
        return {}
      }
    },
    /**
     * Whether to display only the viewer or also the editor items
     * @values:
     *  -view
     *  - edit
     * */
    editorMode: {type: String, default: "edit"},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    initialSlideId: {type: String, default: null},
    initialStepId: {type: String, default: null},
    disableLinks: {type: Boolean, default: false},
    vSTAGEContext: {type: Boolean, default: false},
    ccLightContext: {type: Boolean, default: false},
    sessionConfig: {type: Object, default: () => {return {}}}
  },
  data() {
    return {
      formId: null,
      showExportSettings: false,
      projectName: "",
      showSaveItMessage: "",
      showLogCorrector: false,
      settingsReRenderKey: 0,
      projectLoadingMode: null,
      stepLoadingMode: null,

      /**
       * For using videos instead of thumbnails
       * */
      videoConfigError: "",
      videoConfig: null,

      /**
       * The different possible font styles
       * */
      fontCuts: fontCuts,
      scaling: false,
      formLoadingErrorCounter: 0,

      presentationNotAvailable: false,

      /**
       * Editor state variables
       * */
      activeColumn: "blocks",
      loadedConfig: false,
      collapseAll: false,
      activeStep: "", //step uuid
      activeSlideUuid: null,
      editingStepId: null,
      showDeletePrompt: false,
      doNotShowDeleteConfirm: false,
      toBeDeleted: null,
      toBeDeletedType: null,
      language: "Unknown",
      elementsCollapsed: false,
      panelDocked: true,
      transitionEditMode: false,

      forceReRenderKey: 0,
      forceReRenderTransitionEditor: 0,

      /**
       *  Content vars from json files
       * */
      config: {}, // the vform config
      slides: null, // the slides array from the vSTAGE project config

      /**
       * Thumbnail loading
       * */
      thumbs: {}, //
      loadingThumbs: true,

      /**
       * Font loading
       * */
      fontUrls: [],

      /**
       * from enum.js
       * */
      vFormControls: vFormControls,
      placeholders: placeholders,

      /**
       * Screen simulator
       * */
      screenSize: 'NONE',
      customScreenSize: {},
      screenOrientation: 'landscape',

      /**
       * Submenus
       * */
      hamburgerActive: false,
      gearActive: false,
      hideGear: false,

      loadingMessage: "",

      /**
       * Hotspots
       * */
      activeSlideMeta: null,
      slideImageMode: 'cover',
      showNewerVersionPopup: false,

      /**
       * shared state between different vform components (different from vformConfig as we do not want to store it)
       * */
      state: {
        projectConfig: null,
        panelContextReRenderKey: 0,
        // overall
        offlineMode: this.offlineMode,
        dirty: false,

        //active items
        activeElement: "",
        activeColumn: "",
        activeSlideUuid: '',
        panelEditing: false,

        // drag and drop
        dragging: false,
        vFormElementTypeBeingDragged: false,
        draggedElementUuid: "",

        // copy paste
        clipboard: {},

        // step
        activeStep: "",
        nextStep: "",

        // hotspots/dots
        selectedHotspot: '',
        selectedLearningDot: '',
        hotspotPopupVisible: false,
        hotspotTooltip: null,

        // layout
        panelsRerenderKey: 0,
        currentlyEditingPanel: "",

        totalSlides: 0,
        progress: 0,
      },
    };
  },
  computed: {
    /**
     * The current active step object based on the active slide it is linked to
     * */
    activeStepObject() {
      if (this.config.steps && (this.activeSlideUuid || this.activeStep)) {
        return this.getStepForSlide(
            this.slides,
            this.config.steps,
            this.activeSlideUuid,
            this.activeSlideUuid,
            this.activeStep
        );
      }
      return null;
    },
    activeStepIndex() {
      return this.config && this.config.steps && this.activeStepObject ? this.config.steps.findIndex(item => {
        return item.uuid === this.activeStepObject.uuid
      }) : -1;
    },
    // the table the form is logging to (might be non-existent)
    loggingTableName() {
      return this.assetName + "_log";
    },
    viewerModeOnly() {
      return this.editorMode === "view";
    },
    showSlideShow() {
      return !this.presentationNotAvailable &&
          this.language &&
          this.config &&
          this.slides &&
          this.slides.length > 0 && this.loadedConfig
    }
  },
  watch: {
    initialStepId(val) {
      if(val) {
        this.setActiveStep(val);
      }
    },
    remoteFormId(val) {
      if(val) {
        this.formId = val;
      }
    },
    /**
     * When seeing the editor only in view mode, the thumbnails shouldn't load
     * because 50 thumbnails can take a lot of time
     * but when we switch to edit mode and the thumbnails are not loaded,
     * the thumbnails should be loaded now
     * */
    viewerModeOnly(val) {
      if (val) {
        this.setActiveColumm('blocks'); // otherwise the border-top will show up in play mode
      }
      this.addContextMenu();
      // if viewer mode and thumbnails missing:
      if (!val) {
        this.loadThumbnails();
        this.addContextMenu(); // it isn't added in playmode, so add it when switching to edit-mode
      } else if (val) {
        const currentSlide = this.slides.filter((item) => {
          return item.id === this.activeSlideUuid;
        });
        if (currentSlide[0] && currentSlide[0].ghostSlide) {
          const slidesWithoutGhosts = this.slides.filter((item) => {
            return !item.ghostSlide;
          });
          if (slidesWithoutGhosts && slidesWithoutGhosts.length) {
            this.selectSlide(slidesWithoutGhosts[0].id);
          }
        }
      }
      this.setDraggingState();
    },
    "state.activeElement": {
      handler: function () {
        if (this.state.activeElement) {
          this.showElementProperties(this.state.activeElement);
        }
      },
    },
    "state.dirty": {
      handler: function () {
        this.$emit("dirty", this.state.dirty);
        window.vFormDirtyState = this.state.dirty;
      },
    },
    slides() {
      this.loadThumbnails();
    },
    targetTeams() {
      this.addTeamsToLoggingTable();
    },
    assetName() {
      const currentLogTableName = this.$store.getters.getDatasetName(this.config.logID);
      if (currentLogTableName && currentLogTableName !== this.loggingTableName) {
        this.updateLoggingTableName();
      }
    },
    panelDocked(val) {
      if (!val) {
        this.$nextTick(() => {
          let panel = document.getElementById("flexer-panel");
          this.adjustInlineMenuPopup(panel);
        });

        this.enableDragDropSidePanel();
      } else {
        this.disableDragDropSidePanel();
      }
    },
  },
  beforeMount() {
    this.loadingMessage = "initializing editor";
    this.$store.dispatch('clearFormsImageCache');
    this.doNotShowDeleteConfirm = window.localStorage.getItem("vform.doNotShowDeleteConfirm");
    this.initialLoad();
    window.vFormDirtyState = false;

    if (this.offlineMode) {
      this.$emit('stopLoading', "config fully loaded");
    }
    // this starts the vform editor in full screen mode
    //if (this.vSTAGEContext) {
      this.screenSize = 'DEFAULT';
    //}
  },
  mounted() {
    if (this.projectId) {
      this.setDraggingState();
    }
    this.addKeydownListener();
  },
  methods: {
    // this is used by the vSTAGE component
    traversevFormTree() {
      return this.traverseTree(this.config, this.slides);
    },
    forceReload() {
      location.reload();
    },
    finishDelete() {
      this.showDeletePrompt = false;
      this.resetDelete()
    },
    confirm() {
        this.showDeletePrompt = false;
        this['delete' + this.toBeDeletedType](); // e.g. deleteStep()
    },
    addContextMenu() {
      const $this = this;
      setTimeout(() => {
        $this.state.panelContextReRenderKey++;
      }, 100)
    },
    async convertToFile() {
      if (this.config.version && this.config.version < 3) {
        // load file to make sure it worked!!
        // delete content from content field
        this.config.version = 3;
        await this.saveFormNew({
          formId: this.formId,
          content: this.config,
          isFile: true,
        }).then(async () => {
          await this.saveFormNew({
            formId: this.formId,
            content: null,
            isFile: false
          })
        }).then(() => {
          this.initialLoad()
        });
      }
    },
    /**
     * Initial setup
     * */
    async initialLoad() {
      if (!this.formId && !this.offlineMode) {
        this.formId = await this.loadFormInstance(this.projectId)
            .catch(() => {
              this.$emit('error', 404, 'backToOverview')
            })
      }

      // reset previous error messages
      this.presentationNotAvailable = false;

      this.projectLoadingMode = this.offlineMode ? projectLoadingModes.OFFLINE : await this.getProjectLoadingMode(this.projectId);

      this.config = await this.loadFormNew({
        loadingMode: this.offlineMode ? vformLoadingModes.OFFLINE : vformLoadingModes.CONTENT, // todo: when vform moves to project storage, change here to dynamic
        formId: this.formId,
        projectId: this.projectId
      })
      this.$emit('setConfig', this.config);

      this.state.projectConfig = await this.loadProjectConfigFileNew(this.projectId, {loadingMode: this.projectLoadingMode})
          .catch(() => {
            this.presentationNotAvailable = true;
          })
      this.slides = this.state.projectConfig && this.state.projectConfig.slides ? this.state.projectConfig.slides : [{id: "none"}];

      this.$emit('setSlides', this.slides);

      // add progress tracker
      await this.$store.dispatch('setvFormTotalSlides', this.getSlidesWithoutGhosts(this.slides).length);
      await this.$store.dispatch('setvFormTotalSteps', this.config.steps ? this.config.steps.length : 0);
      this.state.progress = 0;

      // only load videoconfig if it is not in vSTAGE
      if (!this.vSTAGEContext) {
        this.videoConfig = await this.loadVideoConfigFileNew({
          formId: this.formId,
          projectId: this.projectId,
          loadingMode: this.offlineMode ? vformLoadingModes.OFFLINE : vformLoadingModes.FILE
        });
      }
      this.setUpConfig();
    },
    getWindowAppLoadingClasses() {
      return getWindowAppLoadingClasses();
    },
    addTransition(slideId) {
      this.transitionEditMode = true;
      const $this = this;
      setTimeout(() => {
        $this.$refs.transitionEditor.addTransition(slideId);
      }, 100)
    },
    reload() {
      this.initialLoad();
    },
    replaceLog(forceCreate = false) {
      this.config.logID = null;
      this.createLoggingTable(forceCreate);
      this.showSaveItMessage = this.$t('NewLogAttachedSaveIt');
    },
    applyForAllPanels(panelIndex, panel) {
      this.config.steps.forEach((step) => {
        let localPanels = step.panels.filter((p) => {
          return !p.isMaster;
        })
        if (localPanels[panelIndex]) {
          let p = localPanels[panelIndex];
          this.$set(p, "backgroundColor", panel.backgroundColor);
          this.$set(p, "bottomBackgroundColor", panel.bottomBackgroundColor);
          this.$set(p, "fontColor", panel.fontColor);
          this.$set(p, "xLeft", panel.xLeft);
          this.$set(p, "y", panel.y);
          this.$set(p, "width", panel.width);
          this.$set(p, "height", panel.height);
          this.$set(p, "autoFit", panel.autoFit);
          this.$set(p, "pinBottom", panel.pinBottom);
          this.$set(p, "hideTopPadding", panel.hideTopPadding ? panel.hideTopPadding : false);
          this.$set(p, "borderRadius", panel.borderRadius ? panel.borderRadius : 0);
          this.$set(p, "version", panel.version);
        }
      });
    },
    addKeydownListener() {
      // this is for hot reloading, otherwise it ads the listener a thousand times...
      if (this.listeners.length) {
        return;
      }
      const $this = this;

      this.addListener(document, "keydown", function (e) {
        const {keyCode, metaKey, ctrlKey} = e;

        //ctrl-S
        if ((window.navigator.platform.match("Mac") ? metaKey : ctrlKey) && keyCode === 83) {
          e.preventDefault();
          // Process the event here
          $this.saveContent();
        }

        if (keyCode === 40) {
          e.preventDefault();
          // Process the event here
          $this.$refs.slideShowDisplay.showHideBottomBar();
        }

        if(keyCode === 39 && $this.editorMode === "view") {
          // arrow right
          if( e.target.nodeName == "INPUT" || e.target.nodeName == "TEXTAREA" ) return;
          $this.$refs.slideShowDisplay.goNextExternal(); // this will call the donext on the step to make sure that validation etc. are triggered correctly
        } else if(keyCode === 37 && $this.editorMode === "view") {
          if( e.target.nodeName == "INPUT" || e.target.nodeName == "TEXTAREA" ) return;
          // arrow left
          $this.$refs.slideShowDisplay.historyBack();
        }

        //delete
        if (keyCode === 46) {
          //check if an input is currently in focus
          if (document.activeElement.nodeName.toLowerCase() != "input" && document.activeElement.nodeName.toLowerCase() != "textarea") {
            e.preventDefault();
            // Process the event here
            if ($this.state.activeElement) {
              var index = $this.activeStepObject.elements.findIndex(
                  (x) => x.uuid === $this.state.activeElement
              );
              if (index >= 0) {
                $this.deleteElement($this.activeStepObject, index);
              }
            }
          }
        }
      }, 'keydown-item')
    },
    addUuidToOptions(elements) {
      for (let j = 0; j < elements.length; j++) {
        let element = elements[j];
        if (element.options) {
          for (let i = 0; i < element.options.length; i++) {
            const {uuid} = element.options[i];
            if (!uuid) {
              element.options[i].uuid = uuidv4();
              this.setDirtyFlag(true)
            }
          }
        }
        elements[j] = element;
      }
      return elements;
    },
    addUuidToElementsLegacy(step) {
      if (step.elements) {
        for (let j = 0; j < step.elements.length; j++) {
          let element = step.elements[j];
          if (!element.uuid) {
            element.uuid = uuidv4();
          }
          this.$set(step.elements, j, element);
        }
        const elements = this.addUuidToOptions(step.elements);
        this.$set(step, "elements", elements);
      }
      return step;
    },
    setDraggingState() {
      this.disableDragDropElements();
      if (!this.viewerModeOnly) {
        switch (this.activeColumn) {
          case "blocks":
          case "properties":
            this.enableDragDropElements();
            break;
          case "step-properties":
            this.enableDragDropPanels(this.draggableTargetPanels, false);
            this.enableDragDropPanels(this.draggableTargetPanelsNoBottom, true);
            break;
          case "hotspots":
          case "learning-dots":
            this.enableDragDropInfoDots();
            break;
          default:
            this.enableDragDropElements();
            break;
        }
      }
    },
    getPanelObject() {
      return {
        uuid: uuidv4(),
        identifierColor: "#" + Math.floor(Math.random() * 16777215).toString(16),
      };
    },
    addNextButtonToAllSteps() {
      this.config.steps.forEach((step) => {
        if (
            step.elements &&
            !step.elements.filter((item) => {
              return (
                  item.formElementTypeString === vFormControls.BUTTON &&
                  item.bottomDropZone === true
              );
            }).length
        ) {
          step.elements.push(JSON.parse(JSON.stringify(nextButton)));
        }
      });
      this.$store.dispatch('createNotification', {
        'text': this.$t('events.addedNextButtons')
      });
    },
    deleteUiElement() {
      if (this.state.activeElement) {
        const idx = this.activeStepObject.elements.findIndex((item) => {
          return item.uuid === this.state.activeElement;
        });

        this.activeStepObject.elements.splice(idx, 1);
        this.state.activeElement = null;
      }
    },
    goToProject(conf) {
      this.$refs.slideShowDisplay.goToProject(conf);
    },
    showElementProperties(element_uuid) {
      this.showProperties();
      const element = this.getCurrentElement(element_uuid);
      if (element) {
        element.expanded = true;
      }
      this.focusPropertiesFirstField(element);
    },

    getCurrentElement(elementUuid) {
      //get from step elements
      let element = this.activeStepObject.elements.find(
          (x) => x.uuid === elementUuid
      );

      if (element) {
        return element;
      }

      //get from hotspot elements
      if (this.state.selectedHotspot && this.state.selectedHotspot.elements) {
        element = this.state.selectedHotspot.elements.find(
            (x) => x.uuid === elementUuid
        );
      } else if (this.config.global && this.config.global.elements) {
        element = this.config.global.elements.find(
            (x) => x.uuid === elementUuid
        );
      }

      return element;
    },
    showBlocks() {
      this.state.activeElement = "";
      this.setActiveColumm("blocks");
    },
    showHotspots() {
      this.state.activeElement = "";
      this.setActiveColumm("hotspots");
      this.state.hotspotPopupVisible = false;
    },
    showLearningDots() {
      this.state.activeElement = "";
      this.setActiveColumm("learning-dots");
    },
    /**
     * Sets the first element active and switches to properties panel
     * */
    showProperties() {
      this.setPropertyElementActive();
      this.setActiveColumm("properties");
    },
    /**
     * When switching to property window, set the first element active
     * */
    setPropertyElementActive() {
      const isStepPanelVisible = !this.activeSlideMeta || !this.activeSlideMeta.hideStepPanel;
      const {activeElement, selectedHotspot} = this.state;
      let elements;
      // case 1: use elements from active step
      if (isStepPanelVisible) {
        elements = this.activeStepObject.elements;
        elements = elements.length ? elements.filter((item) => !item.bottomDropZone) : [];
        // case 2: use els from selected hotspot
      } else if (selectedHotspot) {
        elements = selectedHotspot.elements;
      }
      if (!activeElement && (elements && elements.length > 0)) {
        this.state.activeElement = elements[0].uuid;
      }
    },
    showStepProperties() {
      if (this.activeStepObject) {
        this.setActiveColumm("step-properties");
      }
    },
    setActiveStep(step_uuid) {
      const index = this.config.steps.findIndex(item => {
        return item.uuid === step_uuid
      });
      if(index !== -1) {
        this.activeStep = step_uuid;
        this.state.activeStep = step_uuid;
        this.state.nextStep = this.config.steps[index + 1] ? this.config.steps[index + 1] : this.config.steps[0];
      }
    },
    setActiveColumm(column) {
      this.activeColumn = column;
      this.state.activeColumn = column;
      if (this.activeColumn === "step-properties") {
        this.$set(this.state, "panelEditing", true);
      } else {
        this.$set(this.state, "panelEditing", false);
      }

      this.setDraggingState();
    },
    emitDataSet() {
      if (!this.state.offlineMode) {
        const logId = this.config.logID;
        if (logId) {
          this.$emit("logId", logId);
          this.$store.dispatch("clientLoadDataset", {
            id: logId,
          }).then(() => {
            this.$emit("logId", logId);
          }).catch(() => {
            this.$emit("logId", null);
            // todo: show warning that the dataSet was deleted
            this.config.logID = null;
          });
        }
      }
    },
    /**
     * Returns the slides meta for a slide which contains hotspots and learning dots
     * */
    getSlidesMetaForSlide(targetUuid) {
      const {slidesMeta} = this.config;
      if (slidesMeta) {
        const index = slidesMeta.findIndex((s) => s.uuid === targetUuid);
        if (index !== -1) {
          return slidesMeta[index];
        }
      }
      return null;
    },
    /**
     * This method sets the variables concerning the hotspots
     * */
    setSlideInfo() {
      this.activeSlideMeta = this.getSlidesMetaForSlide(this.activeSlideUuid);
      this.slideImageMode = this.getImageMode(this.activeSlideMeta);
    },
    /**
     * Sets the image to contain or cover based on hotspot thingys
     * */
    getImageMode() {
      /*let slideImageMode = 'cover';
      if (slideMeta) {
        if (slideMeta && ((slideMeta.hotspots && slideMeta.hotspots.length > 0) || (slideMeta.learningDots && slideMeta.learningDots.length > 0))) {
          slideImageMode = this.editorMode ? 'contain' : 'cover';
        }
      }
      return slideImageMode;*/
      return 'cover';
    },
    selectSlide(uuid) {
      this.state.activeElement = null;

      //vformeditor - slidecarousel is affected through prop binding
      this.activeSlideUuid = uuid;
      this.state.activeSlideUuid = uuid;
      // todo: switch to global
      this.$store.dispatch('setvFormActiveSlideUuid', uuid);

      this.setSlideInfo();

      let step = this.getStepForSlide(
          this.slides,
          this.config.steps,
          uuid,
          this.activeSlideUuid,
          this.activeStep
      );
      if (step) {
        if (this.vSTAGEContext && this.activeStep !== step.uuid) {
          // this is for vSTAGE video rendering
          this.$store.dispatch('setccLightStepLoadedState', false);
        }
        this.setActiveStep(step.uuid);
      }

      if (this.activeColumn === "properties") {
        this.showProperties();
      }

      const slide = this.slides.filter((item) => {
        return item.id === uuid;
      })[0];
      if (slide && !slide.ghostSlide) {
        // this is necessary for vSTAGE things in case of cclight
        this.$emit("goToSlide", uuid);
      }

      step = null; // Garbage Collection
    },
    async addTeamsToLoggingTable() {
      if (!this.state.offlineMode) {
        if (this.config.logID && this.targetTeams.length) {
          this.$store.dispatch(`clientAddDataSetResource`, {
            id: this.config.logID,
            params: this.targetTeams.map((item) => {
              return item.id;
            }),
          }).then(() => {
            this.$emit("logId", this.config.logID);
          });
        }
      }
    },
    /**
     * Creates and adds a logging table so the vForm results (data entered into the form) get saved into a table
     * */
    async createLoggingTable(forceCreate = false) {
      const hasLoginComponents = this.hasUserLoginComponents();

      if (forceCreate || (hasLoginComponents && hasLoginComponents.length > 0 && !this.config.logID)) {
        let args = {
          name: this.loggingTableName + this.dateTimeFromUnix(Date.now()),
          type: "tbl",
          organizationId: this.organizationId,
          teams: this.targetTeams ? this.targetTeams : [],
          schemaName: "sfx.event",
          schemaVersion: 2,
        };
        await this.$store.dispatch("createDataset", args)
            .then(async (dataset) => {
              this.config.logID = dataset.id;
              await this.saveLoggingTableToMetaValues(dataset.id);
              this.$emit("logId", this.config.logID);
            });
      }
    },
    async saveLoggingTableToMetaValues(logId) {
      await this.$store.dispatch('clientSaveAssetMetaSetValues', {
        id: this.formId,
        values: [{
          metaFieldId: SpecialUuids.LOG_TABLE_METAFIELD,
          value: logId
        }]
      })
    },

    /**
     * Updates the name of the logging table according to the name of the asset
     * */
    async updateLoggingTableName() {
      let args = {
        id: this.config.logID,
        name: this.loggingTableName,
      };
      await this.$store.dispatch("updateDataset", args);
    },

    /**
     * When the step attached to a ghost slide is removed,
     * this method will remove the ghost slide
     * */
    cleanUpGhostSlide(linkedSlideId, newTargetId = null) {
      const ghostSlideIndex = this.slides.findIndex((item) => {
        return item.id === linkedSlideId;
      });
      if (this.slides[ghostSlideIndex].ghostSlide) {
        if (ghostSlideIndex !== -1) {
          this.slides.splice(ghostSlideIndex, 1);
        }
        if (newTargetId) {
          this.selectSlide(newTargetId);
        } else if (this.activeSlideUuid === linkedSlideId) {
          this.selectSlide(this.slides[0].id);
        }
      }
    },
    /**
     * Removes a step from the config
     * First time it is called, it sets the toBeDeleted to the step.step and shows the confirm dialog
     * Second time it is called it finally deletes the step
     * @params {Integer} - the step.step number (must be unique)
     * */
    deleteStep(stepUuid = null) {
      if (this.toBeDeletedType) {
        const index = this.config.steps.findIndex((item) => {
          return item.uuid === this.toBeDeleted;
        });
        const stepObject = this.config.steps[index];
        this.cleanUpGhostSlide(stepObject.linkedSlide);
        this.config.steps.splice(index, 1);
        this.removeStepFromAllGlobalPanels(stepObject);

        this.resetDelete();

        this.setStepNumbers();
        if (this.$refs.carousel) {
          this.$refs.carousel.forceReRenderKey++;
        }
        this.state.dirty = true;
      } else {
        this.toBeDeleted = stepUuid;
        this.toBeDeletedType = "Step";
        this.showDeletePrompt = true;
      }
    },
    setStepNumbers() {
      // steps are already ordered by slide, so the numbers will be correct
      for(let i = 0; i < this.config.steps.length; i++) {
        this.$set(this.config.steps[i], "step", i + 1);
      }
      if(this.$refs.carousel) {
        this.$refs.carousel.forceReRenderKey++;
      }
    },
    removeBlock(uuid, doNotAskForConfirmation = false) {
      let step = this.config.steps.find((x) => x.uuid === this.activeStep);
      if (step) {
        let elIndex = step.elements.findIndex((e) => {
          return e.uuid === uuid;
        });

        //not found in step elements, then it's an element from hotpoints popup
        if (elIndex < 0) {
          //look in global elements
          let globalIndex = -1;
          if (this.config.global && this.config.global.elements) {
            globalIndex = this.config.global.elements.findIndex((e) => {
              return e.uuid === uuid;
            });
          }

          if (globalIndex >= 0) {
            this.deleteGlobalElement(uuid, doNotAskForConfirmation);
          } else {
            this.deleteElementFromHotpointPopup(uuid);
          }
        } else {
          this.deleteElement(step, elIndex, doNotAskForConfirmation);
        }
      }

      step = null; // Garbage Collection
    },
    /**
     * Removes a element from the step
     * First time it is called, it sets the toBeDeleted to the parent step and the index of the element and shows the confirm dialog
     * Second time it is called it finally deletes the element
     * @params {Object} - step the step object
     * @params {Integer} - the index of the element in the step.elements array
     * */
    deleteElement(step, elIndex, doNotAskForConfirmation = false) {
      if (this.doNotShowDeleteConfirm || doNotAskForConfirmation) {
        this.toBeDeleted = {step, elIndex};
        this.toBeDeletedType = "Element";
        step.elements.splice(elIndex, 1);
        this.resetDelete();
        this.state.dirty = true;
      } else {
        if (this.toBeDeletedType) {
          const {step, elIndex} = this.toBeDeleted;
          step.elements.splice(elIndex, 1);
          this.resetDelete();
          this.state.dirty = true;
        } else {
          this.toBeDeleted = {step, elIndex};
          this.toBeDeletedType = "Element";
          this.showDeletePrompt = true;
        }
      }
    },
    deleteGlobalElement(globalElUuid, doNotAskForConfirmation = false) {
      if (this.doNotShowDeleteConfirm || doNotAskForConfirmation) {
        this.toBeDeleted = {globalElUuid};
        this.toBeDeletedType = "GlobalElement";
        const globalElIndex = this.config.global.elements.findIndex((e) => e.uuid === globalElUuid);
        if (globalElIndex >= 0) {
          this.config.global.elements.splice(globalElIndex, 1);
        }

        this.resetDelete();
        this.state.dirty = true;
      } else {
        if (this.toBeDeletedType) {
          const {globalElUuid} = this.toBeDeleted;
          const globalElIndex = this.config.global.elements.findIndex((e) => e.uuid === globalElUuid);
          if (globalElIndex >= 0) {
            this.config.global.elements.splice(globalElIndex, 1);
          }

          this.resetDelete();
          this.state.dirty = true;
        } else {
          this.toBeDeleted = {globalElUuid};
          this.toBeDeletedType = "GlobalElement";
          this.showDeletePrompt = true;
        }
      }
    },
    deleteElementFromHotpointPopup(uuid) {
      if (this.doNotShowDeleteConfirm) {
        this.toBeDeletedType = "ElementFromHotpointPopup";
        this.toBeDeleted = uuid;
        let elIndex = this.state.selectedHotspot.elements.findIndex((e) => {
          return e.uuid === uuid;
        });

        if (elIndex >= 0) {
          this.state.selectedHotspot.elements.splice(elIndex, 1);
        }

        this.resetDelete();
        this.state.dirty = true;
      } else {
        if (this.toBeDeletedType) {
          const elemUuid = this.toBeDeleted;
          let elIndex = this.state.selectedHotspot.elements.findIndex((e) => {
            return e.uuid === elemUuid;
          });

          if (elIndex >= 0) {
            this.state.selectedHotspot.elements.splice(elIndex, 1);
          }

          this.resetDelete();
          this.state.dirty = true;
        } else {
          this.toBeDeleted = uuid;
          this.toBeDeletedType = "ElementFromHotpointPopup";
          this.showDeletePrompt = true;
        }
      }
    },
    /**
     * After deletion or on abort: reset the vars
     * */
    resetDelete() {
      this.toBeDeleted = null;
      this.toBeDeletedType = null;
    },
    /**
     * Sets the editingStepId to the
     * */
    editStepName(step) {
      if (!step.name) {
        step.name = "no name";
      }
      this.editingStepId = step.step;
    },
    /**
     * @params {Object} - step: the step to modify
     * @params {selectedSlides} - [uuid]: array of slide uuids
     * */
    updateStepSlides(step, selectedSlides) {
      step.linkedSlides = selectedSlides;
    },
    findIndexToInsertStep(slide_uuid) {
      let stepIndex = 0;
      let slidesBefore = this.slides.findIndex((s) => {
        return s.id === slide_uuid;
      });

      for (let index = 0; index < slidesBefore; index++) {
        const slide = this.slides[index];
        if (this.$refs.carousel.getStepIfFirstSlide(slide.id)) {
          stepIndex++;
        }
      }

      return stepIndex;
    },
    /**
     * Add a step to the config.steps array
     * **/
    addStep(slide_uuid) {
      this.state.dirty = true;
      const indexToInsert = this.findIndexToInsertStep(slide_uuid);

      if (!this.config.steps) {
        this.$set(this.config, "steps", []);
        this.setVersionIfNone();
      }

      let step = {
        uuid: uuidv4(),
        elements: [{...JSON.parse(JSON.stringify(nextButton)), currentLang: this.language}],
        name: this.$t("vform.UnnamedStep"),
        width: 35,
        panels: [this.getPanelObject()],
        panelsLeftConverted: true,
        version: 1.1
      };

      if (slide_uuid) {
        step.linkedSlide = slide_uuid;
        step.linkedSlides = [slide_uuid];
      }

      if (indexToInsert < this.config.steps.length) {
        this.config.steps.splice(indexToInsert, 0, step);
      } else {
        this.config.steps.push(step);
      }
      this.addStepToAllGlobalPanels(step);

      this.setStepNumbers();
    },
    /**
     * Adds a step automatically ot all global panels so the global panels will
     * be shown there
     * */
    addStepToAllGlobalPanels(step) {
      if (!this.config.global || !this.config.global.panels) {
        return;
      }
      this.config.global.panels.map(panel => {
        if (!panel.globalVisibleOnSteps.includes(step.uuid)) {
          panel.globalVisibleOnSteps.push(step.uuid);
        }
        return panel;
      })
    },
    removeStepFromAllGlobalPanels(step) {
      if (!this.config.global || !this.config.global.panels) {
        return;
      }
      this.config.global.panels.map(panel => {
        const index = panel.globalVisibleOnSteps.findIndex(item => {
          return item === step.uuid;
        })
        if (index !== -1) {
          panel.globalVisibleOnSteps.splice(index, 1);
        }
        return panel;
      })
    },
    addAllStepsToGlobalPanel(panel) {
      if (this.config.steps) {
        this.config.steps.map(step => {
          if (!panel.globalVisibleOnSteps.includes(step.uuid)) {
            panel.globalVisibleOnSteps.push(step.uuid);
          }
        })
      }
    },
    getCurrentHotspot() {
      const index = this.config.slidesMeta ? this.config.slidesMeta.findIndex(item => {
        return item.uuid === this.activeSlideUuid;
      }) : -1;
      if(index === -1) {
        return;
      }
      const slideMeta = this.config.slidesMeta[index];
      const targetUuid = this.state.selectedHotspot.uuid;
      const hotspotIndex = slideMeta.hotspots.findIndex(item => {
        return item.uuid === targetUuid;
      });
      return slideMeta.hotspots[hotspotIndex];
    },
    getElementsByRealm(realm) {
      if(realm === vFormRealms.GLOBAL) {
        return this.config.global.elements;
      } else if(realm === vFormRealms.STEP) {
        return this.activeStepObject.elements;
      } else if(realm === vFormRealms.HOTSPOT) {
        const hotspot = this.getCurrentHotspot();
        return hotspot.elements;
      }
      return [];
    },
    removeElement(elementUuid, sourcePanelUuid) {
      // remove the original item from its panel
      const sourceP = this.getPanelRealm(sourcePanelUuid);
      let sourceElements = this.getElementsByRealm(sourceP);
      const sourceElIndex = sourceElements.findIndex(item => {return item.uuid === elementUuid});
      let sourceElement = sourceElements[sourceElIndex];
      sourceElement = JSON.parse(JSON.stringify(sourceElement));
      if(!sourceElement) {
        console.log('source element not found')
        return;
      }
      sourceElements.splice(sourceElIndex, 1);
      this.applyElementsTo(sourceElements, sourceP);
      return sourceElement;
    },
    /**
     * This adds a new block (= element) to a panel
     * */
    addElement(sourceElement, targetPanelUuid, config) {
      const {isFirst, isBottom, isLast, previousElement, isNew} = config;

      // find out the targetIndex to place the item to
      const targetP = this.getPanelRealm(targetPanelUuid);
      let targetElements = this.getElementsByRealm(targetP);
      let targetElIndex;
      if(isFirst) {
        targetElIndex = 0;
      } else if(isLast || isBottom) {
        targetElIndex = targetElements.length;
      } else if(previousElement) {
        targetElIndex = targetElements.findIndex(item => {return item.uuid === previousElement});
      }

      // insert the target item
      if(targetElements.length) {
        targetElements.splice(targetElIndex, 0, sourceElement);
      } else {
        targetElements.push(sourceElement);
      }
      this.applyElementsTo(targetElements, targetP);
      if(isNew) {
        this.makeElementActiveThings(sourceElement);
      }
      this.addContextMenu();
    },
    createElement(type) {
      return {
          formElementTypeString: type,
          formElementType: type,
          label: {
            dix: {
              Unknown: "",
            },
            imageDix: {
              Unknown: "",
            },
          },
          expanded: true,
          uuid: uuidv4(),
          panelUuid: "",
        };
    },
    /**
     * @params targetPanelUuid - the target panel the item goes to
     * @params isfirst – whether to add the item at the start of the panel
     * @params isLast – whether to add the item at the bottom of the panel
     * @params previousElement - if an item needs to be added after a specific element
     * @params existingElementUuid - if an item needs to be moved from another panel (then sourcePanelUuid is mandatory)
     * @params sourcePanelUuid - the source panel the item is coming from (mandatory for existingElementUuid)
     * @params addingElementString - if an item is new, its type
     * */
    moveOrAddElementToPanel(targetPanelUuid, config) {
      // we have try catch so the editor doesn't freeze because drag and drop fails for some reason...
      try {
        const {isBottom, existingElementUuid, addingElementString, sourcePanelUuid} = config;

        let sourceElement = existingElementUuid ? this.removeElement(existingElementUuid, sourcePanelUuid) : this.createElement(addingElementString);

        sourceElement.panelUuid = targetPanelUuid;
        sourceElement.bottomDropZone = !!isBottom;

        config.isNew = !existingElementUuid;

        this.addElement(sourceElement, targetPanelUuid, config);

      } catch (e) {
        console.log(e);
      }
    },
    makeElementActiveThings(newElement) {
      this.showProperties();
      this.state.activeElement = newElement.uuid;
      this.showElementProperties(newElement.uuid);
      this.focusPropertiesFirstField(newElement);
      this.addTranslationToElement(newElement, this.language);
    },
    applyElementsTo(elements, realm) {
      if(realm === vFormRealms.GLOBAL) {
        this.$set(this.config.global, "elements", elements);
      } else if(realm === vFormRealms.STEP) {
        const index = this.config.steps.findIndex(item => {
          return item.uuid === this.activeStepObject.uuid;
        })
        this.$set(this.config.steps[index], "elements", elements);
      } else if(realm === vFormRealms.HOTSPOT) {
        const hotspot = this.getCurrentHotspot();
        this.$set(hotspot, "elements", elements);
        //this.state.selectedHotspot = hotspot;
      }
    },
    getPanelRealm(panelUuid) {
      if(panelUuid === 'hotspot') {
        return vFormRealms.HOTSPOT;
      }
      const elIndex = this.activeStepObject.panels.findIndex((x) => x.uuid === panelUuid);
      if(elIndex !== -1) {
        return vFormRealms.STEP;
      }
      const globIndex = this.config.global && this.config.global.panels ? this.config.global.panels.findIndex((x) => x.uuid === panelUuid) : -1;
      if(globIndex !== -1) {
        return vFormRealms.GLOBAL;
      }
      return vFormRealms.STEP;
    },
    /**
     * Add a new element to the step
     * @params value {string} - the enum value of vFormControls
     * @parmas index {integer} - the step object to which to add the element
     * @params dropZone {String} - the selected dropzone (empty if default)
     * @params panelUuid {String} - the selected panel
     * **/
    /*addBlockToStep(value, index, dropZone = "", panelUuid = "", existingEl = null) {
      let globalPanel = false;
      if (this.config.global && this.config.global.panels) {
        globalPanel = this.config.global.panels.find((p) => {
          return p.uuid === panelUuid;
        });
      }

      if (globalPanel) {
        this.addBlockToGlobalPanel(value, index, dropZone, panelUuid, existingEl);
      } else {
        if (!this.state.hotspotPopupVisible) {
          this.state.dirty = true;
          let step = this.activeStepObject;
          if (step) {
            let newElement;
            if(existingEl) {
              newElement = existingEl;
            } else {
              newElement = {
                formElementTypeString: value,
                formElementType: value,
                label: {
                  dix: {
                    Unknown: "",
                  },
                  imageDix: {
                    Unknown: "",
                  },
                },
                expanded: true,
                uuid: uuidv4(),
                panelUuid: panelUuid,
              };
            }

            if (dropZone) {
              newElement[dropZone] = true;
            }
            if (index === undefined || index === step.elements.length) {
              step.elements.push(newElement);
            } else {
              step.elements.splice(index, 0, newElement);
            }

            this.showProperties();
            this.state.activeElement = newElement.uuid;
            this.showElementProperties(newElement.uuid);
            this.focusPropertiesFirstField(newElement);
          }

          this.addTranslation(this.language);
        }
      }
    },*/
    /*addBlockToGlobalPanel(value, index, dropZone = "", panelUuid = "", existingEl = null) {
      if (this.config.global && !this.config.global.elements) {
        this.$set(this.config.global, "elements", []);
      }
      let newElement;
      if(existingEl) {
        newElement = existingEl;
      } else {
        newElement = {
          formElementTypeString: value,
          formElementType: value,
          label: {
            dix: {
              Unknown: "",
            },
            imageDix: {
              Unknown: "",
            },
          },
          expanded: true,
          uuid: uuidv4(),
          panelUuid: panelUuid,
        };
      }
      if (dropZone) {
        newElement[dropZone] = true;
      }
      if (index === undefined || index === this.config.global.elements.length) {
        this.config.global.elements.push(newElement);
      } else {
        this.config.global.elements.splice(index, 0, newElement);
      }

      this.state.activeElement = newElement.uuid;
      this.showElementProperties(newElement.uuid);
      this.focusPropertiesFirstField(newElement);

      this.addTranslationToElement(newElement, this.language);

    },*/
    /*addBlockToHotspotInfoPopup(slideUuid, elementType, hotspotIndex, insertIndex) {
      this.state.dirty = true;
      let slideMeta = this.activeSlideMeta;
      if (slideMeta) {
        let hotspot = slideMeta.hotspots[hotspotIndex];
        if (hotspot) {
          let newElement = {
            formElementTypeString: elementType,
            formElementType: elementType,
            label: {
              dix: {
                Unknown: "",
              },
              imageDix: {
                Unknown: "",
              },
            },
            expanded: true,
            uuid: uuidv4(),
          };

          if (!hotspot.elements) {
            this.$set(hotspot, "elements", []);
          }

          if (insertIndex === undefined || insertIndex === hotspot.elements.length) {
            hotspot.elements.push(newElement);
          } else {
            hotspot.elements.splice(insertIndex, 0, newElement);
          }

          this.showProperties();
          this.state.activeElement = newElement.uuid;
          this.showElementProperties(newElement.uuid);
          this.focusPropertiesFirstField(newElement);
        }
      }

      this.addTranslation(this.language);
    },*/
    focusPropertiesFirstField(element) {
      setTimeout(() => {
        let elementDiv = document.getElementById("elem-" + element.uuid);
        if (elementDiv) {
          const textarea = elementDiv.querySelector("textarea");
          if (textarea) {
            textarea.focus();
          } else {
            let input = elementDiv.querySelector("input");
            if (input) {
              input.focus();
            }
            input = null; // garbage collection
          }
        }
        elementDiv = null; // garbage collection
      }, 100);
    },
    /*async validateThumbsCache() {
      //clear cache if project is different than last one opened
      let clearThumbsCache = false;
      let projectIdOfCachedThumbs = localStorage.getItem("projectIdOfCachedThumbs");
      let cachedThumbsTimestamp = localStorage.getItem("cachedThumbsTimestamp");
      let projectLastModified = "";

      if (!projectIdOfCachedThumbs || (this.projectId && projectIdOfCachedThumbs && projectIdOfCachedThumbs !== this.projectId)) {
        clearThumbsCache = true;
      }

      //check if there are updates to the project .. then clear the thumbs cache
      if (!clearThumbsCache && projectIdOfCachedThumbs && this.projectId && this.projectId === projectIdOfCachedThumbs) {
        this.$store
            .dispatch("clientListProjectParts", {
              id: this.projectId,
            })
            .then(async (data) => {
              if (data[0].lastModified !== cachedThumbsTimestamp) {
                clearThumbsCache = true;
                projectLastModified = data[0].lastModified;
              }
            })
            .catch((e) => {
              console.log(e);
            });
      }

      if (clearThumbsCache) {
        localStorage.setItem("projectIdOfCachedThumbs", this.projectId);
        localStorage.setItem("cachedThumbsTimestamp", projectLastModified);

        //clear last project thumbs
        for (let index = 0; index < 200; index++) {
          let fileName =
              "_slides/" + ("000000000" + index).substr(-3) + "/thumbnail.png";
          localStorage.removeItem(fileName);
        }
      }
    },*/
    /***
     * Loads the thumbnail for each slide
     * and triggers the setUpConfig
     * */
    async loadThumbnails() {
      if(this.viewerModeOnly || (this.thumbs && Object.keys(this.thumbs).length !== 0)) {
        return;
      }
      this.loadingThumbs = true;

      const slidesWithoutGhosts = this.slides.filter(item => {
        return !item.ghostSlide;
      })
      for (let i = 0; i < slidesWithoutGhosts.length; i++) {
        const slide = this.slides[i];
        const imageURL = await this.loadThumbnailNew(this.projectId, slide, i, {
          loadingMode: this.projectLoadingMode,
          isThumbnail: true,
          format: 'url'
        }).catch(() => {
          console.log('could not load thumbnail for slide ' + (slide.id + 1))
        });
        this.$set(this.thumbs, slide.id, imageURL);
      }
      this.loadingThumbs = false;
    },
    setUpBlankvForm() {
      this.config.formSettings = {
        slideTransition: 'fade'
      };
      this.setVersionIfNone();
      this.$set(this.config, "steps", []);
      if (this.slides) {
        const {id} = this.slides[0];
        if (id) {
          this.addStep(id);
        }
      }
    },
    prepareHotpots() {
      if(this.config.slidesMeta) {
        for(let i = 0; i < this.config.slidesMeta.length; i++) {
          let meta = this.config.slidesMeta[i];
          if(meta.hotspots) {
            for(let j = 0; j < meta.hotspots.length; j++) {
              let hotspot = meta.hotspots[j];
              if(!hotspot.uuid) {
                hotspot.uuid = uuidv4();
              }
              meta.hotspots[j] = hotspot;
            }
          }
          this.config.slidesMeta[i] = meta;
        }
      }
    },
    prepareGlobal() {
      if (this.config.global && this.config.global.elements) {
        this.config.global.elements = this.addUuidToOptions(this.config.global.elements)
      }
    },
    prepareSteps() {
      if (!this.config.steps) {
        console.log('cannot prepare steps, no steps given')
        return;
      }

      let stepIndex = 1;
      this.config.steps.forEach((step) => {
        // legacy processing
        step = this.normalizeLinkedSlideToArrayForLegacy(step);
        step = this.addUuidToElementsLegacy(step);
        step = this.normalizeSlideLinkingForLegacy(step);
        step = this.removeOrphanElementsFromStep(step);

        this.addTranslation(this.language);

        if (!step.panels || !step.panels.length) {
          this.$set(step, "panels", [this.getPanelObject()]);
        }

        // if a step has no linked slide at all, add a dummy slide id
        if (!step.linkedSlide) {
          step.linkedSlide = uuidv4();
        }
        const foundSlides = this.slides.filter((slide) => {
          return slide.id === step.linkedSlide;
        });
        if (!foundSlides.length) {
          console.log(step.linkedSlide + " not found - adding ghost slide");
          this.slides.push({
            id: step.linkedSlide,
            ghostSlide: true,
          });
          step.step = 'invalid';
        } else {

          step.step = stepIndex;
          stepIndex = stepIndex + 1;
          if (!step.name || step.name === "") {
            step.name = this.$t("vform.UnnamedStep");
          }
        }

        //adjust panel to left X offset
        if(step.panels) {
          step.panels.forEach((panel) => {
            if (!step.panelsLeftConverted) {
              if (panel.x || panel.x === 0) {
                this.$set(panel, "xLeft", 100 - ((panel.width ? panel.width : 30) + panel.x));
              } else {
                this.$set(panel, "xLeft", null);
              }

              panel.x = null;
              delete panel["x"];
            }
          });
        }
        step.panelsLeftConverted = true;

      });
    },
    removeOrphanElementsFromStep(step) {
      //find orphan elements, no panel
      if(!step.panels) {
        return step;
      }
      const panelIds = step.panels.map(panel => {
        return panel.uuid
      });
      step.elements = step.elements.filter((e) => {
        return panelIds.findIndex((id) => {
          return id === e.panelUuid;
        }) >= 0 || !e.panelUuid
      });
      return step;
    },
    removeOrphanElementsFromGlobal() {
      //global orphan elements
      if (this.config.global && this.config.global.panels) {
        const globalPanelIds = this.config.global.panels.map(panel => {
          return panel.uuid
        });
        this.config.global.elements = this.config.global.elements.filter((e) => {
          return globalPanelIds.findIndex((id) => {
            return id === e.panelUuid;
          }) >= 0
        });
      }
    },
    setInitialStepAndSlide() {
      const initialSlide = this.initialSlideId
          ? this.initialSlideId
          : this.slides[0].id;
      let targetSlide = initialSlide;
      let initialStep = this.config.steps.filter((item) => {
        return item.linkedSlide === initialSlide;
      });
      // external given initial step id forces init on a specific step
      if (this.initialStepId) {
        const tempInitialStep = this.config.steps.filter((item) => {
          return item.uuid === this.initialStepId;
        });
        if (tempInitialStep && tempInitialStep.length) {
          // todo: show short error message that step is not available and redirecting
          initialStep = tempInitialStep;
        }
        targetSlide = initialStep[0].linkedSlide;
      }
      this.selectSlide(targetSlide);
      this.setActiveStep(initialStep && initialStep[0] ? initialStep[0].uuid : this.config.steps[0].uuid);
      this.forceReRenderKey++;
    },
    /**
     * parses and url-decodes the config
     * counts the steps per slide and
     * checks if there is a user identifier present
     * */
    setUpConfig() {
      this.$emit('setLoadingMessage', "setting up config");
      this.loadedConfig = false;

      if (!this.config) {
        return;
      }

      if (!this.config.steps || !this.config.steps.length) {
        // this is a vform that hasn't been modified yet
        this.setUpBlankvForm();
      }

      /**
       * Preparation phase
       * */
      this.orderStepsBySlide();
      this.$emit('setLoadingMessage', "parsing steps");
      this.prepareSteps();
      this.prepareGlobal();
      this.prepareHotpots();
      this.removeOrphanElementsFromGlobal();

      this.$emit('setLoadingMessage', "normalizing items");

      // only convert if version is not on 2 yet
      if (!this.config.version || this.config.version < 2) {
        // todo: check if those two legacy methods are still necessary. Do we really have that old forms anymore?
        this.convertOldVformElements();
        this.convertElementsWithImage();
      }

      if (!this.activeStep) {
        this.setInitialStepAndSlide();
      } else {
        console.log("already active step given, did not set step active");
      }

      if (!this.config.global) {
        this.$set(this.config, "global", {});
      }
      if (!this.config.global.panels) {
        this.$set(this.config.global, "panels", []);
      }
      if (!this.config.global.elements) {
        this.$set(this.config.global, "elements", []);
      }

      this.addProjectSwitchCurrentProjectFlag();

      this.$emit('setLoadingMessage', "Almost there");
      this.addTranslation(this.language);
      this.emitDataSet();

      this.$emit('setLoadingMessage', "config loaded");
      this.loadedConfig = true;

      this.applyFormTheme();
      this.loadFontsLocal();
      this.$emit('stopLoading', "config fully loaded");
      if(this.state.dirty) {
        this.showSaveItMessage = "We corrected some data for you, you should save the form now";
      }
    },
    /***
     * Order steps by slide
     * otherwise if slide order changes, the numbering is out of order
     * */
    orderStepsBySlide() {
      this.config.steps = this.config.steps.sort((x, y) => {
        const xSlide = this.slides.findIndex(item => {
          return item.id === x.linkedSlide
        });
        const ySlide = this.slides.findIndex(item => {
          return item.id === y.linkedSlide
        });
        if (xSlide < ySlide) {
          return -1;
        }
        if (xSlide > ySlide) {
          return 1;
        }
        return 0;
      })
    },
    convertOldVformElements() {
      this.config.steps.forEach((step) => {
        if (step.elements) {
          for (let index = 0; index < step.elements.length; index++) {
            let element = step.elements[index];
            this.convertCheckboxesToRadiobutton(element);
            this.convertShortAnswerWithIsId(element);
            this.convertProjectSwitchToButton(element);
            this.convertSlideControlToButton(element, index, step.elements);
          }
        }
      });
    },
    convertCheckboxesToRadiobutton(element) {
      if (element.formElementType === vFormControls.CHECKBOXES2 || element.formElementTypeString === vFormControls.CHECKBOXES2) {
        element.formElementType = vFormControls.RADIOBUTTONS;
        element.formElementTypeString = vFormControls.RADIOBUTTONS;
      }
    },
    convertShortAnswerWithIsId(element) {
      if ((element.formElementType === vFormControls.SHORT_ANSWER || element.formElementTypeString === vFormControls.SHORT_ANSWER) && element.isID) {
        //create login element
        element.formElementTypeString = vFormControls.LOGIN;
        element.formElementType = vFormControls.LOGIN;

        let buttonElement = {
          currentLang: this.language,
          formElementTypeString: this.vFormControls["BUTTON"],
          formElementType: this.vFormControls["BUTTON"],
          label: {
            dix: {
              Unknown: "",
            },
            imageDix: {
              Unknown: "",
            },
          },
          expanded: true,
          uuid: uuidv4(),
        };

        if (!element.loginButton) {
          this.$set(element, "loginButton", buttonElement);
        }

        if (!element.fields) {
          let field = {
            label: element.label,
            isID: true,
          };

          this.$set(element, "fields", [field]);
        }
      }
    },
    convertProjectSwitchToButton(element) {
      if (element.formElementType === vFormControls.PROJECT_SWITCH || element.formElementTypeString === vFormControls.PROJECT_SWITCH) {
        element.formElementTypeString = vFormControls.BUTTON;
        element.formElementType = vFormControls.BUTTON;
        element.selectedAction = "GoToStep";
        element.alignment = "justify";
      }
    },
    convertSlideControlToButton(element, elementIndex, elements) {
      if (element.formElementType === vFormControls.SLIDE_CONTROL || element.formElementTypeString === vFormControls.SLIDE_CONTROL) {
        //make slide control a button, just in case there are no options added in the old vform
        element.formElementTypeString = vFormControls.BUTTON;
        element.formElementType = vFormControls.BUTTON;
        element.uuid = uuidv4();

        if (element.options.length > 0) {
          //for each old vform options, create a new button element in the new vform
          for (let optionIndex = 0; optionIndex < element.options.length; optionIndex++) {
            const option = element.options[optionIndex];
            let newButtonElement = {
              formElementTypeString: vFormControls.BUTTON,
              formElementType: vFormControls.BUTTON,
              label: option.text,
              targetSlide: option.targetSlide
                  ? option.targetSlide.uuid
                  : "",
              expanded: true,
              alignment: "justify",
              showastiles: element.showastiles, //html binding uses lowercase .. this line needs to remain like this. / or update case and migrate old vforms
              uuid: uuidv4(),
            };

            newButtonElement.selectedAction = "GoToSlide";
            elements.splice(
                elementIndex + optionIndex + 1,
                0,
                newButtonElement
            );
          }

          elements.splice(elementIndex, 1);
        }
      }
    },
    convertElementsWithImage() {
      let elementTypesToSkip = [
        this.vFormControls.IMAGE,
        this.vFormControls.BUTTON,
        this.vFormControls.SLIDE_CONTROL,
        this.vFormControls.PROJECT_SWITCH,
      ];
      this.config.steps.forEach((step) => {
        if (step.elements) {
          for (let index = 0; index < step.elements.length; index++) {
            let element = step.elements[index];
            if (
                element.label.imageDix &&
                !elementTypesToSkip.find(
                    (x) => x === element.formElementTypeString
                )
            ) {
              if (element.label.imageDix["Unknown"]) {
                const imageElement = {
                  formElementTypeString: "Image",
                  formElementType: "Image",
                  label: {
                    dix: {
                      Unknown: "",
                    },
                    imageDix: element.label.imageDix,
                  },
                  expanded: true,
                  uuid: uuidv4(),
                };

                //add image element
                step.elements.splice(index, 0, imageElement);
                index++;

                //remove image dix from original
                element.label.imageDix = {Unknown: ""};
              }
            }
          }
        }
      });
    },
    setVersionIfNone() {
      if (!this.config.version) {
        this.config.version = 2;
      }
    },
    /**
     * This if for legacy forms which have the linkedSlide only on the elements
     * check all elements and if one slide is given, set it to the parent as well
     * */
    normalizeSlideLinkingForLegacy(step) {
      if (step.linkedSlide) {
        return step;
      }
      let linkedSlide = "";
      if (step.elements && step.elements.length) {
        step.elements.map((el) => {
          if (el.linkedSlide) {
            linkedSlide = el.linkedSlide.uuid;
          }
        });
      }
      if (linkedSlide && linkedSlide.uuid) {
        step.linkedSlide = linkedSlide.uuid;
      }
      return step;
    },
    normalizeLinkedSlideToArrayForLegacy(step) {
      if (!step.uuid) {
        step.uuid = uuidv4();
      }

      if (!step.linkedSlides) {
        step.linkedSlides = [];
      }

      if (step.linkedSlides.length === 0 && step.linkedSlide) {
        step.linkedSlides = [step.linkedSlide];
      }
      return step;
    },
    addProjectSwitchCurrentProjectFlag() {
      this.config.steps.forEach((step) => {
        if (step.elements && step.elements.length) {
          step.elements.forEach((element) => {
            if (element.linkedProjectId && element.linkedProjectId === this.projectId && !element.isCurrentProject) {
              this.$set(element, "isCurrentProject", true);
            }
          });
        }
      });
    },
    /**
     * Checks if a unque user identifier is available
     * because the vForm only gets logged if an identifier is available
     * */
    hasUserLoginComponents() {
      if (this.config && this.config.steps) {
        return this.config.steps.filter((step) => {
          return !!step.elements.filter((el) => {
            return el.formElementType === this.vFormControls["LOGIN"];
          }).length;
        });
      }
      return false;
    },
    /**
     * Save the config to the content field
     * */
    async saveContent(skipLoggingTable = false, content = null) {
      if (content) {
        this.config = content;
      }
      this.setVersionIfNone();
      const {version} = this.config;

      this.removeEmptyTranslations();
      if (!skipLoggingTable) {
        await this.createLoggingTable();
      }
      await this.saveFormNew({
        formId: this.formId,
        content: this.config,
        isFile: version && parseInt(version) > 2,
      })
      this.setDirtyFlag(false);
      await this.$store.dispatch("createNotification", {
        text: this.$t("events.formUpdated"),
      });
      this.$emit('setConfig', this.config)
      this.addTranslation(this.language);
      this.showSaveItMessage = "";
    },
    setDirtyFlag(val) {
      this.state.dirty = val;
    },
    removeEmptyTranslations() {
      let translatedLanguages = ["Unknown"];
      this.config.steps.forEach((step) => {
        step.elements.forEach((element) => {
          delete element["currentLang"]; //remove currentLang to reduce size of JSON. property is only releveant for vFormEditor UI
          Object.keys(element.label.dix).forEach((key) => {
            if (element.label.dix[key] && key !== "Unknown") {
              if (translatedLanguages && !translatedLanguages.find((x) => {
                return x === key;
              })) {
                translatedLanguages.push(key);
              }
            }

            if (!element.label.dix[key] && key !== "Unknown") {
              delete element.label.dix[key];
            }
          });
          Object.keys(element.label.imageDix).forEach((key) => {
            if (element.label.imageDix[key] && key !== "Unknown") {
              if (translatedLanguages && !translatedLanguages.find((x) => {
                return x === key;
              })) {
                translatedLanguages.push(key);
              }
            }

            if (!element.label.imageDix[key] && key !== "Unknown") {
              delete element.label.imageDix[key];
            }
          });

          //options
          if (element.options) {
            element.options.forEach((option) => {
              Object.keys(option.text.dix).forEach((key) => {
                if (option.text.dix[key] && key !== "Unknown") {
                  if (translatedLanguages && !translatedLanguages.find((x) => {
                    return x === key;
                  })) {
                    translatedLanguages.push(key);
                  }
                }

                if (!option.text.dix[key] && key !== "Unknown") {
                  delete option.text.dix[key];
                }
              });
              Object.keys(option.text.imageDix).forEach((key) => {
                if (option.text.imageDix[key] && key !== "Unknown") {
                  if (translatedLanguages && !translatedLanguages.find((x) => {
                    return x === key;
                  })) {
                    translatedLanguages.push(key);
                  }
                }

                if (!option.text.imageDix[key] && key !== "Unknown") {
                  delete option.text.imageDix[key];
                }
                delete option.userId;
                delete option.isSelected;
              });
            });
          }

          //qr codes
          if (element.codes) {
            element.codes = element.codes.filter((item) => item.assetId);
          }
        });
      });

      this.config.languages = translatedLanguages;
    },
    addTranslation(lang) {
      this.language = lang;
      if (this.config.steps) {
        this.config.steps.forEach((step) => {
          step.elements.forEach((element) => {
            this.addTranslationToElement(element, lang);
          });
        });
        if(this.config.global && this.config.global.elements) {
          this.config.global.elements.forEach((element) => {
            this.addTranslationToElement(element, lang);
          });
        }

        this.forceReRenderKey++;
      }

      //add label dix structure for hotspots
      if (this.config.slidesMeta) {
        this.config.slidesMeta.forEach((slideMeta) => {
          if (slideMeta.hotspots) {
            slideMeta.hotspots.forEach((hotspot) => {
              if (!hotspot.label.dix[lang]) {
                this.$set(hotspot.label.dix, lang, "");
              }
              if (hotspot.elements) {
                hotspot.elements.forEach((element) => {
                  element.currentLang = lang;
                  if (!element.label.dix[lang]) {
                    this.$set(element.label.dix, lang, "");
                  }
                });
              }
            });
          }
        });
      }
    },

    addTranslationToElement(element, lang) {
      element.currentLang = lang;

      //labels
      if (!element.label.dix[lang]) {
        this.$set(element.label.dix, lang, "");
      }

      //url for website
      if (element.formElementTypeString === vFormControls.WEBSITE) {
        if (element.url && !element.url.dix[lang]) {
          this.$set(element.url.dix, lang, "");
        }
      }

      if (!element.label.imageDix[lang]) {
        this.addImgLang(lang, element);
      }

      //options
      if (element.options) {
        element.options.forEach((option) => {
          if (!option.text.imageDix[lang]) {
            this.$set(option.text.imageDix, lang, "");
          }

          if (!option.text.dix[lang]) {
            this.$set(option.text.dix, lang, "");
          }
        });
      }

      //loginButton
      if (element.loginButton) {
        element.loginButton.currentLang = lang;
        if (!element.loginButton.label.dix[lang]) {
          this.$set(element.loginButton.label.dix, lang, "");
        }
      }
    },
    addImgLang(lang, element) {
      this.$set(element.label.imageDix, lang, "");
    },
    showDeleteConfirmMessage() {
      window.localStorage.setItem("vform.doNotShowDeleteConfirm", this.doNotShowDeleteConfirm);
    },
    applyFormTheme() {
      if (this.config.formSettings && this.config.formSettings.themeColor) {
        let styleString = ':root {';
        styleString += '--vform-editor-layout-accent-color: ' + this.config.formSettings.themeColor + ' !important; ';

        //calculate emphasize text color in contrast with the background
        const fontColor = this.getContrast(this.config.formSettings.themeColor);
        const lightenDarkenFactor = this.isBrightColor(this.config.formSettings.themeColor) ? 0.7 : 1.5;
        styleString += '--vform-editor-emphasized-font-color: ' + fontColor + ' !important; ';
        styleString += '--vform-highlight-hover-color: ' + this.lightenDarkenColor(this.config.formSettings.themeColor, lightenDarkenFactor) + ' !important; ';
        styleString += '}';

        let existingFormThemeStyleElement = document.getElementById('vformTheme');
        if (existingFormThemeStyleElement) {
          existingFormThemeStyleElement.remove();
        }
        const style = document.createElement('style');
        style.id = 'vformTheme';
        style.appendChild(document.createTextNode(styleString));
        document.getElementsByTagName('head')[0].appendChild(style);
      } else {
        let existingFormThemeStyleElement = document.getElementById('vformTheme');
        if (existingFormThemeStyleElement) {
          existingFormThemeStyleElement.remove();
        }
        existingFormThemeStyleElement = null;
      }
    },
    async exportToPDF() {
      await this.$store.dispatch('updatePDFGenerating', true)
      await this.toPDF(this.organizationId, this.projectId, this.formId, this.slides, this.config, this.projectLoadingMode);
      await this.$store.dispatch('updatePDFGenerating', false)
    },
    async exportToWord(exportConfig) {
      await this.$store.dispatch('updateWORDGenerating', true)
      await this.toWord(this.organizationId, this.projectId, this.slides, this.config, exportConfig);
      await this.$store.dispatch('updateWORDGenerating', false)
    },
    async startPDFReport(config) {
      await this.$store.dispatch('updatePDFGenerating', true)
      await this.toPDF(this.organizationId, this.projectId, this.formId, this.slides, this.config, this.projectLoadingMode, config);
      await this.$store.dispatch('updatePDFGenerating', false)
    },
    destroyFontUrls() {
      for (let i = 0; i < this.fontUrls.length; i++) {
        try {
          window.URL.revokeObjectURL(this.fontUrls[i])
        } catch {
          // do nothing – url might already have been revoked by the browser
        }
      }
      this.fontUrls = [];
    },
    async loadFontsLocal() {
      this.destroyFontUrls();
      let fontId = null;
      let fontName = "";
      const {formSettings} = this.config;
      if (formSettings && formSettings.font) {
        const {id, name} = formSettings.font;
        fontId = id;
        fontName = name ? name : id;
      }
      if (!fontId) {
        console.log('no font id found, abort loading fonts')
        return;
      }
      if (isUUID.v4(fontId)) {
        console.log('loading font asset for id ' + fontId)
        // is a font asset
        await this.loadAndApplyFonts(this.projectId, fontId, fontName, {
          loadingMode: this.offlineMode ? projectLoadingModes.OFFLINE : projectLoadingModes.CURRENT
        }).catch(e => {
          console.log(e);
          // todo: show error?
        })
      }
    },

  }
};
</script>

<style lang="scss">

</style>

<style lang="scss" scoped>
$side-icons-height: 30px;
body {
  font-family: "Inter", sans-serif;
}

.vform-screen-size-selector {
  z-index: 555;
}

.undocker, .hamburger, .gear {
  position: absolute;
  left: -30px;
  top: 15px;
  padding: 5px;
  z-index: 105;
  width: $side-icons-height;
  height: $side-icons-height;
  background-color: var(--vform-editor-ui-secondary-color);
  cursor: pointer;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

  &:hover, &.active {
    background-color: var(--vform-editor-ui-tertiary-color);
    color: #fff;
  }
}

.gear {
  padding: 0;
  top: 48px;
  position: absolute;
  z-index: 106;
}

.hamburger {
  padding: 0;
  top: 64px;
  position: absolute;
  z-index: 555;

  .hamburger-inner {
    width: $side-icons-height - 10px;
    margin-top: 0;
    height: $side-icons-height;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    position: absolute;

    div {
      width: 100%;
      height: 2px;
      background-color: #fff;
      position: absolute;
      top: 8px;
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }

    div:nth-child(1) {
      top: 14px;
    }

    div:nth-child(2) {
      top: 20px;
    }

    &.active {
      div:nth-child(1) {
        top: 50%;
        transform: rotate(45deg);
      }

      div:nth-child(2) {
        display: none;
      }

      div:nth-child(3) {
        top: 50%;
        transform: rotate(-45deg);
      }
    }
  }
}

.vform-inline-menu {
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  top: 15px;
  transform-origin: top right;
  z-index: 0;
  position: absolute;
  padding: 0;
  border-radius: 4px;

  &.active {
    width: 400px;
    height: 80vh;
    background-color: var(--vform-editor-layout-secondary-color);
    left: -400px;
    z-index: 105;
    padding: 15px;
    padding-top: 20px;
    padding-right: 35px;
    overflow: auto;
  }
}

.form-loading-screen {
  width: 100%;
  height: 100vh;
  background-color: var(--vform-editor-ui-secondary-color);
  top: 0;
  left: 0;
  z-index: 556;
  color: #fff;
  position: relative;

  .spinner-simple {
    display: none;
  }

  &.full-width {
    width: 100vw;
    position: fixed;
  }

  .loading-spinner-widget {
    margin-top: -3rem;
  }

  .loading-message {
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-top: 50px;
  }

  .icon, .loading-spinner-widget, .form-error, .loading-message {
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}

.bottom-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  -webkit-box-shadow: 2px 2px 10px 2px #666;
  box-shadow: 2px 2px 10px 2px #666;
  background-color: var(--vform-bottom-bar-background-color);
  color: var(--vform-bottom-bar-text-color);
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 12px 0 14px 0;
}

.drop-zone {
  height: 35px;
  position: absolute;
  top: -20px;
  z-index: -1;
  width: 100%;
}

.large-drop-zone {
  height: 400px !important;
  position: relative;
  top: -20px;
  z-index: -1;
  width: 100%;
}

.drop-area-outer {
  border: dotted 1px gray;
  border-radius: 4px;
}

.drop-zone-highlight {
  z-index: 50;
}

.vform-element h2 {
  display: inline-block;
  color: var(--vform-editor-ui-tertiary-color);
  padding: 5px 0 5px 0;
  border-bottom: solid 1px var(--vform-editor-ui-secondary-color);
}

.icon-disabled {
  color: #5b5b5b;
  opacity: 0.2;
  cursor: default;
}

.icon-disabled .icon:hover {
  color: #5b5b5b;
  cursor: default;
}

.v-form-label {
  min-height: 150px;
}

.scrollspy-target {
  border: 2px solid red;
}

// TODO ???? why is this ?
.block-container {
  .form-block input,
  textarea,
  span,
  label {
    cursor: move;
    font-size: 10px;
  }
}

/*new style */
.dark-ui {
  background-color: var(--vform-editor-panel-active-background-color);
}

.step-properties {
  margin-left: auto;
  margin-right: 10px;
}

.slider-label {
  font-size: 0.733rem;
}

.drag-grip-global-settings {
  justify-content: flex-end;

  &:hover {
    color: var(--vform-editor-accent-color);
  }
}

</style>
