<template>
  <div></div>
</template>
<script>
import {SpecialUuids, AssetTypes} from "@/enum";
import {v4 as uuidv4} from "uuid";
export default {
  name: "CourseMixin.js",
  data() {
    return {
      courseItems: [],
      courseCats: [],
      openSessions: [],
      assetLoaded: false,
      baseCourseItem: null,
      courseOriginProjectId: null,
      hasCourseMetaSet: false,
      showCatItemsOnlyInternal: []
    }
  },
  methods: {
    setBaseCourseItem(projectId) {
      if(this.courseItems) {
        this.baseCourseItem = this.courseItems.find(item => {
          return item.id === projectId;
        })
        const {cat, parent} = this.baseCourseItem;
        this.courseItems = this.courseItems.filter(item => {
          return (item.cat === cat || !item.cat && !cat) &&  (item.parent === parent || !item.parent && !parent)
        });
        // this makes it only showing the items from the same category but not the other ones
        this.showCatItemsOnlyInternal.push(cat);
      }
    },
    getCourseTreeForCat(cat) {
      let tree = [],
          mappedArr = {}
      const allItems = this.courseItems.filter(item => {
        return (cat && item.cat === cat) || (!cat && !item.cat);
      })
      for(let i = 0; i < allItems.length; i++) {
        const item = allItems[i];
        const {id} = item;
        mappedArr[id] = item;
        mappedArr[id].children = [];
      }
      for(let id in mappedArr) {
        const mappedElem = mappedArr[id];
        const parentId = mappedElem.parent;
        if (parentId && mappedArr[parentId]) {
          mappedArr[parentId].children.push(mappedElem);
        } else {
          mappedElem.parent = null;
          tree.push(mappedElem);
        }
      }
      return tree;
    },
    checkHasCourseMetaSet(metaSets) {
      return metaSets && metaSets.length && metaSets.filter(item => {
        return item.id === SpecialUuids.COURSE_METASET;
      }).length > 0;
    },
    async attachCourseMetaSetTovForm(id) {
      return await this.$store.dispatch('clientAddAssetMetaSet', {
          id,
          args: [SpecialUuids.COURSE_METASET]
      });
    },
    async removeCourseMetaSetFromvForm(id) {
      return await this.$store.dispatch('clientRemoveAssetMetaSet', {
        id,
        args: [SpecialUuids.COURSE_METASET]
      });
    },
    async loadvFormMetaValuesForCourse(id) {
      console.log('loading course like this:')
      console.log({
        id,
        include: ['metaSets', 'metaValues', 'instances'],
        fields: ['id', 'name', 'organizationId']
      });
      const asset = await this.$store.dispatch('loadForm', {
        id,
        include: ['metaSets', 'metaValues', 'instances'],
        fields: ['id', 'name', 'organizationId']
      }).catch(e => {
        console.log(e);
        console.log('could not load course asset, throw an error?')
      });
      if(asset) {
        const {metaValues, instances, metaSets} = asset;
        this.hasCourseMetaSet = this.checkHasCourseMetaSet(metaSets);
        if(this.hasCourseMetaSet) {
          if(metaValues.length) {
            this.setCourseCats(metaValues);
            this.setCourseItems(metaValues)
            this.setSessionTable(metaValues);
          }
          if(instances.length) {
            this.setOriginProjectId(instances);
          }
        }
        this.assetLoaded = true;
      }
      return asset;
    },
    setOriginProjectId(instances) {
      const instance = instances.find(item => {
        return item.type === AssetTypes.FORM;
      });
      if(instance) {
        this.courseOriginProjectId = instance.projectId;
      }
    },
    setSessionTable(metaValues) {
      const sessionTable = metaValues.find(item => {
        return item.metaFieldId === SpecialUuids.COURSE_DATASET_METAFIELD;
      });
      console.log('sessionTable:')
      console.log(sessionTable)
      if(sessionTable) {
        const {value} = sessionTable;
        console.log('got session table:')
        console.log(value)
        this.$store.dispatch('setvFormSessionTable', value);
        this.localSessionTable = value;
      }
    },
    async loadAvailableSessions() {
      if(!this.$store.getters.getvFormSessionTable) {
        console.error('loadAvailableSessions failed, no session table available')
        throw new Error('!!!!!!!!!!!!!!! loadAvailableSessions failed, no session table available');
      }
      this.openSessions = await this.$store.dispatch('getSessionsFromDB', {
        sessionTable: this.$store.getters.getvFormSessionTable
      });
      if(this.openSessions) {
        this.openSessions.sort((a,b) => {
          return b.startTime - a.startTime;
        })
      }
    },
    setCourseCats(metaValues) {
      const courseCats = metaValues.find(item => {
        return item.metaFieldId === SpecialUuids.COURSE_CATS_METAFIELD;
      })
      if(courseCats && courseCats.value) {
        this.courseCats = JSON.parse(courseCats.value);
      }
      // todo: remove after publish! This is legacy
      if(this.courseCats && this.courseCats.length) {
        this.courseCats = this.courseCats.map(item => {
          if(!item.id) {
            item.id = uuidv4();
          }
          return item;
        })
      }
    },
    setCourseItems(metaValues) {
      const courseItems = metaValues.find(item => {
        return item.metaFieldId === SpecialUuids.COURSE_ITEMS_METAFIELD;
      })
      if(courseItems && courseItems.value) {
        this.courseItems = JSON.parse(courseItems.value);
      }
      // todo: remove, legacy
      if(this.courseItems && this.courseItems.length) {
        this.courseItems = this.courseItems.map(item => {
          if(item.cat) {
            console.log('cats')
            console.log(this.courseCats)
            const catIndex = this.courseCats.findIndex(cat => {
              return cat.name === item.cat;
            })
            console.log('catindex for ' + item.cat + '  is: ' + catIndex)
            if(catIndex !== -1) {
              item.cat = this.courseCats[catIndex].id;
            }
          }
          return item;
        })
      }
    },
    removeCourseItem(projectId) {
      const index = this.courseItems.findIndex(item => {
        return item.id === projectId;
      })
      this.courseItems.splice(index, 1);
      this.forceReRenderKey++;
    },
    moveCourseItemUp(projectId) {
      const index = this.courseItems.findIndex(item => {
        return item.id === projectId;
      })
      const tmp = this.courseItems[index-1];
      this.$set(this.courseItems, index-1, this.courseItems[index])
      this.$set(this.courseItems, index, tmp);
      this.forceReRenderKey++;
    },
  }
}
</script>