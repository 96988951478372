<template>
  <div
      :id="step.uuid"
      ref="wrapper"
      :class="['panel-form-wrapper', 'active',
        state.hotspotPopupVisible ? 'step-panel-in-background' : '']"
      :style="{
          width: width ? width + 'px !important' : '100%',
          height: height ? height + 'px !important' : '100%',
        }"
  >
    <!--<template v-if="!editorMode">
      <div class="state-overlay" v-if="$store.getters.getvFormMode === vformModes.TEST">
        {{ $t('TestMode') }}
      </div>
      <div class="state-overlay" v-else-if="$store.getters.loggingIsDisabled">
        {{ $t('TestMode') }}
      </div>
    </template>-->
    <div
        v-for="(panel, panelIndex) in getStepPanels()"
        :id="'panel-' + panel.uuid"
        :key="panel.uuid + state.panelsRerenderKey"
        :ref="panel.uuid"
        :class="[
                    'form panel-container',
                    !panel.pinBottom && !panel.autoFit ? 'panel-container-default' : 'panel-container-no-bottom',
                    state.panelEditing ? 'editing' : '',
                    showBottomBar && !panel.hideBottomBar ? 'has-bottom-bar' : 'no-bottom-bar',
                    showStepNavigation ? 'preview-mode' : '',
                    showButtonContainer(panel, panelIndex)
                        ? 'has-button-container'
                        : 'no-button-container',
                    //isCurrentStep ? 'active' : 'inactive',
                    'active',
                    step.elements && step.elements.length ? '' : 'empty',
                    panel.pinBottom ? 'pin-bottom' : '',
                    panel.autoFit ? 'auto-fit' : '',
                    getPanelValidation(panel.uuid) && !editable ? 'error-mode': '',
                ]"
        :panelUuid="panel.uuid"
        :stepUuid="step.uuid"
        :style="{
          width: getPanelWidth(panel.width, panel.version),
          minWidth: panel.minWidth ? panel.minWidth + (panel.maxWidthUnit ? panel.maxWidthUnit : 'px') + ' !important' : '',
          maxWidth: panel.maxWidth ? panel.maxWidth + (panel.maxWidthUnit ? panel.maxWidthUnit : 'px') + ' !important' : '',
          left: getPanelLeft(panel.xLeft, panel.version),
          right: panel.xLeft || panel.xLeft === 0 ? 'unset' : 'var(--vform-default-vform-margin)',
          top: panel.pinBottom ? 'unset' : panel.y || panel.y === 0 ? panel.y + '%' : 'var(--vform-default-vform-margin)',
          bottom: panel.pinBottom ? '  var(--vform-bottom-bar-height)' : 'auto',
          height: getPanelHeight(panel),
          'max-height': getPanelMaxHeight(panel),
          fontSize: formSettings.fontSize ? formSettings.fontSize + 'em' : '0.95em',
          borderRadius: panel.borderRadius ? (panel.borderRadius + 'px') : '',
          }">
      <div
          v-if="editorMode"
          :style="{
            left: (panel.xLeft && panel.xLeft > 50) ? '-30px' : 'auto',
            right: (!panel.xLeft || panel.xLeft < 50) ? '-30px' : 'auto',
          }"
          class="panel-top-bar"
      >
        <div
            :class="['icon-container', state.panelEditing ? 'active bg-highlight' : '']"
            @click="switchToPanelEdit(panel)">
          <icon size="0.8" type="pencil-alt"/>
          <div class="hover-label">{{ $t('edit') }}</div>
        </div>
        <div :class="['icon-container', panel.autoFit ? 'active bg-highlight' : '']"
             @click="panel.autoFit = !panel.autoFit; state.panelsRerenderKey++;">
          <icon type="arrows-up-down"/>
          <div class="hover-label">{{ $t('autoFit') }}</div>
        </div>
        <div :class="['icon-container', panel.pinBottom ? 'active bg-highlight' : '']"
             @click="panel.pinBottom = !panel.pinBottom; state.panelsRerenderKey++;">
          <icon v-if="panel.pinBottom" type="arrow-up-from-line"/>
          <icon v-else type="arrow-down-from-line"/>
          <div class="hover-label">{{ $t('pinToBottom') }}</div>
        </div>
      </div>
      <div
          v-if="editorMode"
          :style="{
            left: (panel.xLeft && panel.xLeft > 50) ? '-30px' : 'auto',
            right: (!panel.xLeft || panel.xLeft < 50) ? '-30px' : 'auto',
            bottom: (panel.height && panel.height < 30) ? '-90px' : '',
          }"
          class="panel-bottom-bar"
      >
        <div :class="['icon-container', !panel.hideBottomBar ? 'active' : '']"
             @click="setPanelBottomBar(panel)">
          <icon type="eye"/>
          <div class="hover-label">{{ $t('hideBottomBar') }}</div>
        </div>
      </div>
      <div
          :class="[
              'form-inner d-flex',
              panel.autoFit ? 'auto-fit' : '',
              panel.pinBottom ? 'form-inner-pin-bottom' : '',
              panel.isMaster && editorMode ? 'master-panel' : '',
              ]"
          :style="{
              backgroundColor: panel.backgroundColor ? panel.backgroundColor : 'var(--vform-editor-ui-primary-color)',
              color: panel.fontColor ? panel.fontColor : '#000',
              borderRadius: getRadius(panel),
              borderBottomLeftRadius: showButtonContainer(panel, panelIndex) ? '0' : getRadius(panel),
              borderBottomRightRadius: showButtonContainer(panel, panelIndex) ? '0' : getRadius(panel),
              fontFamily: formSettings.font ? formSettings.font.name + (formSettings.font.systemFont ? '' : ' normal') : ''
          }"
      >
        <div
            v-if="state.panelEditing"
            :style="{backgroundColor: panel.identifierColor}"
            class="edit-indicator"
        >
          <div class="number">
            <icon v-if="panel.isMaster" class="white" type="globe-americas"/>
            <span v-else>{{ !panel.isMaster ? panelIndex + 1 : '' }}</span>
          </div>
        </div>
        <div class="form-inner-inner">
          <div :class="['full-height']">
            <div class="row">
              <!--<div class="col-12 pt-5">
               {{
                  step.elements
                  ? step.elements.map((item) => {
                    return getComponentName(item.formElementTypeString);
                  })
                  : "hallo"
                  }}
                {{ step.elements.length + (vformConfig.global && vformConfig.global.elements ? vformConfig.global.elements.length : 0) }}
              </div>-->
              <div :class="['col-12 vform-tile-container', panel.hideTopPadding ? 'no-top-padding' : ''] ">
                <!--{{ state.progress }} / {{ state.totalSlides }}-->
                <template
                    v-for="(item, index) in getPanelElements(panel, 'bottomDropZone', true)"
                    :class="['element-display', state.activeElement === item.uuid? 'active': '']"
                    style="position: relative"
                >
                  <component
                      class="form-element-context"
                      :slides="slides"
                      :form-id="formId"
                      :session-config="sessionConfig"
                      :is="getComponentName(item.formElementTypeString)"
                      :key="item.uuid"
                      :ref="'element' + item.uuid"
                      :active-mode="activeMode"
                      :class="['element-draggable', getComponentName(item.formElementTypeString),
                              defaultDropzoneHoverable ? 'hover' : 'no-hover']"
                      :config="item"
                      :disable-links="disableLinks"
                      :disabled="!lastSlideActive || state.panelEditing === true"
                      :editable="editorMode && !state.dragging && state.activeColumn !== 'step-properties'"
                      :editor-mode="editorMode"
                      :editor-selected="state.activeElement === item.uuid"
                      :element-uuid="item.uuid"
                      :elementIndex="index"
                      :elementType="item.formElementTypeString"
                      :font="formSettings.font ? formSettings.font : null"
                      :font-color="panel.fontColor ? panel.fontColor: null"
                      :instance-id="$store.getters.getvStageSelectedObjectInstanceId"
                      :is-active="isCurrentStep"
                      :lang="lang"
                      :offline-mode="state.offlineMode"
                      :panel-uuid="item.panelUuid"
                      :preview-mode="!showStepNavigation"
                      :project-id="projectId"
                      :state="state"
                      :step-form-data="stepFormData"
                      :steps="steps"
                      :user-id="userId"
                      :session-id="sessionId"
                      :v-s-t-a-g-e-context="vSTAGEContext"
                      :vform-config="vformConfig"
                      :globals="globals"
                      :teams="teams"
                      :organization-id="organizationId"
                      :project-name="projectName"
                      :data-element-id="item.uuid"
                      @editProperty="showElementProperties(item.uuid)"
                      @goToProject="goToProject"
                      @goToProjectSlide="goToProjectSlide"
                      @goToSlide="goToSlide"
                      @goToSlideNo="goToSlideNo"
                      @goToStep="goToStep"
                      @goToLastSlide="goToLastSlide"
                      @goToFirstSlide="goToFirstSlide"
                      @goToLastStep="goToLastStep"
                      @goToFirstStep="goToFirstStep"
                      @checkout="checkout"
                      @historyBack="$emit('historyBack')"
                      @increasePostLoadingCounter="increasePostLoadingCounter(item.formElementType)"
                      @next="doNext"
                      @removeBlock="$emit('removeBlock', item.uuid)"
                      @setUserId="(value) => {$emit('setUserId', value);}"
                      @signOut="signOut"
                      @storeData="storeData">
                    <div
                        v-if="editorMode"
                        :key="'dz' + index + item.uuid"
                        slot="dropzone"
                        :class="[
                          'interactjs-drop-zone drop-zone w-100',
                          state.dragging ? 'drop-zone-highlight' : '']"
                        :previous-element="item.uuid"
                        :global-panel="panel.isMaster"
                        :panel="panel.uuid"
                    />
                  </component>
                </template>
                <div
                    v-if="editable && editorMode"
                    :class="['interactjs-drop-zone large-drop-zone w-100',state.dragging ? 'drop-zone-highlight' : 'd-none',]"
                    is-last="true"
                    dropZoneId="Large"
                    :global-panel="panel.isMaster"
                    :panel="panel.uuid"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--BUTTON CONTAINER-->
      <div
            v-if="showButtonContainer(panel, panelIndex)"
            @mouseenter="() => {if (editable) {setHoverable(false);}}"
            @mouseleave="() => {if (editable) {setHoverable(true);}}"
            :class="['row no-gutters button-container pb-2 pt-2 d-flex align-items-center align-self-end justify-content-between']"
            :style="{
                            backgroundColor: panel.bottomBackgroundColor
                                ? panel.bottomBackgroundColor : panel.backgroundColor ? panel.backgroundColor : '#fff',
                            borderTop: panel.bottomBackgroundColor
                                ? panel.bottomBackgroundColor : panel.backgroundColor ? panel.backgroundColor : '#fff',
                            borderBottomLeftRadius: getRadius(panel),
                            borderBottomRightRadius: getRadius(panel),
                            fontFamily: formSettings.font ? formSettings.font.name + (formSettings.font.systemFont ? '' : ' normal') : ''
                        }"
        >
          <!--SECONDARY DROPZONE GOES HERE-->
          <div
              v-if="editorMode"
              :class="[
                'interactjs-drop-zone fit-drop-zone w-100',
                state.dragging ? 'drop-zone-highlight' : '',
                !getPanelElements(panel, 'bottomDropZone', false).length && state.dragging ? 'make-zone-larger' : ''
              ]"
              is-bottom="true"
              dropZoneId="bottomDropZone"
              :panel="panel.uuid"/>
          <template
              v-for="(item, index) in getPanelElements(panel, 'bottomDropZone', false)"
              :class="[
                'w-100 element-display position-relative',
                state.activeElement === item.uuid ? 'active' : '',
              ]">
            <component
                class="form-element-context"
                :is="getComponentName(item.formElementTypeString)"
                :key="item.uuid"
                :ref="'element' + item.uuid"
                :form-id="formId"
                :session-config="sessionConfig"
                :active-mode="activeMode"
                :class="[getComponentName(item.formElementTypeString)]"
                :config="item"
                :disable-links="disableLinks"
                :disabled="!lastSlideActive || state.panelEditing === true"
                :editable="editorMode && !state.dragging && state.activeColumn !== 'step-properties'"
                :editor-mode="editorMode"
                :editor-selected="state.activeElement === item.uuid"
                :element-uuid="item.uuid"
                :elementIndex="index"
                :font="formSettings.font ? formSettings.font : null"
                :font-color="panel.fontColor ? panel.fontColor: null"
                :invisible="!nextButtonAllowed"
                :is-active="isCurrentStep"
                :lang="lang"
                :panel-uuid="item.panelUuid"
                :preview-mode="!showStepNavigation"
                :project-id="projectId"
                :step-form-data="stepFormData"
                :steps="steps"
                :user-id="userId"
                :session-id="sessionId"
                :v-s-t-a-g-e-context="vSTAGEContext"
                :globals="globals"
                :teams="teams"
                :organization-id="organizationId"
                :project-name="projectName"
                :num-of-elements-in-zone="getPanelElements(panel, 'bottomDropZone', false).length"
                :data-element-id="item.uuid"
                :slides="slides"
                @editProperty="showElementProperties(item.uuid)"
                @goToProject="goToProject"
                @goToProjectSlide="goToProjectSlide"
                @goToSlide="goToSlide"
                @goToSlideNo="goToSlideNo"
                @goToStep="goToStep"
                @goToLastSlide="goToLastSlide"
                @goToFirstSlide="goToFirstSlide"
                @goToLastStep="goToLastStep"
                @goToFirstStep="goToFirstStep"
                @checkout="checkout"
                @historyBack="$emit('historyBack')"
                @increasePostLoadingCounter="increasePostLoadingCounter(item.formElementType)"
                @next="doNext"
                @removeBlock="$emit('removeBlock', item.uuid)"
                @signOut="signOut"
                @storeData="storeData"
            />
          </template>
        </div>
      <div v-if="state.panelEditing && !panel.pinBottom && !panel.autoFit" class="ns-resize-handle"></div>
      <div v-if="state.panelEditing" class="ew-resize-handle"></div>
      <div v-if="state.panelEditing && !panel.pinBottom && !panel.autoFit" class="nwse-resize-handle"></div>
    </div>
  </div>
</template>

<script>
import {getComponentName} from "@/components/vForm/utils";
import COURSE_BLOCK from "@/components/vForm/viewer/COURSE_BLOCK.vue";
import TEXTDISPLAY from "@/components/vForm/viewer/TEXTDISPLAY";
import RADIOBUTTONS from "@/components/vForm/viewer/RADIOBUTTONS";
import QR_CODE from "@/components/vForm/viewer/QR_CODE";
import SHORT_ANSWER from "@/components/vForm/viewer/SHORT_ANSWER";
import SEPARATOR from "@/components/vForm/viewer/SEPARATOR";
import CHECKBOXES from "@/components/vForm/viewer/CHECKBOXES";
import HEADLINE from "@/components/vForm/viewer/HEADLINE";
import WEBSITE from "@/components/vForm/viewer/WEBSITE";
import IMAGEDISPLAY from "@/components/vForm/viewer/IMAGEDISPLAY";
import VIDEODISPLAY from "@/components/vForm/viewer/VIDEODISPLAY";
import AUDIODISPLAY from "@/components/vForm/viewer/AUDIODISPLAY";
import META_LIST_FILTER from "@/components/vForm/viewer/META_LIST_FILTER";
import MEDIA_LOADER from "@/components/vForm/viewer/MEDIA_LOADER";
import SENSOR from "@/components/vForm/viewer/SENSOR";
import LOGOUT from "@/components/vForm/viewer/LOGOUT";
import PDFDISPLAY from "@/components/vForm/viewer/PDFDISPLAY";
import PROGRESS_TRACKER from "./PROGRESS_TRACKER";
import VERTICAL_SPACE from "./VERTICAL_SPACE";
import BUTTON_ITEM from "./BUTTON_ITEM";
import PART_INFO from "@/components/vForm/viewer/PART_INFO";
import CODE_SNIPPET from "@/components/vForm/viewer/CODE_SNIPPET";
import LIST_MATCH from "@/components/vForm/viewer/LIST_MATCH";
import LIST_MATCH_TEXT_INPUT from "@/components/vForm/viewer/LIST_MATCH_TEXT_INPUT";
import PART_LEGEND from "@/components/vForm/viewer/PART_LEGEND.vue";
import FORM_SUMMARY from "./FORM_SUMMARY";
import LanguageSelector from "@/components/forms/LanguageSelector";
import LOGIN from "./LOGIN";
import Icon from "@/components/Icon";
import LoadingSpinner from "../../LoadingSpinner";
import SignOut from "./SignOut";
import listenerMixinJs from "../../mixins/listenerMixin.js";
import {vformModes} from "@/enum";


export default {
  name: "Step",
  mixins: [listenerMixinJs],
  components: {
    COURSE_BLOCK,
    TEXTDISPLAY,
    RADIOBUTTONS,
    QR_CODE,
    SHORT_ANSWER,
    SEPARATOR,
    CHECKBOXES,
    HEADLINE,
    META_LIST_FILTER,
    MEDIA_LOADER,
    IMAGEDISPLAY,
    VIDEODISPLAY,
    AUDIODISPLAY,
    PART_LEGEND,
    FORM_SUMMARY,
    PROGRESS_TRACKER,
    WEBSITE,
    LOGOUT,
    SENSOR,
    Icon,
    LoadingSpinner,
    BUTTON_ITEM,
    LOGIN,
    VERTICAL_SPACE,
    LanguageSelector,
    SignOut,
    PDFDISPLAY,
    PART_INFO,
    CODE_SNIPPET,
    LIST_MATCH,
    LIST_MATCH_TEXT_INPUT
  },
  props: {
    sessionConfig: {type: Object, default: () => {return {}}},
    step: {type: Object, default: () => {return {};}},
    lang: {type: String, required: true},
    isCurrentStep: {type: Boolean, default: true},
    projectId: {type: String, required: true},
    projectName: {type: String, default: ""},
    showSlide: {type: Boolean, default: true},
    showStepNavigation: {type: Boolean, default: true},
    lastSlideActive: {type: Boolean, default: true},
    nextButtonAllowed: {type: Boolean, default: true},
    /**
     * Whether or not the step should be editable
     * **/
    editable: {type: Boolean, default: false},
    /**
     * Show next button when no step is attached to a slide
     * */
    showNextButtonOnEmptySlides: {type: Boolean, default: false},

    /**
     * The user id is the unique identifier for logging
     * */
    userId: {type: String, default: null},
    sessionId: {type: String, default: null},
    /**
     * shared state between different vform components (different from vformConfig as we do not want to store it)
     * */
    state: {type: Object, default: null},
    /**
     * Whether we are in editor mode or not
     * */
    editorMode: {type: Boolean, default: false},
    formSettings: {type: Object, default: () => {return {fontSize: "0.95", resizeFontBasedOnScreenSize: false};},},
    disableLinks: {type: Boolean, default: false},
    vSTAGEContext: {type: Boolean, default: false},
    /**
     * only for vSTAGE context: whether vform is currently displayed or not
     * if not, stop video and audio and other things happening in the background
     * */
    activeMode: {type: Boolean, default: true},
    vformConfig: {type: Object, default: null},
    stepFormData: { type: Object, default: () => {return {}}},
    steps: { type: Array, default: () => { return []}},
    globals: {type: Object, default: () => {return []}},
    teams: {type: Array, required: true},
    organizationId: {type: String, required: true},
    formId: {type: String, required: true},
    /**
     * The slides array coming from the config.json of a vSTAGE package
     * */
    slides: {type: Array, default: null},
  },
  data() {
    return {
      showBottomBar: true,

      // validation
      validationIncorrectByPanel: [],
      missingRequiredByPanel: [],

      // states
      success: false,
      isMounted: false,
      defaultDropzoneHoverable: true,
      registeredPostLoadingComponents: 0,

      // context menu
      contextMenuForPanel: null,

      getComponentName: getComponentName,

      // layout
      width: 0,
      height: 0,
      vformModes
    };
  },
  watch: {
    editorMode() {
      this.fillData();
      this.adjustSize();
    },
    registeredPostLoadingComponents() {
      this.emitFullyLoaded();
    },
    //stepFormData() {
      // do not activate this or every time you change an input field, everything rerenders
      //this.fillData();
    //}
  },
  beforeMount() {
    this.addListener(window, "resize", this.adjustSize, 'adjust-step-size')
    this.adjustSize();
    if(this.stepFormData) {
      this.fillData();
    }
  },
  mounted() {
    this.isMounted = true;
    this.$emit('stepMounted');
    this.fillData();
    this.$emit('setJumpTargets', this.step.uuid, this.getAllJumpTargets());
    this.emitFullyLoaded();
  },
  methods: {
    checkout(args) {
      this.$emit("checkout", args);
      this.$emit("goToSlide", 'first', {}, false, false, null);
    },
    setPanelBottomBar(panel) {
      panel.hideBottomBar = !panel.hideBottomBar;
      this.state.panelsRerenderKey++;
      const $this = this;
      setTimeout(() => {
        $this.state.panelContextReRenderKey++;
      }, 100)
    },
    switchToPanelEdit(panel) {
      if(this.state.panelEditing && this.state.currentlyEditingPanel === panel.uuid) {
        this.state.currentlyEditingPanel = null;
        this.state.panelEditing = false;
        this.$emit('setActiveColumn', 'blocks');
      } else {
        this.$set(this.state, "currentlyEditingPanel", panel.uuid)
        this.state.panelEditing = true;
        this.$emit('setActiveColumn', 'step-properties');
      }
    },
    getPanelMaxHeight(panel, removeButtonContainer = false) {
      if(panel.pinBottom || panel.autoFit) {
        if(panel.hideBottomBar) {
          // 100% without the bar at the bottom
          return 'calc(100% - var(--vform-bottom-bar-height))';
        } else {
          return 'calc(100% - var(--vform-bottom-bar-height) ' + (removeButtonContainer ? ' - 65px ' : '' ) + ' )'
        }
      } else if(this.showBottomBar) {
        return 'calc(100% - var(--vform-bottom-bar-height))';
      }
      return '100%';
    },
    getPanelHeight(panel) {
      if(panel.autoFit) {
        return 'auto !important';
      }
      if (panel.height) {
        if (panel.pinToBottom) {
          return 'calc(100% - var(--vform-bottom-bar-height))'
        }
        return panel.height + '%';
      } else {
        if (this.showBottomBar) {
          return 'calc(100% - (var(--vform-default-vform-margin)*2) - var(--vform-bottom-bar-height))'
        } else {
          return 'calc(100% - (var(--vform-default-vform-margin)*2) - var(--vform-editor-carousel-height))'
        }
      }
    },
    getPanelLeft(leftInPercent, version) {
      // todo: remove after making sure it is working
      if (!leftInPercent && leftInPercent !== 0) {
        return 'unset';
      } else if (version) {
        return leftInPercent + '%';
      } else {
        // this is for legacy panels
        return 'calc(' + leftInPercent + '%  + 30px)';
      }
    },
    getPanelWidth(widthInPercent, version) {
      const noWidth = !widthInPercent && widthInPercent !== 0;
      if (!version && noWidth) {
        return 'calc(30% - 30px)';
      } else if(version && noWidth) {
        return '30%';
      } else if (version) {
        return widthInPercent + '%';
      } else {
        // this is for legacy panels without a version only
        return 'calc(' + widthInPercent + '%  - 30px)';
      }
    },
    getRadius(panel) {
      return (panel.borderRadius ? panel.borderRadius : 0) + 'px';
    },
    adjustSize() {
      if(!this.vSTAGEContext) {
        const coverImages = document.getElementsByClassName("slide-large-image");
        const coverImage = coverImages[0];
        if (!coverImage) {
          return;
        }
        const mediaRatio = coverImage.naturalWidth / coverImage.naturalHeight;
        const containerRatio = coverImage.clientWidth / coverImage.clientHeight;
        if (mediaRatio <= containerRatio) {
          this.width = coverImage.clientWidth;
          this.height = coverImage.clientHeight;
        } else {
          this.width = coverImage.clientHeight * containerRatio;
          this.height = coverImage.clientHeight;
        }
      } else {
        this.width = null;
        this.height = null;
      }
    },
    /**
     * Checks if there are buttons in the bottom panel which are hidden
     * */
    checkForHiddenButton() {
      const {elements} = this.step;
      let hiddenBottomEls = 0;
      if (elements.length && this.step.panels) {
        const bottom = elements.filter(item => {
          return !!item.bottomDropZone
        })
        for (let i = 0; i < bottom.length; i++) {
          const panelUuid = bottom[i].panelUuid;
          let panel = [this.step.panels[0]];
          if (panelUuid) {
            panel = this.step.panels.filter(item => {
              return item.uuid === bottom[i].panelUuid
            })
          }
          if (panel && panel.length) {
            const hiddenBottomBar = panel[0].hideBottomBar;
            if (hiddenBottomBar) {
              hiddenBottomEls++;
            }
          }
        }
      }
      return hiddenBottomEls;
    },
    getStepPanels() {
      let globalStepPanels = [];
      let globalPanels = this.vformConfig.global && this.vformConfig.global.panels ? this.vformConfig.global.panels : [];
      globalPanels.forEach((panel) => {
        if (panel.globalVisibleOnSteps) {
          if (panel.globalVisibleOnSteps.find((x) => x === this.step.uuid)) {
            globalStepPanels.push(panel);
          }
        }
      });

      return [...this.step.panels, ...globalStepPanels];
    },
    checkItemDropzone(item, dropZoneName, negate = false) {
      if(!dropZoneName) {return true}
      return negate ? !item[dropZoneName] : !!item[dropZoneName];
    },
    /**
     * Gets all elements for a given panel
     * the panel can either be global or local
     * */
    getPanelElements(panel, dropZoneFilter = '', negate = false) {
      const {uuid} = panel;
      const panelIndex = this.step.panels.findIndex((p) => p.uuid === uuid);
      //if the panel is part of the current step
      if (panelIndex !== -1) {
        return this.step.elements.filter(item => {
          return ((item.panelUuid === uuid) || (!item.panelUuid && panelIndex === 0)) && this.checkItemDropzone(item, dropZoneFilter, negate)
        });
      } else if (panel.isMaster) {
        if (this.vformConfig.global && this.vformConfig.global.elements) {
          return this.vformConfig.global.elements.filter((item) => {
            return (item.panelUuid === uuid) && this.checkItemDropzone(item, dropZoneFilter, negate);
          });
        }
      }
      return [];
    },
    removeHiddenBottomBarItems(panel, els) {
      console.log(panel);
      if(panel.hideBottomBar) {
        console.log(els)
        return els.filter(item => {return this.checkItemDropzone(item, 'bottomDropZone', true)})
      }
      return els;
    },
    emitFullyLoaded() {
      /**
       * We have two issues when setting a step to fully loaded:
       * 1 - Buttons in a hidden bottom bar – they won't be mounted at all so they cannot emit a loaded state
       * 2 - Buttons that are hidden because of autoplay – they are in the dom, but not visible and not clickable
       * */
      const panels = this.getStepPanels();
      let allElementsCount = 0;
      for(let i = 0; i < panels.length; i++) {
        let els = this.getPanelElements(panels[i]);
        els = this.removeHiddenBottomBarItems(panels[i], els);
        allElementsCount += els.length;
      }
      if (allElementsCount === this.registeredPostLoadingComponents) {
        console.log('emitting fully loaded')
        this.$emit('fullyLoaded', this.step.uuid);
      }
    },
    increasePostLoadingCounter() {
      this.registeredPostLoadingComponents = this.registeredPostLoadingComponents + 1;
    },
    getFontSize(fontSizeInRem, panelUuid) {
      if (!this.formSettings.resizeFontBasedOnScreenSize) {
        return fontSizeInRem + 'rem';
      }
      const wrapper = document.getElementById('slideshow-div');
      if (wrapper) {
        const width = window.getComputedStyle(wrapper, null).getPropertyValue("width").replace('px', '');
        const height = window.getComputedStyle(wrapper, null).getPropertyValue("height").replace('px', '');
        const ref = this.$refs[panelUuid];
        const el = ref ? ref[0] : null;

        if (el) {
          const rootFs = window.getComputedStyle(document.body).getPropertyValue("font-size");
          const rawRootFs = rootFs.replace('px', '');
          const targetSize = (rawRootFs / fontSizeInRem * 100) * (width * height / 1000 * 0.0000015);
          return targetSize + 'rem';
        }
      }

      return fontSizeInRem + 'rem';
    },
    /**F
     * For validation (e.g. a questionnaire with checkboxes) the panel shows something is wrong
     * */
    getPanelValidation(panelUuid) {
      if (this.validationIncorrectByPanel && this.validationIncorrectByPanel.length) {
        const incorrect = this.validationIncorrectByPanel.filter((item) => {
          return (item.panelUuid === panelUuid || (!panelUuid && !item.panelUuid));
        });
        return incorrect.length;
      } else if (this.missingRequiredByPanel && this.missingRequiredByPanel.length) {
        const required = this.missingRequiredByPanel.filter((item) => {
          return (item.panelUuid === panelUuid || (!panelUuid && !item.panelUuid));
        });
        return required.length;
      } else {
        return false;
      }
    },
    signOut() {
      if (!this.editorMode) {
        this.$emit("signOut", true);
      }
    },
    setHoverable(val) {
      this.defaultDropzoneHoverable = val;
    },
    showElementProperties(elementUuid) {
      this.state.activeElement = elementUuid;
    },
    showButtonContainer(panel, panelIndex) {
      if(panel.hideBottomBar) {
        return false;
      }
      return (
          this.editorMode ||
          (this.step.elements.length &&
              this.step.elements.filter((item) => {
                return (
                    item.bottomDropZone &&
                    (item.panelUuid === panel.uuid ||
                        (panelIndex === 0 && !item.panelUuid))
                );
              }).length)
          || (this.vformConfig.global && this.vformConfig.global.elements && this.vformConfig.global.elements.length &&
              this.vformConfig.global.elements.filter((item) => {
                return (
                    item.bottomDropZone &&
                    (item.panelUuid === panel.uuid ||
                        (panelIndex === 0 && !item.panelUuid))
                );
              }).length)
      );
    },
    storeData(elementId, data) {
      this.$emit('storeData', elementId, data);
    },
    /**
     * @params {any} - data
     * **/
    fillData() {
      let data = this.stepFormData;
      if (this.step && this.step.elements) {
        this.processElementsFillData(data, this.step.elements);
      }
      if(this.vformConfig.global && this.vformConfig.global.elements) {
        this.processElementsFillData(data, this.vformConfig.global.elements);
      }
    },
    processElementsFillData(data, elements) {
      for (let i = 0; i < elements.length; i++) {
        const component = this.getComponent(elements[i].uuid);
        if (component && component.fillData) {
          const localData = data && data[elements[i].uuid] ? data[elements[i].uuid] : null;
          component.fillData(localData);
        }
      }
    },
    getAllJumpTargets() {
      const targets = [];
      if (this.step && this.step.elements) {
        for (let i = 0; i < this.step.elements.length; i++) {
          const component = this.getComponent(this.step.elements[i].uuid);
          if (component && component.getJumpTarget) {
            const localTarget = component.getJumpTarget();
            if (localTarget) {
              targets.push(localTarget)
            }
          }
        }
      }
      return targets;
    },
    prepareStepTransition() {
      if(this.$store.getters.getvFormMode === vformModes.PREVIEW) {
        return {
          data: {},
          success: true
        }
      }
      this.validationIncorrectByPanel = [];
      this.missingRequiredByPanel = [];
      const showSuccess = false;
      let data = {};
      // todo:
      /**
       * getStepPanels -> returns all panels on this step
       * get all elements per panel
       * validate all elements of that panel
       * */
      const panels = this.getStepPanels();
      for (let j = 0; j < panels.length; j++) {
        const panel = panels[j];
        const {uuid} = panel;
        const elements = this.getPanelElements(panel);
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const component = this.getComponent(element.uuid);
          if (component) {
            if (component.validate && this.lastSlideActive) {
              const isValid = component.validate();
              if (!isValid) {
                this.validationIncorrectByPanel.push({
                  panelUuid: uuid,
                  validationIncorrect: true,
                });

              }
            }

            if (component.checkRequired && this.lastSlideActive) {
              const hasRequired = component.checkRequired();
              if (!hasRequired) {
                this.missingRequiredByPanel.push({
                  panelUuid: uuid,
                  missingRequired: true,
                });
              }
            }
          }
        }
      }
      data = this.collectComponentData();
      if (!this.validationIncorrectByPanel.length && !this.missingRequiredByPanel.length) {
        if (showSuccess) {
          this.success = true;
        }
        return {data, success: true};
      } else {
        this.$emit('wrongAnswer', data)
      }
      return {data, success: false};
    },
    /**
     * Default next button
     * */
    doNext(trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("nextSlide", data, false, false, trigger);
      }
    },
    goToLastSlide(args) {
      // args also contains projectId, make this working cross project at some point
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", 'last', data, false, false, trigger);
      }
    },
    goToFirstSlide(args) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", 'first', data, false, false, trigger);
      }
    },
    goToLastStep(args) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", 'last', data, false, trigger);
      }
    },
    goToFirstStep(args) {
      const {trigger} = args;
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", 'first', data, false, trigger);
      }
    },
    goToSlide(uuid, label, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToSlide", uuid, data, false, false, trigger);
      }
    },
    /**
     * Legacy, only used for old QR codes, which have slide:5 in their metavalues
     * @params no {Integer} - the number of the slide (which of course can change if new slides are inserted)
     * */
    goToSlideNo(no, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success || no === 0) {
        this.$emit("goToSlideNo", no, data, true, trigger);
      }
    },
    goToStep(stepUuid, label, trigger = null) {
      const {data, success} = this.prepareStepTransition();
      if (success) {
        this.$emit("goToStep", stepUuid, data, false, trigger);
      }
    },
    goToProjectSlide(projectUuid, projectName, targetSlide = null, trigger = null) {
      let data = {};
      data = this.collectComponentData();
      this.$emit("goToProjectSlide", projectUuid, projectName, targetSlide, data, trigger);
    },
    goToProject(conf, trigger = null) {
      let data = {};
      data = this.collectComponentData();
      this.$emit("goToProject", conf, data, trigger);
    },
    getComponent(uuid) {
      return this.$refs["element" + uuid] ? this.$refs["element" + uuid][0] : null;
    },
    collectComponentData() {
      let slideData = {
        logs: [],
        stepName: this.step.name ? this.step.name : "",
      };
      const panels = this.getStepPanels();
      for (let j = 0; j < panels.length; j++) {
        const panel = panels[j];
        const elements = this.getPanelElements(panel);
        for (let i = 0; i < elements.length; i++) {
          const element = elements[i];
          const component = this.getComponent(element.uuid);
          if (component) {
            if (component.getData) {
              const data = component.getData();
              if (data) {
                slideData.logs.push(data);
              }
            }
          }
        }
      }

      return slideData;
    },
  },
};
</script>

<style lang="scss">
.block-drop-not-allowed {
  cursor: not-allowed !important;
}
</style>

<style lang="scss" scoped>
.state-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(80, 188, 255, 0.4);
  border: 1px dashed #444;
  border-radius: 3px;
  padding: 5px;
  z-index: 180;
  pointer-events: none;
}
.panel-top-bar, .panel-bottom-bar {
  cursor: pointer;
  padding-top: 15px;
  padding-bottom: 15px;
  transition: all 300ms ease;
  position: absolute;
  width: 30px;
  top: 5px;
  z-index: 156;

  .hover-label {
    position: absolute;
    left: 100%;
    top: 0;
    background-color: rgba(0, 0, 0, 1);
    padding: 3px;
    border-radius: 3px;
    display: inline-block;
    min-width: 200px;
    opacity: 0;
    pointer-events: none;
    font-size: 0.9rem;
    z-index: 5;
  }

  .icon-container {
    opacity: 0.5;
    z-index: 4;
    border-radius: 50%;
    width: 80%;
    padding-top: 75%;
    margin: auto;
    background-color: var(--ccdefault-normal-button-hover-background);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: #fff;
    position: relative;
    margin-bottom: 3px;

    &:hover {
      opacity: 0.9;
      background-color: var(--ccdefault-normal-button-hover-background);

      .hover-label {
        animation: forwards 0.5s fadeIn;
        animation-delay: 0.8s;
      }
    }

    &.active {
      background-color: var(--vform-editor-ui-secondary-color);
    }

    &.bg-highlight {
      background-color: $highlight;
    }
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.9rem !important;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.panel-bottom-bar {
  top: auto;
  bottom: 0;
  padding-bottom: 0;
}

.drop-zone {
  height: 23px;
  position: absolute;
  top: -16px;
  z-index: -1;
  width: 100%;
  left: 0;
}

.drop-zone-highlight {
  z-index: 50 !important;
}

.fit-drop-zone {
  min-height: 40px;
  z-index: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  width: 100%;
  &.make-zone-larger {
    position:static;
    transform: none;
  }
}

.fixed-drop-zone {
  height: 10px;
  position: relative;
  z-index: -1;
  width: 100%;

  &.make-larger {
    height: 40px;
  }
}

.large-drop-zone {
  height: 100px !important;
  position: relative;
  top: 5px;
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
  z-index: -1;
  width: 100%;
}

.drop-active {
  background-color: var(--vform-editor-accent-color);
  background-clip: content-box;
  opacity: 0.7;
  padding: 6px 0 8px 0;
}

.drop-not-allowed {
  cursor: not-allowed;
  background-color: red;
  background-clip: content-box;
  opacity: 0.3;
  padding: 6px 0 8px 0;
}

.element-display {
  border: solid 1px transparent;
  cursor: pointer;
}

.form {
  min-width: 250px;
  position: absolute;
  width: 100%;
  height: calc(100% - (var(--vform-default-vform-margin) * 2));
  z-index: 55;
  font-size: 0.95rem;
  margin: auto;
  color: var(--vform-on-bright-color);
  display: flex;
  flex-direction: column;

  &.error-mode {
    outline: 2px solid red;
  }

  &.active {
    right: var(--vform-default-vform-margin);
  }

  &.inactive {
    right: -100vw !important; // needs to be important because of locally set styles
    width: 0;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
  }

  &.no-bottom-bar {
    height: calc(
        90% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-editor-carousel-height)
    );
  }

  &.has-bottom-bar {
    //margin-bottom: var(--vform-default-vform-margin);
    height: calc(
        100% - (var(--vform-default-vform-margin) * 2) -
        var(--vform-bottom-bar-height)
    );
  }
}

@media screen and (max-width: 800px) {
  :root {
    --vform-vform-margin: 23px;
  }
  .form {
    font-size: 0.9rem;
  }
}

.vform-preview {
  .form {
    padding-left: 0;
    position: static;
    padding-top: 0;
  }
}

.form-inner {
  position: relative;
  flex: 1;
  padding-left: 18px;
  padding-right: 18px;
  background-color: var(--vform-editor-ui-primary-color);
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;

  &.form-radius {
    border-radius: 3px;
  }

}

.master-panel {
  background-image: repeating-linear-gradient(45deg, rgba(47, 47, 67, 0.12) 0, rgba(47, 47, 67, 0.12) 1px, transparent 0, transparent 50%);
  background-size: 12px 12px;
  background-color: rgba(240, 255, 71, 0);
}

.form-spacer {
  padding-top: var(--vform-editor-header-bar-height);
}

.form.empty {
  .form-inner {
    background-color: transparent;
  }
}

.form-inner-inner {
  min-height: 80%;
  width: 100%;
}

.button-container {
  width: 100%;
  border-top: 1px solid #ddd;
  display: flex;
  // flex-direction: column;
  position:relative;
  flex: 0;
  background-color: #fff;
  z-index: 100;
  padding-left: 18px;
  padding-right: 18px;
  margin: 0;
  &.bar-border-radius {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }
}

.home-button {
  color: var(--vform-editor-layout-accent-color);
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;

  &:hover {
    background-color: var(--vform-neutral-color);
  }
}

@keyframes slideIn {
  from {
    left: 0;
    width: 0;
  }
  to {
    width: 2vw;
    left: -3vw;
  }
}

.slide-header {
  width: 100%;
  z-index: 15;
  margin-top: 15px;
  color: var(--vform-on-bright-color);
}

.panel-form-wrapper {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 20;
  cursor: auto;
  display: flex;

  &.inactive {
    display: none;
  }
}

.active-col-step-properties {
  .panel-form-wrapper {
    width: 100%;
    height: 100%;
  }
}

.active-col-properties, .active-col-blocks, .active-col-hotspots, .active-col-learning-dots {
  .panel-form-wrapper {
    pointer-events: none;
  }
}

.ns-resize-handle {
  background: transparent;
  cursor: ns-resize;
  width: calc(100% - 25px);
  height: 25px;
  top: -25px;
  left: 0;
  position: absolute;
  z-index: 100;
  opacity: 0.4
}

.ew-resize-handle {
  background: transparent;
  cursor: ew-resize;
  width: 20px;
  height: calc(100% - 25px);
  top: 0;
  position: absolute;
  z-index: 100;
  right: 0;
}

.nwse-resize-handle {
  background: transparent;
  cursor: nwse-resize;
  width: 25px;
  height: 25px;
  bottom: 0;
  position: absolute;
  z-index: 101;
  right: 0;
}

.panel-container {
  pointer-events: all;
}

.step-panel-in-background {
  opacity: 0.8;
}

.panel-bottom-bar {
  .vform-slide-control-element.BUTTON_ITEM {
    margin: auto;
  }
}

.edit-indicator {
  position: absolute;
  top: -6px;
  left: -6px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  z-index: 150;
  box-shadow: 1px 1px 7px 4px rgb(0 0 0 / 13%);
  color: #fff;

  .number {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

/***
---------------
Media Queries
--------------
*/
.panel-form-wrapper {
  font-size: 16px;
  // background-color: white;
}

// X-Small devices (portrait phones, less than 576px)
@media (min-width: 576px) {
  .panel-form-wrapper {
    font-size: 12px;
    // background-color: red;
    .form {
      min-width: 200px;
    }
  }

  .vform-tile-container {
    padding: 0.5rem !important;
  }
}

// Small devices (landscape phones, less than 768px)
@media (min-width: 768px) {
  .panel-form-wrapper {
    font-size: 13px;
   // background-color: yellow;
  }

  .vform-tile-container {
    padding: 0.7rem !important;
  }
}

// Large devices (desktops, less than 1200px)
@media (min-width: 992px) {
  .panel-form-wrapper {
    font-size: 14px;
   // background-color: blue;
  }

  .vform-tile-container {
    padding: 1rem !important;
  }
}

// Medium devices (tablets, less than 992px)
@media (min-width: 1200px) {
  .panel-form-wrapper {
    font-size: 14px;
   // background-color: brown;
  }

  .vform-tile-container {
    padding: 0.8rem !important;
  }
}

// X-Large devices (large desktops, less than 1400px)
@media (min-width: 1400px) {
  .panel-form-wrapper {
    font-size: 16px;
   // background-color: violet; // done
  }

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}

// XXX-Large devices (larger desktops, 1400px and up)
@media (min-width: 2400px) {
  .panel-form-wrapper {
    font-size: 18px;
   // background-color: black;
  }

  .vform-tile-container {
    padding: 1.1rem !important;
  }
}

</style>
