<template>
    <div>
        <label class="vform-editor-label section">Text</label>
        <div
            v-if="element.currentLang === key"
            class="dflex"
            :key="index2 + 1000000000 + forceReRenderKey"
            v-for="(key, index2) in Object.keys(element.label.dix)"
        >
            <div class="col p-0">
                <textarea
                    :title="element.label.dix['Unknown']"
                    :placeholder="
                        key === 'Unknown'
                            ? placeholders.LONG
                            : $t('vform.translationMessage', {
                                  key: key,
                                  content: element.label.dix['Unknown'],
                              })
                    "
                    rows="300"
                    class="mb-1 form-text v-form-label form-text-dark"
                    type="text"
                    v-model="element.label.dix[key]"
                />
            </div>
        </div>
      <template v-if="!element.emphasized">
        <label class="vform-editor-label section">Background Color</label>
        <div
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.backgroundColor
                    ? element.backgroundColor
                    : '',
                border: element.backgroundColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="colorPickrId = element.uuid"
            class="clickable"
        />
        <color-picker
            v-if="colorPickrId === element.uuid"
            ref="colorpickr"
            :popup-style="true"
            :show-save-button="true"
            :value="element.backgroundColor ? element.backgroundColor : '#fff'"
            class="step-settings-color-pickr"
            @changeColor="
              (color) => {
                setColor(color, false);
              }"
            @close="
                () => {
                    colorPickrId = null;
                }
            "
            @save="
                (color) => {
                     setColor(color);
                }
            "
        />
        <label class="vform-editor-label section">Font Color</label>
        <div
            :style="{
                width: '20px',
                height: '20px',
                backgroundColor: element.fontColor
                    ? element.fontColor
                    : '',
                border: element.fontColor ? '' : '1px dashed rgba(255,255,255,0.5)'
            }"
            @click="colorPickrId = 'fontColor'"
            class="clickable"
        />
        <color-picker
            v-if="colorPickrId === 'fontColor'"
            ref="colorpickr"
            :popup-style="true"
            :show-save-button="true"
            :value="element.fontColor ? element.fontColor : '#000'"
            class="step-settings-color-pickr"
            @changeColor="
              (color) => {
                setFontColor(color, false);
              }"
            @close="
                () => {
                    colorPickrId = null;
                }
            "
            @save="
                (color) => {
                     setFontColor(color);
                }
            "
        />
        <div
            v-if="element.backgroundColor || element.fontColor"
            class="settings-button d-inline-block mt-2"
            @click="element.backgroundColor = null; element.fontColor = null;">
          {{ $t('resetColor') }}
        </div><br />
      </template>

        <label class="vform-editor-label section">Options</label>
        <div class="col row dflex">
            <div class="dflex">
                <label
                    class="container vform-editor-body"
                    :for="'chkEmphasized' + $vnode.key"
                    >{{ $t("emphasized") }}
                    <input
                        type="checkbox"
                        :id="'chkEmphasized' + $vnode.key"
                        v-model="element.emphasized"
                    />
                    <span class="checkmark"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
import { vFormControls, placeholders } from "@/enum";
import ColorPicker from "../widgets/editors/ColorPicker";
export default {
    name: "vFormTEXT",
    components: {
        ColorPicker,
    },
    props: {
        element: { type: Object, required: true },
        stepNo: { type: Number, required: true },
        organizationId: { type: String, default: "" },
    },
    mixins: [vFormElementMixin],
    data() {
        return {
            forceReRenderKey: 0,
            colorPickrId: null,
            vFormControls: vFormControls,
            placeholders: placeholders,
        };
    },
    beforeMount() {
        this.element.formElementType = vFormControls["TEXT"];
    },
    methods: {},
};
</script>
