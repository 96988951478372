var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
  'vform-part-legend',
  'position-relative',
  'w-100',
  'multi-line', 
  _vm.config.emphasized ? 'vform-emphasized' : ''
]},[_vm._t("dropzone"),_c('div',{staticClass:"w-100"}),(_vm.editable)?_c('editor-overlay',{attrs:{"active":_vm.editorSelected},on:{"removeBlock":function($event){return _vm.$emit('removeBlock')},"editProperty":_vm.editProperty}}):_vm._e(),_c('div',{staticClass:"text",attrs:{"contenteditable":"false","id":_vm.uniqueId},on:{"input":_vm.onInput,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.leaveEditing(_vm.uniqueId)},"click":function($event){$event.stopPropagation();return _vm.doNothing($event)}}},[(_vm.editable && !_vm.legend)?_c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('vform.legendPlaceholder')))])]):(_vm.legend)?_c('div',[_c('h3',{class:[
        'd-flex',
        'flex-row',
        'title-' + _vm.config.titleAlignment ]},[_vm._v(" "+_vm._s(_vm.$t('vform.legend'))+" ")]),(!Object.values(_vm.legend).length)?_c('div',[_vm._v(" - ")]):_vm._e(),_c('div',{class:[
        'd-flex',
        'flex-column',
        'text-' + _vm.config.textAlignment,
        'part-legend'
      ]},_vm._l((Object.values(_vm.legend)),function(item,index){return _c('div',{key:index,staticClass:"d-flex"},[(item.letter)?_c('div',{staticClass:"letter"},[_vm._v(_vm._s(item.letter)+")")]):_vm._e(),_c('div',[_vm._v(_vm._s(item.name))])])}),0)]):_c('div',[_vm._v(" "+_vm._s(_vm.$t('vform.partLegendLoading'))+" ")])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }