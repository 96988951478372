<template>
  <div class="the-three-view row">
    <div class="col-6">
      <div class="form-error label vform-label white mb-5">ATTENTION, those controls do autosave!</div>
      <div class="label vform-label white">{{ $t('metalness') }}</div>
      <vue-slider
          class="mb-1"
          v-model="localConfig.metalness"
          :interval="0.1"
          :max="1"
          :min="0"
          tooltip="always"
          @change="applyMaterial"
      >
      </vue-slider>
      <div class="label vform-label white mt-4">{{ $t('glossiness') }}</div>
      <vue-slider
          class="mb-1"
          v-model="localConfig.glossiness"
          :interval="0.1"
          :max="1"
          :min="0"
          tooltip="always"
          @change="applyMaterial"
      >
      </vue-slider>
      <color-picker
          v-if="localConfig.mainColor"
          ref="colorpickr"
          :popup-style="false"
          :value="localConfig.mainColor"
          class="mb-2"
          :show-save-button="false"
          @changeColor="setColor"
          @save="setColor"
      />
    </div>
    <div :id="'fbx_preview_' + id" class="three col-6" @mouseleave="() => {canAnimate = false;}"
         @mouseover="() => {canAnimate = true; animate();}">
      <div>
        <icon class="preview-icon" type="video"/>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import {SpecialUuids} from "@/enum";
import VueSlider from "vue-slider-component";
import ColorPicker from "../widgets/editors/ColorPicker.vue";

export default {
  name: "Three",
  components: {
    ColorPicker,
    Icon,
    VueSlider
  },
  props: {
    id: {type: String, required: true},
    assetId: {type: String, required: true},
    metaSets: {
      type: Array, default: () => {
        return []
      }
    }
  },
  data() {
    return {
      resizeObserver: {},
      canAnimate: false,
      camera: null,
      scene: null,
      renderer: null,
      mesh: null,
      sphere: null,
      loader: null,
      localConfig: {
        mainColor: null
      },
      transparency: 0,
      timeout: null
    }
  },
  watch: {
    metaSets: {
      deep: true,
      handler() {
        this.setUpMeta();
      }
    }
  },
  mounted() {
    this.setUpMeta();
  },
  methods: {
    setColor(color) {
      this.localConfig.mainColor = color;
      this.applyMaterial()
    },
    coconvertHexTransparencyToFloat(code) {
      const alphaDecimal = parseInt(code, 16);
      return alphaDecimal / 255;
    },
    setUpMeta() {
      if(!this.metaSets) {
        return;
      }
      const metaSet = this.getMatMeta();
      if (metaSet) {
        this.localConfig = this.mapMetaVals(metaSet.metaFields);
      }

      this.init();
    },
    getMatMeta() {
      const item = this.metaSets.filter(item => {
        return item.id === SpecialUuids.MATERIAL_METASETID;
      })
      return item[0] ? item[0] : null;
    },
    mapMetaVals(fields) {
      if (!fields) {
        return;
      }
      let conf = {};
      fields.map(field => {
        conf[field.key] = field.metaValues && field.metaValues.length ? field.metaValues[0].value : field.defaultValue;
      })
      return conf;
    },
    applyMaterial() {
      this.sphere.material = this.getCurrentMaterial();
      const $this = this;
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        $this.saveMaterial($this.getMatMeta().metaFields);
      }, 300)
    },
    saveMaterial(fields) {
      const args = []
      for(let i = 0; i < fields.length; i++) {
        const {key, id} = fields[i];
        args.push({
          metaFieldId: id, value: this.localConfig[key]
        })
      }
      this.$store.dispatch('clientSaveAssetMetaSetValues', {
        id: this.assetId,
        values: args
      }).then(() => {
        this.$emit('saved')
      })
    },
    getCurrentMaterial() {
      const {mainColor, metalness, glossiness} = this.localConfig;
      const convertedColor = this.localConfig.mainColor.slice(0, -2);
      if(mainColor.length === 9) {
        this.transparency = this.coconvertHexTransparencyToFloat(mainColor.charAt(7) + mainColor.charAt(8));
      }
      const material = new this.Three.MeshPhysicalMaterial({
        color: new this.Three.Color(convertedColor),
        metalness: parseFloat(metalness),
        reflectivity: parseFloat(glossiness),
        transmission: 1 - this.transparency,
        sheenRoughness: 1 - parseFloat(metalness)
        /* roughness,
         */
      })
      return material;
    },
    init () {
      console.log('initializing')
      cancelAnimationFrame(document.getElementById('fbx_preview_' + this.id));
      let Three = this.Three;

      const container = document.getElementById('fbx_preview_' + this.id);
      this.renderer = new Three.WebGLRenderer({antialias: true, alpha: true});
      this.renderer.setSize(container.clientWidth, container.clientHeight);
      container.innerHTML = '';

      this.scene = new Three.Scene();
      this.camera = new Three.PerspectiveCamera(75, container.clientWidth / container.clientHeight, 1, 1000);
      this.camera.position.z = 120;
      this.scene.add(this.camera);
      new Three.PerspectiveCamera(75, container.innerWidth / container.innerHeight, 0.1, 1000);

      const ambientLight = new Three.SpotLight('#fff', 0.9);
      ambientLight.position.set(200, 200, 200);


      this.scene.add(ambientLight);
      const geometry = new Three.SphereGeometry(35, 32, 16);
      const material = this.getCurrentMaterial();

      this.sphere = new Three.Mesh(geometry, material);
      this.scene.add(this.sphere);

      const directionalLight = new Three.DirectionalLight( 0xffffff, 1 );
      this.scene.add( directionalLight );
      directionalLight.target = this.sphere;

      container.appendChild(this.renderer.domElement);
      this.animate();
    },
    animate: function () {
      if (this.renderer) {
        requestAnimationFrame(this.animate);
      }
      if (this.renderer) {
        try {
          this.renderer.render(this.scene, this.camera);
        } catch (e) {
          console.log(e);
          this.canAnimate = false;
        }
      }
    },
    rotateAboutPoint: function (obj, point, axis, theta, pointIsWorld) {
      pointIsWorld = (pointIsWorld === undefined) ? false : pointIsWorld;

      if (pointIsWorld) {
        obj.parent.localToWorld(obj.position); // compensate for world coordinate
      }

      obj.position.sub(point); // remove the offset
      obj.position.applyAxisAngle(axis, theta); // rotate the POSITION
      obj.position.add(point); // re-add the offset

      if (pointIsWorld) {
        obj.parent.worldToLocal(obj.position); // undo world coordinates compensation
      }

      obj.rotateOnAxis(axis, theta); // rotate the OBJECT
    },
    materialRemoveReflectivity: function (object) {
      object.traverse(function (Group) {
        Group.children.forEach(mesh => {
          let mat = mesh.material;
          if (Array.isArray(mat)) {
            mat.forEach(submat => {
              if (submat && submat.isMeshPhongMaterial) {
                submat.reflectivity = 0.0;
              }
            });
          } else {
            if (mat && mat.isMeshPhongMaterial) {
              mat.reflectivity = 0.0;
            }
          }
          mesh.children.forEach(child => {
            let mat = child.material;
            if (Array.isArray(mat)) {
              mat.forEach(submat => {
                if (submat && submat.isMeshPhongMaterial) {
                  submat.reflectivity = 0.0;
                }
              });
            } else {
              if (mat && mat.isMeshPhongMaterial) {
                mat.reflectivity = 0.0;
              }
            }
          });
        });
      });
    },

  },
};
</script>

<style lang="scss">
.three {
  width: 100%;
  padding-top: 100%;
  position: relative;
  background: #2d2d33;

  canvas {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    font-size: 4.5em;
  }
}

.thumbnail.preview #threeContainer {
  position: absolute;
}
</style>
