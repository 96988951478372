<template>
  <div>
      <div class="row" id="form-export">
        <div class="pt-3 pb-4">
          <h1 class="white">Export</h1>
          <div class="lighter mb-1">{{ $t('Please select a format for your export.') }}</div>
          <!-- Word -->
          <div
            :class="['settings-button float-left mr-1 d-inline-block', showExportPrompt === 'Word' ? 'orange-button' : 'switched-colors']"
            @click="
              showExportPrompt = 'Word';
              autoGenerateLegend = false;
            "
          >
            <icon class="mr-2" type="file-word" />
            <loading-spinner  v-if="$store.getters.isWORDGenerating" class="white ml-2 float-right" />
            {{ $t("exportPrompt.word") }}
            <div class="d-inline-block ml-1" v-if="$store.getters.isWORDGenerating">
              ({{ $store.getters.currentWordPage }} / {{ $store.getters.totalWordPages }})
            </div>
          </div>

          <!-- PDF -->
          <div
              :class="['settings-button mr-1 d-inline-block', showExportPrompt === 'PDF' ? 'orange-button' : 'switched-colors']"
            @click="
              showExportPrompt = 'PDF';
              showSessionSelection = false;
            "
          >
            <icon
              v-if="!$store.getters.isPDFGenerating"
              class="mr-2"
              type="pdf"
            />
            <loading-spinner v-if="$store.getters.isPDFGenerating" class="white ml-2 float-right" />
            {{ $t("exportPrompt.pdf") }}
            <div class="d-inline-block ml-1" v-if="$store.getters.isPDFGenerating">
              ({{ $store.getters.currentPDFPage }} / {{ $store.getters.totalPDFPages }})
            </div>
          </div>
        </div>

        <!-- Word Prompt popup -->
        <div
          class="col-12 darker p-3 border-radius"
          v-if="showExportPrompt === 'Word'"
        >
          <h2 class="mb-3">
            {{ $t("exportPrompt.wordOptions") }}
          </h2>
          <div class="d-flex flex-row mb-3">
            <h4 class="left-side">{{ $t("exportPrompt.template") }}</h4>
            <drop-down
              :initial-active-value="template[0]"
              :labels="template"
              :values="template"
              class="w-75"
            />
          </div>

          <div class="generate-legend-box">
            <div class="mb-2 lighter">{{ $t('AdditionalOptions') }}</div>
            <label for="generateLegend" class="container vform-editor-body ml-0">
              {{ $t("exportPrompt.autoGenerateLegend") }}
              <input
                id="generateLegend"
                type="checkbox"
                v-model="autoGenerateLegend"
              >
              <span class="checkmark" />
            </label>
          </div>
          <template v-if="autoGenerateLegend">
            <!--DO NOT REMOVE THE FOLLOWING in case we want to use it later in life-->
            <div class="lighter mb-2 mt-4">
              {{ $t("exportPrompt.pleaseSelectAlternativeNames") }}
            </div>
            <div class="d-flex flex-row mb-3">
              <h4 class="left-side">{{ $t("exportPrompt.metaSet") }}</h4>
              <drop-down
                v-if="metaSetIds.length"
                :labels="metaSetNames"
                :values="metaSetIds"
                @select="(selected) => (selectedMetaSet = selected)"
                class="w-75"
              />
            </div>

            <div v-if="selectedMetaSet" class="d-flex flex-row mb-3">
              <h4 class="left-side">{{ $t("exportPrompt.metaField") }}</h4>
              <drop-down
                :labels="metaFieldDescriptions"
                :values="metaFieldIds"
                @select="(selected) => (selectedMetaField = selected)"
                class="w-75"
              />
            </div>
          </template>

          <label v-if="autoGenerateLegend" for="generatePartList" class="container vform-editor-body ml-0">
            {{ $t("exportPrompt.generatePartList") }}
            <input
                id="generatePartList"
                type="checkbox"
                v-model="generatePartList"
            >
            <span class="checkmark" />
          </label>

          <div
            class="settings-button action-button float-right d-inline"
            @click="$emit('exportToWord', {
              metaField: selectedMetaField,
              autoGenerateLegend,
              generatePartList
            })"
          >
            {{ $t("exportPrompt.download") }}
          </div>
        </div>

        <div
            class="col-12 darker p-3 border-radius"
            v-if="showExportPrompt === 'PDF'"
        >
          <h2 class="mb-3">
            {{ $t("exportPrompt.pdfOptions") }}
          </h2>
          <div class="mb-3">
            <div v-if="logId" @click="showSessionSelection = true" class="settings-button d-inline-block mr-1">Report <icon type="angle-right" /></div>
            <div @click="$emit('exportToPDF')" class="settings-button d-inline-block mr-1">vForm <icon type="angle-right" /></div><info-helper class="ml-1" text="Report: Filled out form, vForm: Empty form" />
            <session-user-selector
                v-if="showSessionSelection"
                class="pb-2 mt-3"
                :log-id="logId"
                @setSessionData="(data) => {$emit('startPDFReport', data)}"
            />
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import Icon from "@/components/Icon";
import DropDown from "@/components/forms/DropDown.vue";
import LoadingSpinner from "@/components/LoadingSpinner";
import SessionUserSelector from "@/components/vForm/logs/SessionUserSelector.vue";
import InfoHelper from "@/components/InfoHelper.vue";

export default {
  name: "vFormExport",
  components: {
    SessionUserSelector,
    Icon,
    DropDown,
    LoadingSpinner,
    InfoHelper
  },
  props: {
    logId: { type: String, default: "" },
    organizationId: {type: String, required: true}
  },
  data() {
    return {
      showSessionSelection: false,
      showExportPrompt: "",

      // Word Export settings
      selectedMetaSet: "",
      metaFields: [],
      selectedMetaField: "",
      template: ["Tables"],
      autoGenerateLegend: false,
      generatePartList: false,
      wordGenerating: false,
      currentWordPage: 1,
      totalWordPages: 0,

      // PDF Export Settings
      pdfGenerating: false,
      pdfReportGenerating: false,
      currentPDFPage: 1,
      totalPDFPages: 0,
      metaSets: []
    };
  },
  computed: {
    metaSetNames() {
      if (this.metaSets.length > 0) {
        return Object.values(this.metaSets).map((metaSet) => metaSet.name);
      }
      return [];
    },
    metaSetIds() {
      if (this.metaSets.length > 0) {
        return Object.values(this.metaSets).map((metaSet) => metaSet.id);
      }
      return [];
    },
    metaFieldDescriptions() {
      if (this.metaFields.length > 0) {
        return Object.values(this.metaFields).map(
          (metaField) => metaField.description
        );
      }
      return [];
    },
    metaFieldIds() {
      if (this.metaFields.length > 0) {
        return Object.values(this.metaFields).map((metaField) => metaField.id);
      }
      return [];
    },
  },
  watch: {
    autoGenerateLegend() {
      if (!this.autoGenerateLegend) {
        this.selectedMetaSet = "";
        this.selectedMetaField = "";
        this.metaFields = [];
      }
    },
    selectedMetaSet(val) {
      if (val) {
        const item = this.metaSets.find((metaSet) => {
          return metaSet.id === val;
        });
        if (item) {
          this.metaFields = item.metaFields;
          this.selectedMetaField = this.metaFields.length
            ? this.metaFields[0].id
            : null;
        } else {
          this.metaFields = [];
        }
      }
    },
  },
  beforeMount() {
    this.loadMetaSets();
  },
  methods: {
    async loadMetaSets() {
      await this.$store.dispatch('clientLoadMetaSets', {
        include: 'metaFields',
        filter: 'organizationId eq ' + this.organizationId
      }).then(metaSets => {
        this.metaSets = metaSets;
      });
    },
    setPDFTotalPages(pageNo) {
      this.totalPDFPages = pageNo;
    },
    setPDFAddingPage(pageNo) {
      this.currentPDFPage = pageNo;
    },
    setPDFGenerating(bool) {
      this.pdfGenerating = bool;
    },
    setPdfReportGenerating(bool) {
      this.pdfReportGenerating = bool;
    },
    setWordTotalPages(pageNo) {
      this.totalWordPages = pageNo;
    },
    setWordAddingPage(pageNo) {
      this.currentWordPage = pageNo;
    },
    setWordGenerating(bool) {
      this.wordGenerating = bool;
    },
  }
};
</script>

<style lang="scss">
$left-side: 160px;
.newStyle #form-export {
  h1.white {
    color: rgba(255,255,255,0.8);
  }
  h2 {
    font-family: 'Inter bold', sans-serif;
    font-weight: bold;
    text-transform: none;
    color: rgba(255,255,255,0.7);
    font-size: 1.2rem;
  }
}
.left-side {
  width: $left-side;
}
.generate-legend-box {
  border-top: 1px solid rgba(255,255,255,0.4);
  padding-top: 15px;
  margin-top: 15px;
  // margin-left: $left-side - 10px;
}
</style>
