<template>
<div :class="['w-100 mb-3 position-relative', config.emphasized ? 'vform-emphasized' : '', config.backgroundColor ? 'website-with-background-color' : '']">
  <slot name="dropzone"></slot>
  <editor-overlay
      v-if="editable"
      :active="editorSelected"
      @removeBlock="$emit('removeBlock')"
      @editProperty="editProperty"
  />
  <div v-if="disableLinks && config.url && config.displayOption === 'link'">
    {{ getURLText() }}
  </div>
  <a v-else-if="config.url && config.displayOption === 'link'" :target="config.openInNewTab ? '_blank' : ''" :href="config.url.dix[lang]">{{ getURLText() }}</a>
  <iframe v-else-if="config.displayOption === 'iframe' && isActive && config.url && config.url.dix[lang] && config.url.dix[lang].startsWith('http')" :style="{height: config.height ? config.height + 'vh' : 'auto'}" :src="config.url.dix[lang]" />
  <div class="iframe-draggable-placeholder"></div>
  <div v-if="!isActive || !config.url || !config.url.dix[lang]">https://example.com</div>
</div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import EditorOverlay from "./EditorOverlay";
export default {
  name: "WEBSITE",
  components: {
    EditorOverlay
  },
  mixins: [ElementMixin],
  props: {
    disableLinks: {type: Boolean, default: false}
  },
  watch: {
    'config.url.dix'() {
      this.setUserAgent(
          document.querySelector('iframe').contentWindow,
          'Mozilla/5.0 (Linux; Android 13) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/104.0.5112.97 Mobile Safari/537.36'
      );
    }
  },
  mounted() {
    this.increaseCounter();
  },
  methods: {
    getURLText() {
      return this.config.label.dix[this.lang] ? this.config.label.dix[this.lang] : this.config.url.dix[this.lang];
    },
    setUserAgent(window, userAgent) {
      if (window.navigator.userAgent != userAgent) {
        var userAgentProp = {
          get: function() {
            return userAgent;
          }
        };
        try {
          Object.defineProperty(window.navigator, 'userAgent', userAgentProp);
        } catch (e) {
          window.navigator = Object.create(navigator, {
            userAgent: userAgentProp
          });
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  iframe {
    width: 100%;
    border: 0 transparent;
    display: block;
  }
  .element-dragging {
    iframe {
      display:none;
    }
    .iframe-draggable-placeholder {
      height: 30px;
      background-color: rgba(255,255,255,0.7);
    }
  }
  .website-with-background-color {
    background-color: #fff;
  }

  a{
    color: black;
    text-decoration: underline;
  }

  .vform-emphasized a{
    color: var(--vform-editor-ui-primary-color);;
  }
</style>