// DefaultAuthStrategy.js
import UserAuthStrategy from './UserAuthStrategy';
import store from '@/store/store';
import LegacyAuthStrategy from '@/store/identityProviders/LegacyAuthStrategy';
import {ErrorCodes} from '@/enum';

//
const checkErrorCode = (errorCode) => {
    if (errorCode === ErrorCodes.USER_BLOCKED) {
        throw new Error('userIsBlocked');
    } else if(errorCode === ErrorCodes.INVALID_MFA_CODE) {
        throw new Error('mfaCodeWrong');
    }
}

const handeLoginError = (e) => {
    const res = JSON.parse(JSON.stringify(e));
    if(res && res.response) {
        const {body} = res.response;
        if (body) {
            const {errorCode} = body;
            checkErrorCode(errorCode);
        } else {
            throw new Error('userPassOrNameIsWrong');
        }
    } else {
        throw new Error('userPassOrNameIsWrong');
    }
}
class DefaultAuthStrategy extends UserAuthStrategy {
    constructor() {
        super();
        this.backupStrategy = new LegacyAuthStrategy();
        this.strategyName = 'default';
    }

    async authenticate(credentials) {
        console.log('Authenticating with default strategy...');
        try {
            const res = await store.dispatch('clientAuth', credentials);
            const { qrcode, mfaRequired, mfaSetupRequired } = res;
            if(mfaSetupRequired) {
                await store.commit('setMfaQrCode', qrcode);
            }
            if(mfaRequired) {
                await store.commit('setMfaCodeEnter', true);
            }
            return res;
        } catch (error) {
            console.log(error);
            handeLoginError(error);
            console.log('Default authentication failed, trying backup strategy...');
            return this.backupStrategy.authenticate(credentials);
        }
    }
    async getNewAuthToken(attempt = 0) {
        console.log('Getting new auth token...');
        try {
            return await store.dispatch('clientNewAuthToken', {
                clientId: store.getters.clientId,
                userId: store.getters.currentUserId,
                refreshToken: store.getters.refreshToken,
                sessionId: store.getters.sessionId,
            });
        } catch (e) {
            console.log(e);
            if (attempt < 4) {
                // Wait for 1000 milliseconds before next attempt
                await new Promise(res => setTimeout(res, 2000));
                // Retry
                return this.getNewAuthToken(attempt + 1);
            } else {
                await store.dispatch('idpLogout');
                throw new Error('Token retrieval failed after several attempts');
            }
        }
    }
}

export default DefaultAuthStrategy;
