<template>
  <div
      :id="buttonId"
      :class="[
            'vform-slide-control-element',
            invisible ? 'invisible' : '',
            config.bottomDropZone ? 'mb-0' : '',
            tiles ? 'vform-tiles' : '',
            !tiles ? config.alignment : '',
            'border-radius', 'multi-line',
            numOfElementsInZone && numOfElementsInZone > 1 ? 'col-'+getDropzoneCol() : '',
        ]"
      :style="{
            flex: config.bottomDropZone ? '0 0 ' + getDropzoneWidth() + '%' : '',
            paddingTop:
                config.height && config.showastiles ? config.height / 2 + 'vh' : '',
            backgroundColor: tiles && config.backgroundColor ? config.backgroundColor : '',
            color: config.fontColor ? config.fontColor : (fontColor ? fontColor : ''),
            //maxWidth: !config.isAloneInZone && config.bottomDropZone ? '49%' : '',
        }"
      @click="click"
      @mouseenter="tiles ? setColors(buttonId) : () => {}"
      @mouseleave="tiles ? unsetColors(buttonId) : () => {}"
  >
    <!--<div style="font-size: 0.9rem">{{ config }}</div>-->
    <slot name="dropzone"></slot>
    <editor-overlay
        v-if="editable"
        :active="editorSelected"
        @editProperty="editProperty"
        @removeBlock="$emit('removeBlock')"
    />
    <div
        v-if="tiles"
        :class="[
                'inner d-flex border-radius',
                !disabled ? 'clickable' : 'no-button-hover-effect',
                tiles ? 'p-2' : 'simple-button',
                config.bottomDropZone ? 'next-button' : '',
                !tiles ? config.alignment : '',
                config.textAlignment ? `text-${config.textAlignment}` : '',
            ]"
        :style="{
                'justify-content': 'center',
                'flex-direction' : 'column',
                 borderColor: config.borderColor ? config.borderColor : '#000'
            }"
    >
      <preview
          v-if="true && getImage(config, lang)"
          :asset-id="getImage(config, lang)"
          :background-mode="false"
          :img-auto-shrink-to-fit="true"
          :may-load-now="isActive"
          :offline-mode="offlineMode"
          :preview-id="getImage(config, lang)"
          :removable="false"
          :show-spinner-message="false"
          :size="config.iconSize"
          class="tile-button-image-wrapper pt-1 pb-1"
          classes="square-image"
          source="asset"
          spinner-size="default"
          @contentLoaded="increaseCounter"
      />
      <div class="">{{ getLabel(config, lang) ? getLabel(config, lang) : $t("next") }}
      </div>
    </div>
    <div
        v-else
        :id="buttonId"
        :class="[
                'inner d-flex align-items-center',
                !disabled ? 'clickable' : 'no-button-hover-effect',
                invisible ? 'invisible' : '',
                tiles ? 'p-2' : '',
                config.bottomDropZone ? 'next-button' : 'simple-button',
                !tiles ? config.alignment : '',
                !tiles ? (config.textAlignment ? `text-${config.textAlignment}` : '') : '',
            ]"
        :style="{
              backgroundColor: config.backgroundColor ? config.backgroundColor : '',
              borderColor: config.borderColor ? config.borderColor : '#000',
              color: config.fontColor ? config.fontColor : (fontColor ? fontColor : '')
          }"
        @mouseenter="!tiles ? setColors(buttonId) : () => {}"
        @mouseleave="!tiles ? unsetColors(buttonId) : () => {}"
    >
      <preview
          v-if="getImage(config, lang)"
          :asset-id="getImage(config, lang)"
          :may-load-now="isActive"
          :offline-mode="offlineMode"
          :preview-id="getImage(config, lang)"
          :removable="false"
          :show-spinner-message="false"
          class="mini vform-preview-img vform-icon-img mr-2"
          source="asset"
          spinner-size="default"
          @contentLoaded="increaseCounter"
      />
      <div :class="['text wrap-break', getImage(config, lang) ? 'with-icon' : 'without-icon']">
        <!--DO NOT ADD A LINE BREAK BEFORE GETLABEL OR YOU WILL HAVE A SPACE AT THE BEGINNING!!-->
        <template v-if="!config.buttonStyle ||  config.buttonStyle === 'Text' || config.buttonStyle === 'TextAndArrow'">{{ getLabel(config, lang) ? getLabel(config, lang) : $t("next") }}</template>
        <template v-else>
          <div v-html="'&nbsp;'"></div>
        </template>
      </div>
      <div v-if="config.buttonStyle === 'Arrow' || config.buttonStyle === 'TextAndArrow'" class="ml-3">
        <icon type="angle-right"/>
      </div>
    </div>
  </div>
</template>

<script>
import ElementMixin from "@/components/vForm/mixins/ElementMixin.js.vue";
import ColorContrastMixinJs from "../../mixins/ColorContrastMixin.js";
import Preview from "./Preview";
import EditorOverlay from "./EditorOverlay";
import Icon from "@/components/Icon";

export default {
  name: "BUTTON_ITEM",
  components: {
    Preview,
    EditorOverlay,
    Icon
  },
  mixins: [ElementMixin, ColorContrastMixinJs],
  props: {
    invisible: {type: Boolean, default: false},
    numOfElementsInZone: {type: Number, default: 1}
  },
  data() {
    return {
      tiles: false,
      buttonId: null,
      counterIncreased: false,
    };
  },
  watch: {
    config: {
      deep: true,
      handler() {
        this.tiles = this.isTiles(this.config);
        this.checkColors();
        this.forceReRenderKey++;
      },
    },
  },
  beforeMount() {
    this.tiles = this.isTiles(this.config);
    this.config.aligment = this.config.aligment ? this.config.aligment : 'right';
    this.buttonId = 'button' + this.config.uuid;
  },
  mounted() {
    if (!this.getImage(this.config, this.lang)) {
      this.increaseCounter();
    }
    this.checkColors();
  },
  methods: {
    getDropzoneWidth() {
      return Math.floor((100 / this.numOfElementsInZone) - 1);
    },
    getDropzoneCol() {
      return Math.floor(12/this.numOfElementsInZone);
    },
    checkColors() {
      this.setInitialColors(this.buttonId);
    },
    getJumpTarget() {
      return this.getJumpTargets();
    },
    click() {
      if (this.clicking) {
        return;
      }
      this.clicking = true;
      this.handleGoTo();
      const $this = this;
      setTimeout(() => {
        $this.clicking = false;
      }, 100);
    },
  }
};
</script>

<style lang="scss" scoped>
.invisible {
  opacity: 0;
  pointer-events: none;
}

.with-icon {
  flex-grow: 1 !important;
}

.wrap-break {
  text-wrap: wrap;
  overflow-wrap: anywhere;
}

.tile-button-image-wrapper {
  height: 90%;
  justify-content: center;
  display: flex;
  align-items: center;
}
</style>