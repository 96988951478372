<template>
  <div class="w-100">
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Text"
        :initially-collapsed="false"
        preview-id="textElements"
    >
    <div class="block-container" slot="info">
      <block :state="state" :block-type="vFormControls.HEADLINE" block-name="headline">
        <h3 class="mt-3 mb-0">
          {{ $t("vform.helloworld") }}
        </h3>
      </block>
      <block :state="state" :block-type="vFormControls.WEBSITE" block-name="website">
        <a
            target="_blank"
            class="mt-1 mb-0 dark-ui-primary"
        >http://example.com</a
        >
      </block>
      <block :state="state" :block-type="vFormControls.TEXT" block-name="textfield">
        <label>{{ placeholders.LONG }}</label>
      </block>
    </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Form"
        :initially-collapsed="false"
        preview-id="textElements"
    >
      <div class="block-container" slot="info">
        <block :state="state" :block-type="vFormControls.RADIOBUTTONS" block-name="singlechoice">
          <div class="pl-0 mt-2">
            <label class="container"
            >Option 1
              <input type="checkbox" disabled />
              <span class="radio" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container"
            >Option 2
              <input
                  type="checkbox"
                  disabled
                  checked="checked"
              />
              <span class="radio" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container mb-0"
            >Option 3
              <input type="checkbox" disabled />
              <span class="radio" />
            </label>
          </div>        </block>
        <block :state="state" :block-type="vFormControls.CHECKBOXES" block-name="multichoice">
          <div class="pl-0">
            <label class="container"
            >Option 1
              <input type="checkbox" disabled />
              <span class="checkmark" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container"
            >Option 2
              <input
                  type="checkbox"
                  checked="checked"
                  disabled
              />
              <span class="checkmark" />
            </label>
          </div>
          <div class="pl-0">
            <label class="container mb-0"
            >Option 3
              <input
                  type="checkbox"
                  checked="checked"
                  disabled
              />
              <span class="checkmark" />
            </label>
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.SHORT_ANSWER" block-name="shortanswer">
          <textarea rows="3" cols="" class="form-text-dark mt-2" disabled>Lorem ipsum dolor sit amet</textarea>
        </block>
        <block :state="state" :block-type="vFormControls.FORM_SUMMARY" block-name="formSummary" />

        <!--Those are basu blocks-->
        <!--<block :state="state" :block-type="vFormControls.LIST_MATCH" block-name="listMatch">
          <div class="form-block-image">
            <icon type="list-ul" size="3.3" />
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.LIST_MATCH_TEXT_INPUT" block-name="listMatchTextInput">
          <div class="form-block-image">
            <icon type="list-dropdown" size="3.3" />
          </div>
        </block>-->
        <block :state="state" :block-type="vFormControls.BUTTON" block-name="button">
          <div class="form-block-image">
            <button>NEXT ></button>
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.LOGIN" block-name="login" />
        <block :state="state" :block-type="vFormControls.LOGOUT" block-name="logout" />
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses()]"
              :elementType="vFormControls.COURSE_BLOCK"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.courseBlock") }}
            </h2>
          </div>
        </div>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Media"
        :initially-collapsed="false"
        preview-id="mediaElements"
    >
      <div class="block-container" slot="info">
        <block :state="state" :block-type="vFormControls.IMAGE" block-name="image">
          <div class="form-block-image">
            <icon type="video" size="3.3" />
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.VIDEO" block-name="video">
          <div class="form-block-image">
            <icon type="video" size="3.3" />
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.AUDIO" block-name="audio">
          <div class="form-block-image">
            <icon type="audio" size="3.3" />
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.QR_CODE" block-name="qrcode">
          <div class="form-block-image">
            <icon type="qrcode" size="3.3" />
          </div>
        </block>
        <block :state="state" :block-type="vFormControls.SENSOR" block-name="sensor" />
        <block :state="state" :block-type="vFormControls.SENSOR" block-name="sensor" />
        <block  v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin" :state="state" :block-type="vFormControls.CODE_SNIPPET" block-name="codeSnippet">
          <div class="form-block-image">
            <icon type="code" size="3.3" />
          </div>
        </block>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Layout"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">
        <block :state="state" :block-type="vFormControls.SEPARATOR" block-name="divider">
          <div class="mt-3 separatorBlock" />
        </block>
        <block :state="state" :block-type="vFormControls.VERTICAL_SPACE" block-name="verticalSpace">
          <div class="mt-3 verticalSpaceBlock">
            <icon
                :size="'1.55'"
                class=""
                type="arrows-up-down"
            />
          </div>
        </block>
      </div>
    </info-panel>
    <info-panel
        :collapsible="true"
        class="vform-editor-body-bold"
        info-panel-header="Live Data"
        :initially-collapsed="false"
        preview-id="layoutElements"
    >
      <div class="block-container dark-ui" slot="info">
        <div class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.PART_INFO)]"
              :elementType="vFormControls.PART_INFO"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.partInfo") }}
            </h2>
          </div>
        </div>
        <div
            class="col-xxl-6 col-md-12 pl-1 pb-2 pr-1">
          <div
              :class="['form-block', getBlockDraggableClasses(vFormControls.PROGRESS_TRACKER)]"
              :elementType="vFormControls.PROGRESS_TRACKER"
          >
            <h2 class="vform-editor-label">
              {{ $t("vform.progressTracker") }}
            </h2>
          </div>
        </div>
        <block :state="state" block-name="partLegend" :block-type="vFormControls.PART_LEGEND" />
        <block :state="state" block-name="partInfo" :block-type="vFormControls.PART_INFO" />
        <block
            v-if="$store.getters.isSFXMember || $store.getters.isSuperAdmin"
            :state="state"
            block-name="progressTracker"
            :block-type="vFormControls.PROGRESS_TRACKER"
        />
      </div>
    </info-panel>
  </div>
</template>

<script>
import { vFormControls, placeholders } from "@/enum";
import Icon from "../Icon";
import InfoPanel from "../InfoPanel";
import Block from "@/components/vForm/Block.vue";
export default {
    name: "BlockPanel",
    components: {
      InfoPanel,
      Icon,
      Block
    },
    props: {
      state: {type: Object, required: true},
      hotspotPopupAllowedElementTypes: {type: Array, default: null},
    },
    data() {
        return {
          vFormControls: vFormControls,
          placeholders: placeholders,
        };
    },
    methods: {
      getBlockDraggableClasses(elementType){
        if (this.state.hotspotPopupVisible) {
          if (this.hotspotPopupAllowedElementTypes.find((x) => {
            return x === elementType
          })) {
            return "draggable";
          }
          else{
            return "disabled"
          }
        }
        else{
          return "draggable";
        }
      }
    }
};
</script>

<style lang="scss" scoped>
.block-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5px;
}
.form-block .checkmark {
  background: var(--vform-editor-ui-tertiary-color);
  border: solid 1px var(--vform-editor-ui-secondary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .checkmark {
  background-color: gray;
}
.form-block .radio {
  background: var(--vform-editor-ui-tertiary-color);
  //border: solid 1px var(--vform-editor-ui-quaternary-color);
}
/* When the checkbox is checked, add a blue background */
.form-block .container input:checked ~ .radio {
  background-color: gray;
}
.form-block {
  background-color: var(--vform-editor-ui-secondary-color);
  color: var(--vform-editor-ui-primary-color);
  //border: solid 4px var(--vform-editor-panel-active-background-color;
  border-radius: 8px;
  min-height: 50px;
  height: 100%;
  padding: 12px;
  -webkit-box-shadow: 0 8px 6px -5px black;
  -moz-box-shadow: 0 8px 6px -5px black;
  box-shadow: 0 8px 6px -5px black;
  //box-sizing: border-box;
  //margin: 3px;

  &.disabled {
    opacity: 0.2;
  }
}
.form-block-image {
  display: flex;
  justify-content: center;
  line-height: 30px;
  color: var(--vform-editor-ui-tertiary-color);
  button {
    color: var(--vform-editor-ui-quaternary-color);
    background-color: var(--vform-editor-ui-tertiary-color);
    border-radius: 4px;
    border: solid 1px var(--vform-editor-ui-secondary-color);
    font-size: 0.95rem;
    min-width: 100px;
    line-height: 25px;
  }
}
.form-block a {
  text-decoration: underline;
  font-size: 10px;
}
.form-block input,
textarea {
  width: 100%;
  cursor: pointer;
}
.form-block {
  &:not(.disabled):hover {
    // border: solid 2px #da5e06;
    cursor: move;
    background-color: var(--vform-editor-ui-quinary-color);
    opacity: 0.99;
    //padding: 14px;
  }
  &.disabled {
    cursor: not-allowed;

    > textarea, span, label{
      cursor: not-allowed;
    }
  }
}
.form-block h2 {
  //font-weight: 700;
  //font-size: 13px;
}
.form-block {
  h3,
  label {
    color: var(--vform-editor-ui-tertiary-color);
    font-weight: 400;
    //font-size: 0.8rem;
  }
}
.form-block input,
textarea {
  border-width: 1px !important;
  color: var(--vform-editor-ui-quaternary-color) !important;
  background-color: var(--vform-editor-ui-tertiary-color) !important;
}
.form-block label.container {
  font-size: 10px;
}
.draggable {
  z-index: 10000;
}
.info-panel {
  background-color: var(--vform-editor-panel-active-background-color);
}
.separatorBlock {
    background-color: var(--vform-editor-ui-tertiary-color);
    height: 3px;
}

.verticalSpaceBlock {
    border-top: solid 1px var(--vform-editor-ui-tertiary-color);
    border-bottom: solid 1px var(--vform-editor-ui-tertiary-color);
    height: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 2px;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}

.block-container {
    .form-block input,
    textarea,
    span,
    label {
        cursor: inherit;
        font-size: 10px;
    }
}
</style>
