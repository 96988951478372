<template>
  <div class="row">
    <div class="col">
      <h3 class="global-settings-title mb-4">Global Settings</h3>
      <div @click="$emit('convertToFile')"
           class="settings-button d-inline-block mb-2"
           v-if="version < 3 && ($store.getters.isSuperAdmin) && false">
        You won't be able to duplicate the vform in vSTAGE afterwards, be careful!<br />
        {{ $t('convertToNewVersion') }}
      </div>
      <div class="font-selector mt-1">
        <h3 class="">Font</h3>
        <font-selector
            v-if="form.formSettings"
            :initial-selected-font="form.formSettings.font"
            :use-font-assets="true"
            @change="setFontType"
        />
        <h3 class="mt-4">Font Size</h3>
        <slider :default-value="0.95" :initial-value="parseFloat(form.formSettings.fontSize)"
                :interval="0.05" :max="1.4" :min="0.7"
                @change="(val) => { setFontSize(val); }"></slider>
      </div>
      <h3 class="mt-3">{{ $t('ThemeColor') }}</h3>
      <div class="d-inline-flex">
        <div :style="{
                width: '23px',
                height: '23px',
                cursor: 'pointer',
                backgroundColor: form.formSettings.themeColor
                    ? form.formSettings.themeColor
                    : '',
                border: form.formSettings.themeColor ? '' : '1px dashed rgba(255,255,255,0.5)',
            }"
             class=""
             @click="colorPickrId = 'pickThemeColor'"
        />

        <div
            class="ml-4 pr-3 settings-button clickable d-inline-block"
            @click="() => { this.form.formSettings.themeColor = null; this.$emit('themeChanged'); }">
          {{ $t("reset") }}
        </div>

      </div>

      <color-picker
          v-if="colorPickrId"
          ref="colorpickr"
          :popup-style="true"
          :show-save-button="true"
          :value="form.formSettings.themeColor ? form.formSettings.themeColor : '#fff'"
          class="step-settings-color-pickr"
          @changeColor="(color) => {setColor(color, false);}"
          @close="() => {colorPickrId = null;}"
          @save="(color) => {setColor(color);}"
      />

      <h3 class="mt-3">Logo</h3>
      <div class="logo" @click="showSelect = true">
        <preview v-if="form.formSettings && form.formSettings.logoAssetId"
                 :asset-or-project-id="form.formSettings ? form.formSettings.logoAssetId : null"
                 :class="['small-thumbnail mb-1', 'default-imageDix']"
                 :preview-id="form.formSettings ? form.formSettings.logoAssetId : null"
                 :preview-uri="$store.getters['getMediaPreviewUri'](form.formSettings ? form.formSettings.logoAssetId : null)"
                 :removable="false"
                 :type="'image'"
        />

        <div v-if="!form.formSettings || !form.formSettings.logoAssetId" class="vform-alignment-button"
             @click="showSelect = true;">
          <icon size="1" type="folder"/>
        </div>
      </div>

      <popup
          v-if="showSelect"
          :style="{'z-index': 1000}"
          @close="() => {showSelect = false;}"
      >
        <div v-if="showSelect" slot="popupMainContent" class="w-100">
          <div class="col-12 col-md-6">
            <content-display
                :hide-upper-name="true"
                :show-preview="true"
                :value="form.formSettings.logoAssetId"
                class="bg-dark"
                list-name="generalList"
                type="media"
            />
            <span v-if="form.formSettings.logoAssetId" @click="() => {setLogo(null);}">
                <icon :size="'1.5'" type="trash"/>
              </span>
          </div>
          <content-list
              :list-name="'vformImages3'"
              :media-category="3"
              :organization-ids="[organizationId]"
              :show-preview="true"
              :thumbnail-view="true"
              class="col-12 col-md-12 mt-1"
              type="media"
              @save="setLogo"
          />
        </div>
      </popup>

      <div class="w-100 mb-4"/>
      <label class="vform-editor-label section mt-1">{{ $t("transition") }}</label>
      <select v-model="form.formSettings.slideTransition" class="custom-select darker">
        <option value="none">None</option>
        <option value="fade">Fade</option>
        <option value="zoom">Zoom</option>
      </select>
    </div>
    <label
        :for="'kioskmoder' + $vnode.key"
        class="container d-inline-block mt-3 ml-3"
        style="padding-left: 5px !important"
    >
      <input
          :id="'kioskmoder' + $vnode.key"
          v-model="form.alwaysKioskMode"
          type="checkbox"
      />
      <span class="checkmark"></span>
      <div class="ml-4">{{ $t("alwaysKioskMode") }}</div>
    </label>
    <div class="ml-3 mr-3">
      <div
          class="pl-3 pr-3 mt-2 settings-button clickable d-inline-block"
          @click="$emit('addNextButtonToAllSteps')">
        {{ $t("addNextButtonForAllSteps") }}
      </div>
      <br/>
    </div>
    <div v-if="vSTAGEContext" class="d-inline-block mt-4 ml-3">
      <screen-size-selector
          class="vform-screen-size-selector"
          @setOrientation="setScreenOrientation"
          @setSize="setScreenSize"
      />
    </div>
    <div v-if="vSTAGEContext" class="mt-4 ml-3">
      <language-selector
          :use-plain-language="true"
          :value="language"
          class="mr-2"
          open-direction="top"
          @update="setLanguage"
      />
    </div>
  </div>
</template>


<script>

import LanguageSelector from "@/components/forms/LanguageSelector";
import ScreenSizeSelector from "@/components/vForm/viewer/ScreenSizeSelector";
import ColorPicker from "@/components/widgets/editors/ColorPicker";
import ContentList from "@/components/widgets/editors/ContentList";
import ContentDisplay from "@/components/widgets/editors/ContentDisplay";
import Popup from "@/components/Popup";
import Icon from "@/components/Icon";
import Preview from "@/components/preview/Preview";
import Slider from "@/components/widgets/editors/Slider";
import FontSelector from "../FontSelector";

export default {
  name: "vFormGlobalSettings",
  components: {
    LanguageSelector,
    ScreenSizeSelector,
    ColorPicker,
    Popup,
    ContentList,
    ContentDisplay,
    Icon,
    Preview,
    Slider,
    FontSelector
  },

  props: {
    form: {type: Object, required: true},
    logId: {type: String, default: ""},
    language: {type: String, default: ""},
    vSTAGEContext: {type: Boolean, default: false},
    ccLightContext: {type: Boolean, default: false},
    organizationId: {type: String, default: ""},
    version: {type: Number, default: 0}
  },
  data() {
    return {
      showSelect: false,
      colorPickrId: null,
      formSettings: {
        fontSize: "0.95",
        themeColor: '',
        logoAssetId: '',
        slideTransition: 'zoom',
        font: {name: 'Inter', id: 'Inter', systemFont: true}
      },
    };
  },
  watch: {
    form() {
      this.initFormSettings()
    },
    showSelect(val) {
      this.$emit('innerPopupActive', val);
    }
  },
  beforeMount() {
    this.initFormSettings();
  },
  methods: {
    setFontType(font) {
      this.$set(this.form.formSettings, "font", font);
      if (!font.systemFont) {
        this.$emit('loadFont')
      }
    },
    initFormSettings() {
      const {formSettings} = this.form;
      this.form.formSettings = formSettings ? formSettings : this.formSettings;
      if (this.form.alwaysKioskMode) {
        this.form.formSettings.alwaysKioskMode = this.form.alwaysKioskMode;
      }
      if (formSettings && formSettings.slideTransition) {
        this.form.formSettings.slideTransition = formSettings.slideTransition;
      }
    },
    setFontSize(size) {
      this.$set(this.form.formSettings, "fontSize", size);
    },
    setLanguage(lang) {
      this.language = lang;
      this.$cookies.set("vFormLanguage", lang);
      this.$emit("setLanguage", lang);
    },
    setScreenSize(size, customSize) {
      this.$emit("setScreenSize", size, customSize);
    },
    setScreenOrientation(orientation) {
      this.$emit("setScreenOrientation", orientation);
    },
    setColor(color, closePickr = true) {
      if (closePickr) {
        this.colorPickrId = null;
      }
      this.$set(this.form.formSettings, "themeColor", color);
      this.$emit('themeChanged');
    },
    setLogo(assetId) {
      this.form.formSettings.logoAssetId = assetId;
      this.showSelect = false;
      if (assetId) {
        this.$store.dispatch("loadMedia", {
          id: assetId,
        });
      }
      this.$emit('logoChanged');
    }
  }
}

</script>

<style scoped>
h2 {
  margin-bottom: 0;
}

h3.global-settings-title {
  font-size: 1.2rem;
}

.logo {
  width: 50px;
  cursor: pointer;
}
</style>